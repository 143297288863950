import * as React from "react";
export const NoImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={271.5}
    height={300}
    viewBox="0 0 271.5 300"
    {...props}
  >
    <defs>
      <clipPath id="clip-path">
        <circle
          id="Ellipse_40359"
          data-name="Ellipse 40359"
          cx={61.171}
          cy={61.171}
          r={61.171}
          transform="translate(0)"
          fill="#f5f7fa"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1={-3.333}
        y1={0.5}
        x2={-2.333}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffcf54" />
        <stop offset={0.261} stopColor="#fdcb4d" />
        <stop offset={0.639} stopColor="#f7c13a" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={0.845}
        x2={0.279}
        y2={1.171}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#e8edf3" />
      </linearGradient>
    </defs>
    <g
      id="Group_112561"
      data-name="Group 112561"
      transform="translate(-100 -1142)"
    >
      <rect
        id="Rectangle_57055"
        data-name="Rectangle 57055"
        width={271.5}
        height={300}
        rx={16}
        transform="translate(100 1142)"
        fill="#e4e8ee"
      />
      <g
        id="Group_112549"
        data-name="Group 112549"
        transform="translate(60.896 1100.707)"
      >
        <g
          id="Group_95400"
          data-name="Group 95400"
          transform="translate(114.104 76.428)"
        >
          <circle
            id="Ellipse_40358"
            data-name="Ellipse 40358"
            cx={61.171}
            cy={61.171}
            r={61.171}
            transform="translate(0 0)"
            fill="#f5f7fa"
            opacity={0.5}
          />
          <g
            id="Mask_Group_6347"
            data-name="Mask Group 6347"
            transform="translate(0 0)"
            clipPath="url(#clip-path)"
          >
            <g
              id="Group_95399"
              data-name="Group 95399"
              transform="translate(-17.486 23.965)"
            >
              <g id="icons8-skyscrapers" transform="translate(42.153)">
                <path
                  id="Path_6986"
                  data-name="Path 6986"
                  d="M28,11H50.287a2.026,2.026,0,0,1,2.026,2.026V19.1H28Z"
                  transform="translate(18.599 9.261)"
                  fill="#98a0ac"
                />
                <path
                  id="Path_6987"
                  data-name="Path 6987"
                  d="M25,14H59.443a2.026,2.026,0,0,1,2.026,2.026V88.262H25Z"
                  transform="translate(15.521 12.339)"
                  fill="#e4e8ee"
                />
                <path
                  id="Path_6988"
                  data-name="Path 6988"
                  d="M32.313,26.261H8v-5.6a4.052,4.052,0,0,1,2.24-3.624L32.313,6Z"
                  transform="translate(-1.922 4.13)"
                  fill="#98a0ac"
                />
                <path
                  id="Path_6989"
                  data-name="Path 6989"
                  d="M43.1,20.052h4.052V16H43.1Zm0,8.1h4.052V24.1H43.1Zm-8.1-8.1h4.052V16H35Zm0,8.1h4.052V24.1H35Zm8.1,8.1h4.052V32.208H43.1Zm0,8.1h4.052V40.313H43.1Zm0,8.1h4.052V48.417H43.1ZM35,36.261h4.052V32.208H35Zm0,8.1h4.052V40.313H35Zm0,8.1h4.052V48.417H35Zm8.1,8.1h4.052V56.521H43.1Zm0,8.1h4.052V64.625H43.1Zm-8.1-8.1h4.052V56.521H35Zm0,8.1h4.052V64.625H35Z"
                  transform="translate(25.782 14.391)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_6990"
                  data-name="Path 6990"
                  d="M5,94.418H41.469V8L7.406,23.139A4.052,4.052,0,0,0,5,26.842Z"
                  transform="translate(-5 6.182)"
                  fill="#e4e8ee"
                />
                <rect
                  id="Rectangle_50357"
                  data-name="Rectangle 50357"
                  width={24.882}
                  height={9.048}
                  transform="translate(47.543 28.858) rotate(180)"
                  fill="url(#linear-gradient)"
                />
                <path
                  id="Path_6991"
                  data-name="Path 6991"
                  d="M11.052,24.1H7v4.052h4.052Zm8.1-8.1H15.1v4.052h4.052Zm0,8.1H15.1v4.052h4.052Zm-8.1,8.1H7v4.052h4.052Zm0,8.1H7v4.052h4.052Zm0,8.1H7v4.052h4.052Zm8.1-16.208H15.1v4.052h4.052Zm0,8.1H15.1v4.052h4.052Zm0,8.1H15.1v4.052h4.052Zm-8.1,8.1H7v4.052h4.052Zm0,8.1H7v4.052h4.052Zm8.1-8.1H15.1v4.052h4.052Zm0,8.1H15.1v4.052h4.052Z"
                  transform="translate(-2.948 14.391)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_7007"
                  data-name="Path 7007"
                  d="M4.052,8.1H0v4.052H4.052Zm8.1-8.1H8.1V4.052h4.052Zm0,8.1H8.1v4.052h4.052Zm-8.1,8.1H0v4.052H4.052Zm0,8.1H0v4.052H4.052Zm0,8.1H0v4.052H4.052Zm8.1-16.208H8.1v4.052h4.052Zm0,8.1H8.1v4.052h4.052Zm0,8.1H8.1v4.052h4.052Zm-8.1,8.1H0v4.052H4.052Zm0,8.1H0v4.052H4.052Zm8.1-8.1H8.1v4.052h4.052Zm0,8.1H8.1v4.052h4.052Z"
                  transform="translate(16.208 140.134) rotate(180)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_7009"
                  data-name="Path 7009"
                  d="M4.052,8.1H0v4.052H4.052Zm8.1-8.1H8.1V4.052h4.052Zm0,8.1H8.1v4.052h4.052Zm-8.1,8.1H0v4.052H4.052Zm0,8.1H0v4.052H4.052Zm0,8.1H0v4.052H4.052Zm8.1-16.208H8.1v4.052h4.052Zm0,8.1H8.1v4.052h4.052Zm0,8.1H8.1v4.052h4.052Zm-8.1,8.1H0v4.052H4.052Zm0,8.1H0v4.052H4.052Zm8.1-8.1H8.1v4.052h4.052Zm0,8.1H8.1v4.052h4.052Z"
                  transform="translate(72.938 140.134) rotate(180)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_6992"
                  data-name="Path 6992"
                  d="M17.026,6H49.443a2.026,2.026,0,0,1,2.026,2.026V96.47H15V8.026A2.026,2.026,0,0,1,17.026,6Z"
                  transform="translate(5.261 4.13)"
                  fill="#ced3dd"
                />
                <path
                  id="Path_6993"
                  data-name="Path 6993"
                  d="M21.026,1H37.235a2.026,2.026,0,0,1,2.026,2.026v10.13H19V3.026A2.026,2.026,0,0,1,21.026,1Z"
                  transform="translate(9.365 -1)"
                  fill="#98a0ac"
                />
                <path
                  id="Path_6994"
                  data-name="Path 6994"
                  d="M45.365,13.13v4.052H41.313V13.13ZM41.313,25.287h4.052V21.235H41.313Zm0,8.1h4.052V29.339H41.313Zm0,8.1h4.052V37.443H41.313Zm-8.1-24.313h4.052V13.13H33.208Zm0,8.1h4.052V21.235H33.208Zm0,8.1h4.052V29.339H33.208Zm0,8.1h4.052V37.443H33.208ZM25.1,17.182h4.052V13.13H25.1Zm8.1-8.1h4.052V3H33.208Zm-8.1,0h4.052V3H25.1Zm0,16.208h4.052V21.235H25.1Zm0,8.1h4.052V29.339H25.1Zm0,8.1h4.052V37.443H25.1ZM17,17.182h4.052V13.13H17Zm0,8.1h4.052V21.235H17Zm0,8.1h4.052V29.339H17Zm0,8.1h4.052V37.443H17Zm24.313,8.1h4.052V45.547H41.313Zm0,8.1h4.052V53.652H41.313Zm0,8.1h4.052V61.756H41.313ZM33.208,49.6h4.052V45.547H33.208Zm0,8.1h4.052V53.652H33.208Zm0,8.1h4.052V61.756H33.208ZM25.1,49.6h4.052V45.547H25.1Zm0,8.1h4.052V53.652H25.1Zm0,8.1h4.052V61.756H25.1ZM17,49.6h4.052V45.547H17Zm0,8.1h4.052V53.652H17Zm0,8.1h4.052V61.756H17Zm24.313,8.1h4.052V69.86H41.313Zm0,8.1h4.052V77.964H41.313Zm-8.1-8.1h4.052V69.86H33.208Zm0,8.1h4.052V77.964H33.208Zm-8.1-8.1h4.052V69.86H25.1Zm0,8.1h4.052V77.964H25.1Zm-8.1-8.1h4.052V69.86H17Zm0,8.1h4.052V77.964H17Z"
                  transform="translate(7.313 1.052)"
                  fill="#e4e8ee"
                />
                <path
                  id="Path_7008"
                  data-name="Path 7008"
                  d="M28.365,10.13v4.052H24.313V10.13ZM24.313,22.287h4.052V18.235H24.313Zm0,8.1h4.052V26.339H24.313Zm0,8.1h4.052V34.443H24.313Zm-8.1-24.313h4.052V10.13H16.208Zm0,8.1h4.052V18.235H16.208Zm0,8.1h4.052V26.339H16.208Zm0,8.1h4.052V34.443H16.208ZM8.1,14.182h4.052V10.13H8.1Zm8.1-8.1h4.052V0H16.208Zm-8.1,0h4.052V0H8.1Zm0,16.208h4.052V18.235H8.1Zm0,8.1h4.052V26.339H8.1Zm0,8.1h4.052V34.443H8.1ZM0,14.182H4.052V10.13H0Zm0,8.1H4.052V18.235H0Zm0,8.1H4.052V26.339H0Zm0,8.1H4.052V34.443H0Zm24.313,8.1h4.052V42.547H24.313Zm0,8.1h4.052V50.652H24.313Zm0,8.1h4.052V58.756H24.313ZM16.208,46.6h4.052V42.547H16.208Zm0,8.1h4.052V50.652H16.208Zm0,8.1h4.052V58.756H16.208ZM8.1,46.6h4.052V42.547H8.1Zm0,8.1h4.052V50.652H8.1Zm0,8.1h4.052V58.756H8.1ZM0,46.6H4.052V42.547H0Zm0,8.1H4.052V50.652H0Zm0,8.1H4.052V58.756H0Zm24.313,8.1h4.052V66.86H24.313Zm0,8.1h4.052V74.964H24.313Zm-8.1-8.1h4.052V66.86H16.208Zm0,8.1h4.052V74.964H16.208Zm-8.1-8.1h4.052V66.86H8.1Zm0,8.1h4.052V74.964H8.1ZM0,70.912H4.052V66.86H0Zm0,8.1H4.052V74.964H0Z"
                  transform="translate(52.677 166.473) rotate(180)"
                  fill="#e4e8ee"
                />
              </g>
              <g
                id="Group_95391"
                data-name="Group 95391"
                transform="translate(0 42.017)"
              >
                <path
                  id="blob_1_"
                  data-name="blob (1)"
                  d="M20.917,5.321c1.751,1.46,3.962,1.868,5.364,3.157,1.423,1.288,2.058,3.436,2.846,6.378.766,2.942,1.686,6.657.5,9.3-1.182,2.663-4.488,4.23-7.859,4.9a19.671,19.671,0,0,1-9.939-.623C8.7,27.4,5.877,25.592,4.366,23.1c-1.511-2.469-1.708-5.626-2.605-8.954S-.734,7.34.382,5.107c1.095-2.233,4.9-3.221,8.034-4.058C11.569.211,14.065-.476,15.969.425,17.852,1.349,19.165,3.861,20.917,5.321Z"
                  transform="translate(21.351 42.152) rotate(-135)"
                  fill="url(#linear-gradient-2)"
                />
                <g
                  id="Group_95390"
                  data-name="Group 95390"
                  transform="translate(16.893 29.388)"
                >
                  <path
                    id="Path_7005"
                    data-name="Path 7005"
                    d="M7781.038,5675.368s.07-5.206,0-9.086-4.577-1.576-5.789-5.011"
                    transform="translate(-7775.249 -5659.651)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                  <path
                    id="Path_7006"
                    data-name="Path 7006"
                    d="M7778.659,5666.182a34.226,34.226,0,0,0,4.152-4.913c1.263-2.072.9-3.372.9-3.372"
                    transform="translate(-7772.703 -5657.897)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                </g>
              </g>
              <g
                id="Group_95398"
                data-name="Group 95398"
                transform="translate(114.13 42.017)"
              >
                <path
                  id="blob_1_2"
                  data-name="blob (1)"
                  d="M20.917,5.321c1.751,1.46,3.962,1.868,5.364,3.157,1.423,1.288,2.058,3.436,2.846,6.378.766,2.942,1.686,6.657.5,9.3-1.182,2.663-4.488,4.23-7.859,4.9a19.671,19.671,0,0,1-9.939-.623C8.7,27.4,5.877,25.592,4.366,23.1c-1.511-2.469-1.708-5.626-2.605-8.954S-.734,7.34.382,5.107c1.095-2.233,4.9-3.221,8.034-4.058C11.569.211,14.065-.476,15.969.425,17.852,1.349,19.165,3.861,20.917,5.321Z"
                  transform="translate(21.351 42.152) rotate(-135)"
                  fill="url(#linear-gradient-2)"
                />
                <g
                  id="Group_95390-2"
                  data-name="Group 95390"
                  transform="translate(16.893 29.388)"
                >
                  <path
                    id="Path_7005-2"
                    data-name="Path 7005"
                    d="M7781.038,5675.368s.07-5.206,0-9.086-4.577-1.576-5.789-5.011"
                    transform="translate(-7775.249 -5659.651)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                  <path
                    id="Path_7006-2"
                    data-name="Path 7006"
                    d="M7778.659,5666.182a34.226,34.226,0,0,0,4.152-4.913c1.263-2.072.9-3.372.9-3.372"
                    transform="translate(-7772.703 -5657.897)"
                    fill="none"
                    stroke="#ced3dd"
                    strokeLinecap="round"
                    strokeWidth={2}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
        <text
          id="No_Image_Found"
          data-name="No Image Found"
          transform="translate(175.104 218.407)"
          fill="#98a0ac"
          fontSize={9}
          fontFamily="NunitoSans-Bold, Nunito Sans"
          fontWeight={700}
        >
          <tspan x={-34} y={0}>
            {"No Image Found"}
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
