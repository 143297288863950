import * as React from "react"

export const ContactIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    viewBox="0 0 40 40"
    {...props}
  >
    <g
      id="Group_111261"
      data-name="Group 111261"
      transform="translate(-691 -150)"
    >
      <rect
        id="Rectangle_57032"
        data-name="Rectangle 57032"
        width={40}
        height={40}
        rx={8}
        transform="translate(691 150)"
        fill="#78b1fe"
        opacity={0.15}
      />
      <g id="icons8-resume" transform="translate(694 155)">
        <path
          id="Path_100344"
          data-name="Path 100344"
          d="M8,3H26.286V27H8Z"
          fill="#90caf9"
        />
        <path
          id="Path_100345"
          data-name="Path 100345"
          d="M17,11.429A1.429,1.429,0,1,1,15.571,10,1.429,1.429,0,0,1,17,11.429m1.143,3.331a2.9,2.9,0,0,0-2.571-1.331A2.9,2.9,0,0,0,13,14.759v.955h5.143Zm7.429-3.617H20.429v1.143h5.143Zm0,2.857H20.429v1.143h5.143Zm0,5.143H22.714v1.143h2.857Zm0,2.857H22.714v1.143h2.857ZM21,19.143H13v1.143h8Zm4.571,5.714H22.714V26h2.857Zm-4.571,0H13V26h8ZM19.286,22H13v1.143h6.286Z"
          transform="translate(-2.143 -3)"
          fill="#1976d2"
        />
      </g>
    </g>
  </svg>
);

