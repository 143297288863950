import * as React from "react"
export const ShortListDeleteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    data-name="Group 112518"
    {...props}
  >
    <path
      fill="#ff4b4b"
      d="M19.6 12a2.809 2.809 0 0 0-2.768 2.4H14.1a.539.539 0 0 0-.192 0H12.6a.6.6 0 1 0 0 1.2h.855l1.007 10.412A2.208 2.208 0 0 0 16.652 28h5.895a2.207 2.207 0 0 0 2.19-1.988L25.744 15.6h.856a.6.6 0 1 0 0-1.2h-1.3a.6.6 0 0 0-.192 0h-2.74A2.809 2.809 0 0 0 19.6 12Zm0 1.2a1.585 1.585 0 0 1 1.545 1.2h-3.09a1.585 1.585 0 0 1 1.545-1.2Zm-4.94 2.4h9.879l-1 10.3a.992.992 0 0 1-1 .9h-5.887a.993.993 0 0 1-1-.9Zm3.53 1.991a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609Zm2.8 0a.6.6 0 0 0-.591.609v6a.6.6 0 1 0 1.2 0v-6a.6.6 0 0 0-.609-.609Z"
      data-name="icons8-trash (2)"
    />
  </svg>
)
