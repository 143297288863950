import * as React from "react"

export const HandWashIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 96257">
      <path
        d="M14.909 2.001a.19.19 0 0 0-.169.1 9.024 9.024 0 0 0-1.01 2.44 1.179 1.179 0 0 0 2.357 0 9.019 9.019 0 0 0-1.01-2.44.19.19 0 0 0-.168-.1Zm-7.274.7a.589.589 0 0 0-.514.315L5.908 5.328l-2.545 3.3a4.909 4.909 0 0 0-1.023 3v1.607a3.821 3.821 0 0 0 2.585 3.617 19.246 19.246 0 0 0 5.859 1.151h3.143a1.777 1.777 0 0 0 1.769-1.768 1.731 1.731 0 0 0-.11-.589h.305a1.777 1.777 0 0 0 1.768-1.768 1.748 1.748 0 0 0-.473-1.178 1.749 1.749 0 0 0 .474-1.178 1.774 1.774 0 0 0-1.31-1.7 1.739 1.739 0 0 0 .131-.658 1.777 1.777 0 0 0-1.767-1.769H9.17a5.475 5.475 0 0 1 .186-.719 4.881 4.881 0 0 0 .421-1.885 2.029 2.029 0 0 0-1.086-1.783 2.823 2.823 0 0 0-1-.3h-.059Zm.304 1.286a.69.69 0 0 1 .2.062.71.71 0 0 1 .459.742 3.637 3.637 0 0 1-.338 1.451 4.491 4.491 0 0 0-.421 1.742.589.589 0 0 0 .589.589h6.285a.589.589 0 0 1 0 1.178h-3.142a.589.589 0 1 0 0 1.178h4.321a.589.589 0 1 1 0 1.178h-4.321a.589.589 0 1 0 0 1.178h4.321a.589.589 0 0 1 0 1.178h-4.321a.589.589 0 1 0 0 1.178h2.358a.589.589 0 0 1 0 1.178h-3.144a18.492 18.492 0 0 1-5.48-1.084 2.635 2.635 0 0 1-1.786-2.5v-1.611a3.729 3.729 0 0 1 .777-2.279l2.577-3.338a.589.589 0 0 0 .055-.086Z"
        fill="#98a0ac"
        stroke="#98a0ac"
        strokeWidth={0.25}
        data-name="Group 96235"
      />
    </g>
  </svg>
)


