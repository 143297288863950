import { Regular } from "../../utils/font";

 export const ButtonStyle ={
    main:{
        borderRadius: '21px',
        opacity: 1,
        textTransform: 'none',
        boxShadow:'none',
        display:'flex',
        alignItems:'center',
        fontSize:'0.875rem',
        fontWeight:600,
        fontFamily:Regular,
        padding:{
          md:'10px 0px',
          lg:'6px 16px'
        }
    }
}