import * as React from "react"

export const LocationFilledIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 110663">
      <path
        data-name="icons8-location (3)"
        d="M11.8 4a6.8 6.8 0 0 0-5.163 11.219c.121.137 2.971 3.376 3.923 4.283a1.794 1.794 0 0 0 2.48 0c1.107-1.056 3.81-4.154 3.928-4.29A6.8 6.8 0 0 0 11.8 4Zm0 8.8a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"
        fill="#78b1fe"
      />
    </g>
  </svg>
)

