import React, { useRef } from "react";
import ReactMapGL, {
  Marker,
  Popup,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, Typography } from "@mui/material";
import { MapBoxStyle } from "./style";
import "./map.css"
import { config } from "../../config";
import { LocalStorageKeys } from "../../utils";
import { LocationIcon, SchoolBuilding } from "../../assets";
import HospitalIcon from "./assets/hospital";
import HotelIcon from "./assets/hotelIcon";
import ParkIcon from "./assets/parks";
import TransportIcon from "./assets/transport";


export const NearByMap = ({
  latitude = "",
  longitude = "",
  properties = [],
  location = {},
  updateSelected = () => false,
  popupData = {},
  value = 0,
  height = ""
}) => {
  // const [km, setKms] = React.useState(5);
  const currentLocation = JSON.parse(localStorage.getItem(LocalStorageKeys.current_location))

  // change to see effect

  const [popupInfo, setPopupInfo] = React.useState(null);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [viewState, setViewState] = React.useState({
    zoom: 15,
  });

  const mapRef = useRef(null);
  console.log(openPopUp);


  React.useEffect(() => {
    if (latitude?.length === 0 && longitude?.length === 0) {

      if (location) {
        setViewState({ ...viewState, longitude: location?.longitude, latitude: location?.latitude });
      }
      else {
        setViewState({ ...viewState, longitude: currentLocation?.longitude, latitude: currentLocation?.latitude });
      }
      // }
    } else {
      setViewState({
        ...viewState,
        height: "100%",
        width: "100%",
        latitude: latitude,
        longitude: longitude,
      });
    }
    //eslint-disable-next-line
  }, [latitude, longitude]);

  const handleClickMarker = (val) => {
    setPopupInfo(val)
    updateSelected(val)
    setOpenPopUp(true)


  }
  const handleClickMarkerClose = (val) => {
    setPopupInfo({})
    setOpenPopUp(false)

  }
  React.useEffect(() => {
    handleClickMarker(popupData)
    //eslint-disable-next-line
  }, [popupData])

  return (
    <Box style={MapBoxStyle.radiusMain}>

      <ReactMapGL
        {...viewState}
        style={height ? MapBoxStyle.radiusMapheight : MapBoxStyle.radiusMap}
        ref={mapRef}
        mapboxAccessToken={config.mapbox_key}
        onViewportChange={(nextViewport) => setViewState(nextViewport)}
        onMove={evt => setViewState(evt.viewState)}
        mapStyle={config.mapbox_style}
      >
        {properties?.length > 0 &&
          properties?.map((val) => {
            return (
              <>
                <Marker
                  key={val?.id}
                  longitude={val?.longitude}
                  latitude={val?.latitude}
                  anchor="bottom"
                  onClick={(e) => {
                    e.originalEvent.stopPropagation();
                    handleClickMarker(val)

                  }}

                >
                  {value === 0 &&
                    <SchoolBuilding color={popupInfo?.id === val?.id ? "#1F74EA" : null} />
                  }
                  {value === 1 &&
                    <HospitalIcon color={popupInfo?.id === val?.id ? "#1F74EA" : null} />
                  }
                  {value === 2 &&
                    <HotelIcon color={popupInfo?.id === val?.id ? "#1F74EA" : null} />
                  }
                  {value === 3 &&
                    <ParkIcon color={popupInfo?.id === val?.id ? "#1F74EA" : null} />
                  }
                  {value === 4 &&
                    <TransportIcon color={popupInfo?.id === val?.id ? "#1F74EA" : null} />
                  }

                </Marker>

                {popupInfo?.id === val?.id &&
                  <Popup
                    key={val?.id}
                    anchor="top"
                    longitude={val?.longitude}
                    latitude={val?.latitude}
                    onClose={handleClickMarkerClose}
                    className='popup'
                    closeButton={false}
                  >
                    <>
                      <Box>
                        <Typography sx={MapBoxStyle.text}>
                          {val.name}
                        </Typography>
                        <Box sx={MapBoxStyle.box}>
                          <LocationIcon width={37} height={37} />{val.address}
                        </Box>
                      </Box>

                    </>
                  </Popup>
                }


              </>
            )
          })

        }

      </ReactMapGL>



    </Box >
  );
};
