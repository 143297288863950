

import { borderRadius } from "../../../utils";
import { Medium, Regular, SemiBold } from "../../../utils/font";

export const shortlistedStyle = {
    maincard: {
        height: "125px",
        borderRadius: borderRadius,
        backgroundColor: "text.default",
        display: "flex",
        position: "relative",
        border: "1px solid #E4E8EE",
        // boxShadow: 3,
        // maxWidth: "380px",
        width: "100%",
    },
    image: {
        height: "125px",
        width: "100%",
        objectFit: "cover",
        borderRadius: borderRadius,
    },
    maintext: {
        padding: "8px",
        width: "100%"
    },
    smallicons: {
        display: "flex",
        alignItems: "center"
    },
    textheader: {
        color: "text.default",
        fontSize: "0.75rem",
        borderRadius: borderRadius,
        fontFamily: Medium,
        backgroundColor: "#5AC782",
        height: "21px"

    },
    textexp: {
        color: "text.default",
        fontSize: "0.75rem",
        borderRadius: borderRadius,
        fontFamily: Medium,
        backgroundColor: "#CED3DD",
        height: "21px"
    },
    doticon: {
        color: "#4E5A6B",
        height: "6px",
        width: "6px",
        marginLeft: "4px",
        marginRight: "4px",
        mb: "4px"
    },
    units: {
        display: "flex",
        alignItems: "center",
        mt: "2px",
        '@media screen and (min-width: 320px)': {
            width: "60%"
        },
        '@media screen and (min-width: 375px)': {
            width: "80%"
        },
        '@media screen and (min-width: 425px)': {
            width: "90%"
        },
    },
    unittext1: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        color: "#4E5A6B",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        // maxWidth:{
        //     xs: "60px",
        //  },
        //  width:"100%"

    },
    // textcontent: {
    //     display: "flex",
    //     justifyContent: "space-between",
    //     // width: "100%"
    // },

    icons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mt: "4px",
        // textOverflow: "ellipsis",
        // overflow: "hidden",
        // whiteSpace: "nowrap",
        // width:"auto",
        // minWidth:"60px"
        '@media screen and (min-width: 320px)': {
            width: "60%"
        },
        '@media screen and (min-width: 375px)': {
            width: "80%"
        },
        '@media screen and (min-width: 425px)': {
            width: "90%"
        },

    },
    month: {
        fontFamily: Regular,
        fontSize: "1rem !important",

    },
    rupeestext: {
        fontSize: "1rem !important",
        fontFamily: Medium,
        color: "#091B29"
    },
    bedicon: {
        height: "20px",
        width: "20px"
    },
    doticongrey: {
        color: "#CED3DD",
        height: "6px",
        width: "6px",
        marginLeft: "4px",
        marginRight: "4px",
    },
    icontext: {
        color: "#98A0AC",
        fontSize: "0.875rem",
        fontFamily: Regular,
        marginLeft: "4px",
        marginRight: "-10px",
        marginTop: "2px",
    },

    texthead1: {
        color: "text.primary",
        fontSize: "1rem",
        fontFamily: Medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        '@media screen and (min-width: 320px)': {
            width: "60%"
        },
        '@media screen and (min-width: 375px)': {
            width: "80%"
        },
        '@media screen and (min-width: 425px)': {
            width: "90%"
        },
        // maxWidth:{
        //    xs: "60px",
        // },
        // width:"100%"
    },
    headerText: {
        mt: "0px",
        display: "flex",
        justifyContent: "space-between",
        // textOverflow: "ellipsis",
        // overflow: "hidden",
        // whiteSpace: "nowrap",
        // minWidth:"60px",
        '@media screen and (min-width: 320px)': {
            width: "60%"
        },
        '@media screen and (min-width: 375px)': {
            width: "80%"
        },
        '@media screen and (min-width: 425px)': {
            width: "90%"
        },

        // width:{
        //     320:"100px ",
        // }
    },
    amt: {
        fontFamily: Medium,
        fontSize: "0.75rem",
        color: "text.default",
        backgroundColor: "#000",
        height: "24px",
        maxWidth: "122px",
        width: "auto",
        padding: "8px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: "15px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"

    },
    month1: {
        fontFamily: Regular
    },
    pricebox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    chip: {
        position: "absolute",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        backgroundColor: "#30C4AB",
        height: "24px",
        width: "84px",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        left: "18px",
        right: " 22px",
        top: "6px",
        zIndex: "999"
    },
    imgChip: {
        position: "absolute",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        backgroundColor: "#30C4AB",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        right: 0,
        left: 0,
        textAlign: "center",
        margin: "0px auto",
        width: "80%",
        top: "8px",
        zIndex: "99",
        borderRadius: "50px",
        padding: "4px 8px"
    },

    mainbutton: {
        height: "40px",
        width: "40px",
        borderRadius: "20px",
        color: "#4E5A6B",
        fontFamily: Medium,
        fontSize: "0.875rem",
        border: "1px solid #E4E8EE",
        minWidth: "22px!important",
        "& .MuiButton-startIcon": {
            ml: 0,
            mr: 0
        }
    },
    button: {
        height: "40px",
        width: "40px",
        color: "#4E5A6B",
        fontFamily: Medium,
        fontSize: "0.875rem",
        minWidth: "22px!important",
        "& .MuiButton-startIcon": {
            ml: 0,
            mr: 0
        }
    },
    tickbutton: {
        height: "40px",
        width: "40px",
        minWidth: "22px!important",
        border: "none",
        "& .MuiButton-startIcon": {
            ml: "8px",
        }
    },

    subText: {
        color: "#4E5A6B",
        fontSize: "0.75rem",
        fontFamily: Medium,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginInlineStart: "8px"
    },
    Leftimage: {
        width: "100%",
        height: "152px",
        borderRadius: borderRadius,
    },
    startFrom: {
        color: "#091B29",
        fontFamily: Regular,
        fontSize: "0.75rem",
        marginBottom: "4px",
        marginTop: "6px"
    },
    startAmnt: {
        color: "#091B29",
        fontFamily: Medium,
        fontSize: "0.875rem",
    },
    startMonth: {
        color: "#4E5A6B",
        fontSize: "0.875rem",
        fontFamily: Regular
    },
    startTaxes: {
        color: "#4E5A6B",
        fontFamily: Regular,
        fontSize: "0.75rem",
        marginTop: "2px"
    },
    imgChips: {
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        backgroundColor: "#30C4AB",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textAlign: "center",
        borderRadius: "50px",
        padding: "4px 8px",
        width: "40%"
    },
    subTextDiv: {
        marginTop: "8px",
        marginBottom: "8px"
    },
    main: {
        backgroundColor: "#fff",
        border: "1px solid #E4E8EE",
        borderRadius: borderRadius,
        padding: "14px 16px 16px",
        height: "184px"
    },
    imageBoxe: {
        position: "relative"

    },
    rightContentGrid: {
        padding: "24px 10px 0px 10px"
    },
    heading: {
        mt: "-8px"
    },
    unitsSec: {
        mt: "4px"
    },
    iconsLists: {
        mt: "10px"
    }

}
