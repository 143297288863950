import { borderRadiusAlignments } from "../../utils";
import { Medium, Italic, Regular } from "../../utils/font";

export const PastBookingCardStyle = {
  mainBox: {
    display: "flex",
    height: "104px",
    backgroundColor: "primary.contrastText",
    padding: "4px",
    border: "1px solid #E4E8EE",
    borderRadius:borderRadiusAlignments?.borderEight,
    alignItems: "center",
    width: "273px"
  },
  mainimage: {
    display: "flex",
    alignItems: "center",
    padding: "8px 8px 8px 12px",
  },
  image: {
    height: "72px",
    width: "72px",
    borderRadius:borderRadiusAlignments?.borderEight
  },
  text: {
    marginLeft: "10px",
  },
  text1: {
    fontFamily: Medium,
    fontSize: "1rem",
    color: "text.primary",
    lineHeight: 1.7,
  },
  text2: {
    fontSize: "0.875rem !important",
    fontFamily: Regular,
    color: "text.tertiary",
    lineHeight: 1.7,
  },
  text3: {
    fontSize: "0.75rem !important",
    fontFamily: Italic,
    color: "text.tertiary",
    lineHeight: 1.7,
  },
};
