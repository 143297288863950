import * as React from "react"

export const SpoonIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      d="M12.241 1.003a.825.825 0 0 0-.708.311 11.8 11.8 0 0 0-2.159 7.263v.513a1.106 1.106 0 0 0 .7 1.027l1.148.46v3.452a.956.956 0 0 0 .825.967.92.92 0 0 0 1.018-.914V1.873a.871.871 0 0 0-.824-.87Zm-9.7.345a.553.553 0 0 0-.542.561v3.687a.553.553 0 0 0 0 .067 2.764 2.764 0 0 0 1.716 2.5l-.244 5.37v.184a1.291 1.291 0 1 0 2.581 0v-.184l-.244-5.37a2.764 2.764 0 0 0 1.716-2.5.553.553 0 0 0 0-.062V1.909a.553.553 0 1 0-1.106 0V4.49a.553.553 0 1 1-1.106 0V1.909a.553.553 0 1 0-1.106 0V4.49a.553.553 0 0 1-1.106 0V1.909a.553.553 0 0 0-.562-.561Z"
      fill="#5ac782"
      data-name="Group 111658"
    />
  </svg>
)

