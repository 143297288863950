import { Typography, Stack, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { shortlistedStyle } from "./style";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { HomeIcon } from "../../../assets/components";

export const ShortlistedCard = ({
    hotelname = "",
    unit = "",
    sqft = "",
    bed = "",
    room = "",
    bathtab = "",
    price = "",
    logo = "",
    validity = "",
    item,
    onClick = () => false

}) => {
    return (
        <>
            {/* image section */}




            <Box sx={shortlistedStyle.maincard} onClick={onClick}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Stack style={{ position: "relative" }}>
                            <Typography sx={shortlistedStyle?.imgChip}>{item?.unit_category}</Typography>
                            <Box
                                as="img"
                                src={item?.logo ?? "images/house.png"}
                                alt="house"
                                style={shortlistedStyle.image}>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={8} style={{ padding: "24px 10px 0px 10px" }}>
                        <Stack direction={"row"} justifyContent={"space-between"} >
                            <Typography sx={shortlistedStyle.amt} >{item?.currency_symbol ?? ""} {item?.price ?? ""} <span style={shortlistedStyle.month1}> / {item?.payment_period ?? ""}</span></Typography>
                            {/* {
                                validity === "active" ?
                                    <Chip sx={shortlistedStyle.textheader} label="Active" />
                                    :
                                    <Chip sx={shortlistedStyle.textexp} label="Expired" />
                            } */}
                        </Stack>

                        <Grid item xs={12} sm={12} mt={'8px'}>
                            <Typography noWrap sx={shortlistedStyle.texthead1} className="texthead1">
                                {item?.name}
                            </Typography>
                        </Grid>

                        <Grid container mt={"3px"}>
                            <Grid item xs={5.5} sm={5.5}>
                                <Typography noWrap sx={shortlistedStyle.unittext1} className="unittext1">
                                    {item?.unit_no}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <FiberManualRecordIcon sx={shortlistedStyle.doticon} />
                            </Grid>
                            <Grid item xs={5.5} sm={5.5}>
                                <Typography noWrap sx={shortlistedStyle.unittext1} className="unittext1">
                                    {item?.total_area ?? " "} {item?.area_metric ?? " "}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container mt={"2px"}>
                            {/* <Grid item xs={3} sm={3}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <HotelbedIcon />
                                    <Typography noWrap sx={shortlistedStyle.icontext}>{bed}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <FiberManualRecordIcon sx={shortlistedStyle.doticongrey} />
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <BathtabIcon />
                                    <Typography noWrap sx={shortlistedStyle.icontext}>{bathtab}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <FiberManualRecordIcon sx={shortlistedStyle.doticongrey} />
                            </Grid> */}
                            <Grid item xs={4} sm={3}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <HomeIcon />
                                    <Typography noWrap sx={shortlistedStyle.icontext}>{item?.type}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>
            </Box>
        </>
    );

}