import {
  Divider,
  Grid,
  Typography,
  Chip,
  Icon,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import React from "react";
import { lstingSearchStyle } from "./style";
import {
  BedroomIcon,
  Building,
  Floor,
  LocationIcon,
  ParkingIcon,
  RightArrow,
  SqftIcon,
} from "../../assets";
import { CustomButton } from "../Button";
import { withTranslation } from "react-i18next";
import { CarouselSliders } from "../imageCaurosel";
import { AuthContext } from "../../contexts";

const PropertyDetailCard = ({
  propertyId = '',
  header = "",
  subheader = "",
  buildDate = "",
  location = "",
  sqft = "",
  onebhk = "",
  twobhk = "",
  unitsAvailable = "",
  updatedDate = "",
  rate = "",
  currency = '',
  paymentType = '',
  offer = "",
  type = "",
  src = '',
  t=()=> false,
  logo = '',
  variant = "",
  onClick = () => false,
  val,
  handleImageClick = () => false

}) => {
  const auth = React.useContext(AuthContext)

  // const [open, setOpen] = React.useState(false);
  // const [alignment, setAlignment] = React.useState("");
  // const [image, setImage] = React.useState("");
  const history = useNavigate()

  // Handle Image Toggle

  // handle Image Click
  // const handleImageClick = (src) => {
  //   setOpen(true);
  //   setImage(src);
  // };

  const onView = () => {
    history(variant === 'resident' ? auth?.clientRoutes?.residentView : auth?.clientRoutes?.commercialView, { state: { propertyId: propertyId, title: header } });
  };

  return (
    <Box sx={lstingSearchStyle.main}>
      {/* Main Card */}
      <Grid container sx={lstingSearchStyle.inherit}>
        {/* Left Segment */}
        <Grid item xs={12} sm={12} lg={3} sx={lstingSearchStyle.relative}>
          <CarouselSliders data={val?.property_assets} logo={logo} onClick={() => handleImageClick(val)} />
          {/* {
            logo ?
  8            <Box
                as="img"
                src={logo}
                sx={lstingSearchStyle.image}
                onClick={() => handleImageClick(val)}
              />
              :
              <Box sx={lstingSearchStyle.noIg}>No Image Found</Box>
          } */}
          < Chip label={type} sx={lstingSearchStyle.chip} />
        </Grid>
        {/* Right Segment */}
        <Grid
          item
          container
          xs={12}
          sm={12}
          lg={9}
          sx={lstingSearchStyle.pl}
          spacing={2}
        >
          {/* Right 1st Segment */}
          <Grid item xs={8} sx={lstingSearchStyle.firstHalf}>
            {/* 1 */}
            <Typography sx={lstingSearchStyle.firstHeading}>
              {subheader}
            </Typography>
            <Typography sx={lstingSearchStyle.subHeading1}>
              {header}
            </Typography>
            {/* 2 */}

            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <Building />
                <Typography sx={lstingSearchStyle.subheadingCustom}>
                  <Typography as="span" sx={lstingSearchStyle.normalBlack}>
                    {buildDate} <span style={lstingSearchStyle.span}>Build</span>
                  </Typography>
                </Typography>
              </Stack>

              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <LocationIcon />
                <Typography sx={lstingSearchStyle.subheadingCustom}>
                  <Box as="span" sx={lstingSearchStyle.normalBlack}>
                    {location}
                  </Box>
                </Typography>
              </Stack>

              <Typography sx={lstingSearchStyle.underline} onClick={() => onClick(val)}>
                View on Map
              </Typography>

            </Stack>

            {/* 3 */}
            <Box sx={lstingSearchStyle.pdetails}>
              <Box sx={lstingSearchStyle.pdetails2}>
                {" "}
                <SqftIcon />
                <Typography sx={lstingSearchStyle.subHeading2}>
                  Area &nbsp;
                  <Box as="span" sx={lstingSearchStyle.normalBlack}>
                    {sqft}
                  </Box>
                </Typography>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              {variant === 'resident' ?
                <>
                  <Box sx={lstingSearchStyle.pdetails2}>
                    {" "}
                    <BedroomIcon />
                    <Typography sx={lstingSearchStyle.subHeading2}>
                      {"Pet Policy"}&nbsp;
                      <Box as="span" sx={lstingSearchStyle.normalBlack}>
                        {val?.pet_policy}
                      </Box>
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box sx={lstingSearchStyle.pdetails2}>
                    {" "}
                    <BedroomIcon />
                    <Typography sx={lstingSearchStyle.subHeading2}>
                      {"community"}&nbsp;
                      <Box as="span" sx={lstingSearchStyle.normalBlack}>
                        {val?.is_community ? "Yes" : "No"}
                      </Box>
                    </Typography>
                  </Box>
                </>
                :
                <>
                  <Box sx={lstingSearchStyle.pdetails2}>
                    {" "}
                    <Floor />
                    <Typography sx={lstingSearchStyle.subHeading2}>
                      Floor{" "}
                      <Box as="span" sx={lstingSearchStyle.normalBlack}>
                        {onebhk}
                      </Box>
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Box sx={lstingSearchStyle.pdetails2}>
                    {" "}
                    <ParkingIcon />
                    <Typography sx={lstingSearchStyle.subHeading2}>
                      Parking{" "}
                      <Box as="span" sx={lstingSearchStyle.normalBlack}>
                        {twobhk} Open
                      </Box>
                    </Typography>
                  </Box>
                </>
              }
            </Box>
            {/* 4 */}
            {/* <Box sx={lstingSearchStyle.pdetails3}>
              {" "}
              <BlueTick />
              
            </Box> */}
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Icon color="primary">check_circle</Icon>
              <Typography sx={lstingSearchStyle.subHeading3}>
                {unitsAvailable} Available
              </Typography>
            </Stack>
            <Typography sx={lstingSearchStyle.subheading}>
              Updated on {updatedDate}
            </Typography>
          </Grid>
          {/* Right 2nd Segment */}
          <Grid item xs={4} sx={lstingSearchStyle.secondHalf}>
            {/* {logo ? <Box as="img" sx={lstingSearchStyle.companyIcon} src={logo} ></Box>
              :
              null
            } */}
            <Box sx={lstingSearchStyle.alignEnd}>
              {rate !== null && <>
                <Typography sx={lstingSearchStyle.normalBlackWithRegular}>
                  Starts From
                </Typography>
                <Typography>
                  {currency} {rate}
                  <Box as="span" sx={lstingSearchStyle.subheading}>
                    {" "}
                    / {paymentType}
                  </Box>
                </Typography>
              </>}

              {offer && (
                <Typography sx={lstingSearchStyle.red}>
                  Upto {offer} offer
                </Typography>
              )}
            </Box>
            <Box sx={lstingSearchStyle.viewButton}>
              <CustomButton
                label={t("View Details")}
                variant="outlined"
                color="primary"
                endIcon={
                  <RightArrow rootStyle={lstingSearchStyle.rightArrow} />
                }
                onClick={() => onView()}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation()(PropertyDetailCard)