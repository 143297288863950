import * as React from "react"

export const Sort = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 112256"
    width={24}
    height={24}
    {...props}
  >
    <path
      d="M16.235 3a1 1 0 0 0-.692.293l-3.5 3.5a1 1 0 1 0 1.414 1.414l1.793-1.793V20a1 1 0 1 0 2 0V6.414l1.793 1.793a1 1 0 1 0 1.414-1.414l-3.5-3.5A1 1 0 0 0 16.235 3ZM7 3.5a.751.751 0 0 0-.694.466l-2.25 5.5a.75.75 0 0 0 1.389.567l.218-.533h2.676l.218.533a.75.75 0 1 0 1.389-.567l-2.25-5.5A.75.75 0 0 0 7 3.5Zm0 2.73L7.723 8H6.276ZM5.25 13.5a.75.75 0 0 0 0 1.5h2.134l-2.766 4.347a.75.75 0 0 0 .633 1.153h3.5a.75.75 0 1 0 0-1.5H6.616l2.767-4.348A.75.75 0 0 0 8.75 13.5Z"
      fill="#4e5a6b"
    />
  </svg>
)

