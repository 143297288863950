import { Button } from "@mui/material";
import React from "react";
import { ButtonStyle } from "./style";
import CircularProgress from '@mui/material/CircularProgress';


export const CustomButton = ({
  type = '',
  variant = '',
  size = 'medium',
  color = 'primary',
  label = '',
  rootStyle = {},
  startIcon,
  endIcon,
  onClick = () => false,
  disabled = false,
  load = false,
  val = [],
  fullWidth = false,
  ...rest
}) => {
  return (
    <Button
      variant={variant}
      type={type}
      size={size}
      color={color}
      sx={{ ...ButtonStyle.main, ...rootStyle }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={(e) => {
        e.stopPropagation()
        onClick(val)

      }}
      disabled={disabled}
      fullWidth={fullWidth}
      {...rest}
    >
      {load ? <CircularProgress color="inherit" /> : label}

    </Button>
  )
}