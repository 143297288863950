import * as React from "react"

export const ChooseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={64} height={64} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.275}
        y1={0.062}
        x2={0.74}
        y2={0.966}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#0d61a9" />
        <stop offset={1} stopColor="#16528c" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={0.275}
        y1={0.062}
        x2={0.74}
        y2={0.966}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#199ae0" />
        <stop offset={1} stopColor="#0782d8" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.275}
        y1={0.062}
        x2={0.74}
        y2={0.966}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#75daff" />
        <stop offset={1} stopColor="#1ea2e4" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={1961.955}
        y1={43.153}
        x2={1962.355}
        y2={43.153}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffc430" />
        <stop offset={0.57} stopColor="#f8bd29" />
        <stop offset={1} stopColor="#f0b421" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={0.09}
        y1={0.461}
        x2={1.207}
        y2={1.15}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ffc430" />
        <stop offset={0.41} stopColor="#fec130" />
        <stop offset={0.72} stopColor="#fcb730" />
        <stop offset={0.998} stopColor="#f7a72f" />
        <stop offset={1} stopColor="#f7a72f" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={0.349}
        y1={0.497}
        x2={-0.321}
        y2={0.338}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0.282} stopColor="#ffc430" />
        <stop offset={0.401} stopColor="#f8bd2d" />
        <stop offset={0.574} stopColor="#e5aa23" />
        <stop offset={0.78} stopColor="#c58a14" />
        <stop offset={1} stopColor="#9c6000" />
      </linearGradient>
    </defs>
    <g data-name="Group 110521" transform="translate(-515 -810)">
      <rect
        data-name="Rectangle 56287"
        width={64}
        height={64}
        rx={8}
        transform="translate(515 810)"
        fill="#fff3e2"
      />
      <g transform="translate(526.475 821)">
        <path
          data-name="Path 100118"
          d="M26.766 8.383A8.383 8.383 0 1 1 18.383 0a8.383 8.383 0 0 1 8.383 8.383Z"
          transform="translate(-.089)"
          fill="url(#a)"
        />
        <path
          data-name="Path 100119"
          d="M24.821 8.41A6.41 6.41 0 1 1 18.41 2a6.41 6.41 0 0 1 6.411 6.41Z"
          transform="translate(-.117 -.028)"
          fill="url(#b)"
        />
        <path
          data-name="Path 100120"
          d="M22.876 8.438A4.438 4.438 0 1 1 18.438 4a4.438 4.438 0 0 1 4.438 4.438Z"
          transform="translate(-.144 -.055)"
          fill="url(#c)"
        />
        <path
          data-name="Path 100121"
          d="M6.36 31.275v-.019Z"
          transform="translate(-.039 -.431)"
          fill="url(#d)"
        />
        <path
          data-name="Path 100122"
          d="m33.494 20.7-12.563-1.879V8.607a2.563 2.563 0 0 0-2.211-2.594A2.466 2.466 0 0 0 16 8.466v32.02a7.886 7.886 0 0 0 5.241 2H31.78a5.917 5.917 0 0 0 5.92-5.913v-11a4.931 4.931 0 0 0-4.206-4.873Z"
          transform="translate(-.172 -.083)"
          fill="url(#e)"
        />
        <path
          data-name="Path 100123"
          d="M21 19v5.917l2.574-5.533Z"
          transform="translate(-.241 -.262)"
          fill="url(#f)"
        />
        <circle
          data-name="Ellipse 129673"
          cx={1.479}
          cy={1.479}
          r={1.479}
          transform="translate(16.814 6.904)"
          fill="#fff0c9"
        />
        <g data-name="Group 110515">
          <path
            data-name="Path 100124"
            d="M5.989 23.669a2.25 2.25 0 0 0-2.464 2.458l11.918 13.934c.122.122.255.233.384.347v-14.5c-2.242 3.827-3.885.4-5.592.8a4.186 4.186 0 0 0-4.246-3.039Z"
            fill="#ffc430"
          />
        </g>
      </g>
    </g>
  </svg>
)

