import * as React from "react"
export const SignOutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      fill="#ff4b4b"
      d="M12 4a8 8 0 1 0 5.553 13.777.8.8 0 0 0-1.106-1.156A6.405 6.405 0 1 1 12 5.6a6.342 6.342 0 0 1 4.445 1.775.8.8 0 1 0 1.112-1.15A7.964 7.964 0 0 0 12 4Zm4.791 4.792a.8.8 0 0 0-.557 1.373l1.035 1.035H10a.8.8 0 1 0 0 1.6h7.269l-1.034 1.034a.8.8 0 1 0 1.131 1.131l2.4-2.4a.8.8 0 0 0 0-1.131l-2.4-2.4a.8.8 0 0 0-.575-.242Z"
      data-name="Group 112542"
    />
  </svg>
)
