import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const policiesStyle = {
    list: {
        color: '#4E5A6B',
        fontSize: "0.875rem",
        marginTop: "8px",
        fontFamily: Regular
    },
    ul: {
        marginLeft: '-20px'
    },
    transform: {
        textTransform: 'none',
        color: '#4E5A6B',
        fontSize: "0.875rem",
        fontFamily: SemiBold
    },
    tab: {
        borderBottom: 1,
        borderColor: 'divider',
        "& .MuiTabs-scroller": {
            overflow: "auto !important"
        }
    },
    box: {
        backgroundColor: "#F5F7FA",
        borderRadius: borderRadius, mt: '12px'
    },
    tabhead: {
        "& .MuiTabs-scroller": {
            overflowX: "auto!important"
        }
    },
    headText: {
        color: 'text.primary',
        fontSize: "1rem",
        fontFamily: Medium,
    }

}