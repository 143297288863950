import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { TextFeildStyle } from './style';
import { Label } from "../label";

export const TextBox = ({
  label = "",
  multiline = false,
  type = "text",
  placeholder = "",
  value = '',
  id = '',
  isReadonly = false,
  onChange = () => false,
  onBlur = () => false,
  onClick = () => false,
  endAdornment,
  startAdornment,
  defaultValue = '',
  autoFocus = false,
  rowheight = '',
  autocomplete = false,
  isrequired = false,
  variant = 'outlined',
  fontSize = '',
  error = '',
  helperText = '',
  isError = false,
  errorMessage = '',
  isSearch = false,
  fieldStyle = {},
  ...rest
}) => {

  return (
    <Box sx={TextFeildStyle.root}>
      <>
        {label && <Label label={label} isRequired={isrequired} />}
        <Box sx={TextFeildStyle.text}>
          <TextField
            sx={isSearch ? { ...TextFeildStyle.searchTexbox, ...fieldStyle } : TextFeildStyle.textbox}
            type={type}
            onKeyPress={(e) => {
              if (type === "number") {
                if (e.key === "e") {
                  e.preventDefault();
                }
              }
            }}
            id={id}
            placeholder={placeholder}
            variant={variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              readOnly: isReadonly ?? false,
              autocomplete: autocomplete,
            }}
            InputProps={{
              endAdornment: endAdornment,
              startAdornment: startAdornment,
              style: {
                fontSize: fontSize ?? "1.4rem",
                padding: multiline ? 0 : "none",
              },
            }}
            disabled={isReadonly ?? false}
            size="small"
            multiline={multiline}
            rows={rowheight ?? 3}
            autoFocus={autoFocus}
            rowsMax={10}
            onChange={onChange}
            onClick={onClick}
            value={value ?? ""}
            error={error}
            helperText={helperText}
            defaultValue={defaultValue ?? ""}
            onBlur={onBlur}
            onWheel={(e) => e.target.blur()}
          />
        </Box>
      </>
      {isError && (
        <Typography variant={"caption"} color={"error"}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

