import { MapviewCardStyle } from "./style";
import {
  CardMedia,
  Card,
  CardContent,
  Typography,
  Box,
  Stack
} from "@mui/material";
import React from "react";
import { LocationIcon, BlueTick } from "../../assets";
import { useNavigate } from "react-router-dom";
import { CarouselSliders } from "../imageCaurosel";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../contexts";

const MapviewCard = ({
  propertyId = '',
  header = "",
  subheader = "",
  buildDate = "",
  location = "",
  sqft = "",
  onebhk = "",
  twobhk = "",
  unitsAvailable = "",
  updatedDate = "",
  rate = "",
  currency = '',
  paymentType = '',
  offer = "",
  type = "",
  logo = '',
  src = "/images/NoPath - Copy (10)@2x.jpg",
  variant = '',
  val = {},
  t = () => false,
  searches,
  filters,
  selected,
  revenue_type,
  types
}) => {
  const history = useNavigate();
  const language = localStorage.getItem("i18nextLng")
  const auth = React.useContext(AuthContext)

  const onRedirect = () => {
    history(variant === 'resident' ? auth?.clientRoutes?.residentView : auth?.clientRoutes?.commercialView, {
      state: {
        propertyId: propertyId, title: header,
        searches,
        filters,
        selected,
        revenue_type,
        types
      }
    });
  }
  return (
    // main card images and chip label
    <Card sx={MapviewCardStyle.main} >
      <Typography sx={language === "ar" ? MapviewCardStyle.chipArabic : MapviewCardStyle.chip}>{type}</Typography>
      {/* <Typography sx={MapviewCardStyle.chipRight}>{`${currency}${rate} / ${paymentType}`}</Typography> */}
      <CardMedia
        sx={MapviewCardStyle.image}
      >
        <CarouselSliders data={val?.property_assets} logo={logo} />
      </CardMedia>
      {/* card title content */}
      <CardContent sx={MapviewCardStyle.cardcontent} onClick={() => onRedirect()}>
        {/* Old UI with Area, Build Don't Delete This */}
        {/* 
        <Typography sx={MapviewCardStyle.maintext}>
          {subheader}
        </Typography>
        <Typography sx={MapviewCardStyle.subText}>{header}</Typography>

        <Box sx={MapviewCardStyle.g1}>
          <Box sx={MapviewCardStyle.mapIcons}>
            {" "}
            <Building />
            <Typography sx={MapviewCardStyle.subheading}>
              <Box as="span" sx={MapviewCardStyle.normalBlack}>
                {buildDate} Build
              </Box>
            </Typography>
          </Box>
          <Box sx={MapviewCardStyle.mapIcons}>
            {" "}
            <LocationIcon />
            <Typography sx={MapviewCardStyle.subheading}>
              <Box as="span" sx={MapviewCardStyle.normalBlack}>
                {location}
              </Box>
            </Typography>
          </Box>
        </Box>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} pt={1}>
          <Stack direction={"row"} spacing={1} divider={<Divider orientation="vertical" flexItem />} sx={MapviewCardStyle.mapAreaBox}>

            <Stack pb={2}>
              <Typography sx={MapviewCardStyle.subheading2}>
                Area
              </Typography>
              <Typography sx={MapviewCardStyle.subheading}>
                {sqft}
              </Typography>
            </Stack>
            {
              variant === "resident" ?
                <Stack direction={"row"} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                  <Stack pb={2}>
                    <Typography sx={MapviewCardStyle.subheading2}>
                      Pet Policy
                    </Typography>
                    <Typography sx={MapviewCardStyle.subheading}>
                      {val?.pet_policy}
                    </Typography>
                  </Stack>
                  <Stack pb={2}>
                    <Typography sx={MapviewCardStyle.subheading2}>
                      Community
                    </Typography>
                    <Typography sx={MapviewCardStyle.subheading}>
                      {val?.is_community ? 'Yes' : 'No'}
                    </Typography>
                  </Stack>
                </Stack>
                :
                <Stack direction={"row"} spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                  <Stack>
                    <Typography sx={MapviewCardStyle.subheading2}>
                      Floor
                    </Typography>
                    <Typography sx={MapviewCardStyle.subheading}>
                      {onebhk}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography sx={MapviewCardStyle.subheading2}>
                      Parking
                    </Typography>
                    <Typography sx={MapviewCardStyle.subheading}>
                      {twobhk}
                    </Typography>
                  </Stack>
                </Stack>
            }
          </Stack>
        </Stack> */}
        <Typography sx={MapviewCardStyle?.textheading}>{subheader}</Typography>
        <Stack direction={"row"} alignItems={"center"} sx={MapviewCardStyle?.subTextDiv}>
          <LocationIcon />
          <Typography sx={MapviewCardStyle?.subTextss}>{location}</Typography>
        </Stack>

        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Stack>
            <Typography sx={MapviewCardStyle?.startFrom}>{t("Starts From")}</Typography>
            <Typography sx={MapviewCardStyle?.startAmnt}>{`${currency} ${rate}`}  <span style={MapviewCardStyle.startMonth}>/ {paymentType}</span></Typography>
            <Typography sx={MapviewCardStyle?.startTaxes}>+ {t("taxes & charges")}</Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} sx={MapviewCardStyle?.tickTextStyle}>
            <Box sx={MapviewCardStyle?.availabletBlueIcon}>
              <BlueTick />
            </Box>
            <Typography sx={MapviewCardStyle?.availabletText}>{unitsAvailable} {t("Available")}</Typography>
          </Stack>
        </Stack>














        {/* <Box sx={MapviewCardStyle.alCenter}>

        </Box> */}
      </CardContent>
      {/* {card buttons} */}

      {/* logo comment */}
      {/* {logo ? <Box as="img" sx={MapviewCardStyle.logo}
            src={logo}
          />
            : null} */}
    </Card>
  );
};
export default withTranslation("mapviewCard")(MapviewCard)