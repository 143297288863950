import * as React from "react"
export const ShareBlackIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 112366">
      <path
        fill="#4e5a6b"
        stroke="#4e5a6b"
        strokeWidth={0.3}
        d="M16.605 5A2.395 2.395 0 0 0 14.3 8.024l-.042.021-4.933 2.464-.042.021a2.394 2.394 0 1 0 0 2.94l.041.021 4.931 2.465.04.02a2.4 2.4 0 1 0 .531-.97l-.078-.039L9.819 12.5l-.077-.038a2.4 2.4 0 0 0 0-.928l.078-.039 4.93-2.465.074-.03a2.395 2.395 0 1 0 1.781-4Z"
        data-name="icons8-share (1)"
      />
    </g>
  </svg>
)
