import { SvgIcon } from "@mui/material"
import * as React from "react"

export const WhiteTick = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:'#1f74ea',
      ...rootStyle,
    };

    return(
        <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
        <g data-name="Group 110511" transform="translate(-660 -305)">
          <circle
            data-name="Ellipse 129668"
            cx={8}
            cy={8}
            r={8}
            transform="translate(660 305)"
            fill="#fff"
          />
          <path
            data-name="Path 100111"
            d="m662.01 312.431 3.44 3.118 5.666-6.378"
            fill="none"
            stroke="currentColor"
            strokeWidth={1.99958}
          />
        </g>
      </SvgIcon>
    )
 
}

