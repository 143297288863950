import React from "react";
import { withNavBars } from "../../HOCs";
import { withTranslation } from 'react-i18next';

class ShortlistParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return <></>;
  }
}
const props = {
  key: 0
}
export default withNavBars(withTranslation('shortlist')(ShortlistParent), props);

