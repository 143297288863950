import { Box, Chip, Rating, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { StaysMapCardStyle } from './style'
import CheckIcon from '@mui/icons-material/Check';
import { SpoonIcon } from "../../assets/components/spoonIcon";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { BedroomIcon } from "../../assets/components/bedroom";

export const StaysMapCard = ({
    onClick = "",
    amount = '',
    apartmentName = '',
    address = '',
    availableRooms = '',
    isMobileView = () => false
}) => {
    return (
        // image and chip label
        <Box sx={StaysMapCardStyle.mainBox}>
            <Box sx={StaysMapCardStyle.imageBox}>
                <Chip label="Apartment" sx={StaysMapCardStyle.chip1} />
                <Typography sx={StaysMapCardStyle.chip2}>{amount} <span style={StaysMapCardStyle.month}>/ Night</span></Typography>
                <Box as='img'
                    // src="./images/card.png"
                    src="https://fastly.picsum.photos/id/946/318/166.jpg?hmac=L3CBwwKVd9P1pQFT7cF3s9sjN2tY2IfCUXQdDXJjyEs"
                    alt="game logo"
                    sx={StaysMapCardStyle.image}
                />
            </Box>
            {/*Apartment name , location and rating */}
            {
                isMobileView ?
                    <Box sx={StaysMapCardStyle.textBox}>
                        <Typography sx={StaysMapCardStyle.text}>{apartmentName} </Typography>
                        <Typography sx={StaysMapCardStyle.subtext}>{address}</Typography>
                        <Box display={"flex"} >
                            <Box sx={StaysMapCardStyle.iconBox} >
                                <CheckIcon sx={StaysMapCardStyle.CheckIcon} />
                                <Typography sx={StaysMapCardStyle.canceltext}>{"Free cancellation"} </Typography>
                            </Box>
                            <Box sx={StaysMapCardStyle.iconBox} >
                                <SpoonIcon sx={StaysMapCardStyle.CheckIcon} />
                                <Typography sx={StaysMapCardStyle.canceltext}>{"Complimentary breakfast"} </Typography>
                            </Box>
                        </Box>
                        <Box sx={StaysMapCardStyle.bottombox}>
                            <Box sx={StaysMapCardStyle.ratingBox1}>
                                <Stack spacing={1}>
                                    <Rating name="half-rating" defaultValue={4.5} precision={0.5} sx={StaysMapCardStyle.rating} />
                                </Stack>
                                <Typography sx={StaysMapCardStyle.ratingtext}>(4.5 rating | 220 reviews)</Typography>
                            </Box>
                            <Box>
                                <Box sx={StaysMapCardStyle.bb}>
                                    <Box sx={StaysMapCardStyle.roombox}>
                                        <BedroomIcon rootStyle={StaysMapCardStyle.bed} />
                                        <Typography sx={StaysMapCardStyle.availabletext}>{availableRooms} </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    :
                    <Box sx={StaysMapCardStyle.textBox}>
                        <Typography sx={StaysMapCardStyle.text}>{apartmentName} </Typography>
                        <Typography sx={StaysMapCardStyle.subtext}>{address}</Typography>
                        <Box sx={StaysMapCardStyle.ratingBox}>
                            <Stack spacing={1}>
                                <Rating name="half-rating" defaultValue={4.5} precision={0.5} sx={StaysMapCardStyle.rating} />
                            </Stack>
                            <Typography sx={StaysMapCardStyle.ratingtext}>(4.5 rating | 220 reviews)</Typography>
                        </Box>
                        <Box sx={StaysMapCardStyle.iconBox} >
                            <CheckIcon sx={StaysMapCardStyle.CheckIcon} />
                            <Typography sx={StaysMapCardStyle.canceltext}>{"Free cancellation"} </Typography>
                        </Box>
                        <Box sx={StaysMapCardStyle.iconBox} >
                            <SpoonIcon sx={StaysMapCardStyle.CheckIcon} />
                            <Typography sx={StaysMapCardStyle.canceltext}>{"Complimentary breakfast"} </Typography>
                        </Box>
                        <Box sx={StaysMapCardStyle.bb}>
                            <Box sx={StaysMapCardStyle.roombox}>
                                <BedroomIcon rootStyle={StaysMapCardStyle.bed} />
                                <Typography sx={StaysMapCardStyle.availabletext}>{availableRooms} </Typography>
                            </Box>
                            <Box sx={StaysMapCardStyle.roundBox} onClick={onclick}>
                                <ArrowForwardIosIcon sx={StaysMapCardStyle.arrow} />
                            </Box>
                        </Box>
                    </Box>

            }
        </Box>
    )
}