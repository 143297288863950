import * as React from "react";
export const Spoon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      id="Group_111658"
      data-name="Group 111658"
      transform="translate(-815.001 -287)"
    >
      <g
        id="Rectangle_57075"
        data-name="Rectangle 57075"
        transform="translate(815.001 287)"
        fill="#fff"
        stroke="#707070"
        strokeWidth={1}
        opacity={0}
      >
        <rect width={16} height={16} stroke="none" />
        <rect x={0.5} y={0.5} width={15} height={15} fill="none" />
      </g>
      <path
        id="icons8-cutlery"
        d="M18.242,5.04a.825.825,0,0,0-.708.311,11.8,11.8,0,0,0-2.159,7.263v.513a1.106,1.106,0,0,0,.7,1.027l1.148.46v3.452a.956.956,0,0,0,.825.967.92.92,0,0,0,1.018-.914V5.91A.871.871,0,0,0,18.242,5.04Zm-9.7.345A.553.553,0,0,0,8,5.946V9.633A.553.553,0,0,0,8,9.7a2.764,2.764,0,0,0,1.716,2.5l-.244,5.37v.184a1.291,1.291,0,1,0,2.581,0v-.184l-.244-5.37a2.764,2.764,0,0,0,1.716-2.5.553.553,0,0,0,0-.062V5.946a.553.553,0,1,0-1.106,0V8.527a.553.553,0,1,1-1.106,0V5.946a.553.553,0,1,0-1.106,0V8.527a.553.553,0,0,1-1.106,0V5.946a.553.553,0,0,0-.562-.561Z"
        transform="translate(809 282.963)"
        fill="#5ac782"
      />
    </g>
  </svg>
);
 