import { Box, Typography } from "@mui/material";
import { BookingCardStyle } from "./style";

export const BookingCard = ({
  src = "",
  Textlabel = "",
  descriptionlabel = "",
}) => {
  return (
    // Main card and images
    <Box sx={BookingCardStyle.topbox}>
      <Box sx={BookingCardStyle.boximage}>
        <Box sx={BookingCardStyle.image}>{src}</Box>
      </Box>
      {/* Card text content */}
      <Box sx={BookingCardStyle.text}>
        <Typography sx={BookingCardStyle.maintext}>{Textlabel}</Typography>
        <Typography sx={BookingCardStyle.subtext}>
          {descriptionlabel}
        </Typography>
      </Box>
    </Box>
  );
};
