import { SvgIcon } from "@mui/material"
import * as React from "react"

export const SqftIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 18.324,
      height: 15.985,
      color:"#4e5a6b",
      ...rootStyle,
    };
    return(
      <SvgIcon
    data-name="SQ FT"
    xmlns="http://www.w3.org/2000/svg"
    sx={rootSx}
    {...rest}
  >
    <path
      data-name="Path 97264"
      d="M1.427 7.992h1.644v2.182H5.68l.573 2.288 2.788.5.379 1.882H1.427Z"
      fill="#ced3dd"
      opacity={0.3}
    />
    <path
      data-name="Path 97265"
      d="M17.155 1.142H15.51v2.181h-3.181v2.288l-2.788.5-.379 1.881h7.993Z"
      fill="#ced3dd"
      opacity={0.3}
    />
    <path
      d="M.694 0A.7.7 0 0 0 0 .715v14.1a1.185 1.185 0 0 0 1.175 1.175h7.518a1.185 1.185 0 0 0 1.175-1.175v-1.884a1.185 1.185 0 0 0-1.175-1.175H7.048v-1.174a1.185 1.185 0 0 0-1.175-1.175H4.229V8.233a1.185 1.185 0 0 0-1.175-1.175H1.409V.715A.7.7 0 0 0 .693 0Zm2.83.01a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.699Zm2.819 0a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.699Zm2.819 0a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.699Zm2.819 0a.7.7 0 1 0 .7.7.7.7 0 0 0-.7-.699Zm3.289 0a1.185 1.185 0 0 0-1.17 1.175V2.36h-1.649a1.185 1.185 0 0 0-1.175 1.175V4.71H9.632a1.185 1.185 0 0 0-1.175 1.175v1.879a1.185 1.185 0 0 0 1.175 1.175h7.283v6.343a.705.705 0 1 0 1.41 0V8.233a.7.7 0 0 0-.03-.215 1.138 1.138 0 0 0 .03-.255V1.185A1.185 1.185 0 0 0 17.15.011Zm.235 1.41h1.41v6.108H9.867V6.122h2.114a.7.7 0 0 0 .7-.7V3.769H14.8a.7.7 0 0 0 .7-.7Zm-14.1 7.048h1.41v1.644a.7.7 0 0 0 .7.7h2.114v1.65a.7.7 0 0 0 .7.7h2.114v1.41H1.409Zm10.572 6.108a.7.7 0 1 0 .7.7.7.7 0 0 0-.696-.7Zm2.819 0a.7.7 0 1 0 .7.7.7.7 0 0 0-.696-.7Z"
      fill="#4e5a6b"
    />
    </SvgIcon>
    )
  
}

