import * as React from "react"

export const RestaurantIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="m27.477 9.704-8.669-6.83a4.05 4.05 0 0 0-5.028 0l-8.669 6.83A5.509 5.509 0 0 0 3 14.056v12.62a3.328 3.328 0 0 0 3.323 3.323h5.539v-7.51a4.069 4.069 0 0 1-2.954-3.9v-6.282a.739.739 0 1 1 1.477 0v4.8a.37.37 0 0 0 .369.369h1.108a.37.37 0 0 0 .369-.369v-4.8a.739.739 0 0 1 1.477 0v4.8a.37.37 0 0 0 .369.369h1.108a.37.37 0 0 0 .369-.369v-4.8a.739.739 0 0 1 1.477 0v6.278a4.069 4.069 0 0 1-2.954 3.9v7.511h5.17V12.307a.738.738 0 0 1 1.2-.576c.131.1 3.231 2.669 3.231 10.029v.696a1.908 1.908 0 0 1-.563 1.357l-1.653 1.653v4.536h4.8a3.328 3.328 0 0 0 3.323-3.323V14.056a5.509 5.509 0 0 0-2.108-4.352Z"
      fill="#ff4b4b"
      data-name="Group 111090"
    />
  </svg>
)


