import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { LocationIcon } from "../../assets";
import { MapBoxStyle } from "./style";
import { useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { AuthContext } from "../../contexts";

const PopupCard = ({
  popupInfo = {},
  unit = false,
  onViewButton = false,
  variant = "",
  selected,
  location = {},
  searches,
  filters,
  revenue_type,
  types
}) => {
  const history = useNavigate()
  const auth = React.useContext(AuthContext)

  const onView = (val) => {
    if (onViewButton) {
      history(variant === 'resident' ? auth?.clientRoutes?.residentView : auth?.clientRoutes?.commercialView,
        {
          state: {
            propertyId: val?.id, title: val?.header,
            searches,
            filters,
            selected,
            revenue_type,
            types,

          }
        });
    } else {
      return false
    }
  };

  return (
    <>
      {unit && Object.keys(popupInfo)?.length > 0 ?
        <Box sx={MapBoxStyle.mainFlex}>
          <Box as="img" src={popupInfo?.logo !== null ? popupInfo.logo : '/images/badminton.png'} sx={MapBoxStyle?.logo} />
          <Box sx={MapBoxStyle.content}>
            <Box sx={MapBoxStyle.g1}>
              {popupInfo?.name}
            </Box>

            <Box sx={MapBoxStyle.bold}>{popupInfo?.unit_no}</Box>
            <Box sx={MapBoxStyle.flex3}>
              <Box sx={MapBoxStyle.flex}>
                <LocationIcon />
                {popupInfo?.door_no ? popupInfo?.door_no : ""} {(popupInfo?.door_no && popupInfo?.street_1) && ","}
                {popupInfo?.street_1 ? popupInfo?.street_1 : ""}{(popupInfo?.street_1 && popupInfo?.street_2) && ","}
                {popupInfo?.street_2 ? popupInfo?.street_2 : ""}{(popupInfo?.street_2 && popupInfo?.street_3) && ","}
                {popupInfo?.street_3 ? popupInfo?.street_3 : ""}
                {`${popupInfo?.city ? popupInfo?.city : ""} ${(popupInfo?.city && popupInfo?.district) && ","} ${popupInfo?.district ? popupInfo?.district : ""}${(popupInfo?.district && popupInfo?.state) && ","}${popupInfo?.state ? popupInfo?.state : ""} `}

              </Box>
            </Box>
          </Box>
        </Box>
        :
        // {/* PopCard Old UI Starts Here Pls Don't Delete This /* }
        // Object.keys(popupInfo).length > 0 &&
        // <Box sx={MapBoxStyle.mainFlex} onClick={() => onView(popupInfo)}>
        //   <Box as="img" src={popupInfo?.logo !== null ? popupInfo.logo : '/images/badminton.png'} sx={MapBoxStyle?.logo} />
        //   <Box sx={MapBoxStyle.content}>
        //     <Box sx={MapBoxStyle.g1}>
        //       <Chip label={popupInfo?.type} sx={MapBoxStyle.blueChip} />
        //       <Chip
        //         icon={<BlueTick rootStyle={MapBoxStyle.blueIcon} />}
        //         label={`${popupInfo?.unitsAvailable} Vacant Units`}
        //         sx={MapBoxStyle.px}
        //       />
        //     </Box>

        //     <Box sx={MapBoxStyle.bold}>{popupInfo?.subheader}</Box>
        //     <Box sx={MapBoxStyle.flex3}>
        //       <Box sx={MapBoxStyle.flex}>
        //         <Box sx={MapBoxStyle.flex}>
        //           <Building />
        //           {popupInfo?.buildDate}  <Box as='span' sx={MapBoxStyle.secondary}>Build</Box>
        //         </Box>
        //         <LocationIcon />
        //         {popupInfo?.location}
        //       </Box>
        //     </Box>
        //   </Box>
        // </Box>


        // {/* PopCard Old UI Ends Here Pls Don't Delete This /* }


        Object.keys(popupInfo)?.length > 0 &&

        <Box sx={MapBoxStyle.mainFlexNew} onClick={() => onView(popupInfo)}>

          <Stack direction={"row"} spacing={1.5} >
            <Box as="img" src={popupInfo?.logo !== null ? popupInfo.logo : '/images/badminton.png'} sx={MapBoxStyle?.logoNew} />
            <Stack direction={"column"} spacing={"4px"} width={"100%"}>
              <Stack direction={"row"}>
                <Typography sx={MapBoxStyle.blueChipNew}>{popupInfo?.type}</Typography>
                {popupInfo?.is_community && <Typography sx={MapBoxStyle.community}>{("Community")}</Typography>}
              </Stack>
              <Typography sx={MapBoxStyle.boldHeader}>{popupInfo?.subheader}</Typography>
              <Typography sx={MapBoxStyle.locationText}>{popupInfo?.location}</Typography>
              <Typography sx={MapBoxStyle.vacantUnitsText}>{`${popupInfo?.unitsAvailable} Units Available`}</Typography>
            </Stack>
          </Stack>

          {/* <Grid container spacing={1.5}>
            <Grid item xs={4} sm={4} md={4} lg={3.5}>
              <Box as="img" src={popupInfo?.logo !== null ? popupInfo.logo : '/images/badminton.png'} sx={MapBoxStyle?.logoNew} />

            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8.5}>
              <Box sx={MapBoxStyle.content}>
                <Box sx={MapBoxStyle.titleDiv}>
                  <Typography sx={MapBoxStyle.blueChipNew}>{popupInfo?.type}</Typography>
                </Box>
                <Box>
                  <Typography sx={MapBoxStyle.boldHeader}>{popupInfo?.subheader}</Typography>
                </Box>
                <Typography sx={MapBoxStyle.locationText}>{popupInfo?.location}</Typography>
                <Box sx={MapBoxStyle.g1}>
                  <Stack direction={"row"} alignItems={"center"} sx={MapBoxStyle.vacantUnits}>
                    <Typography sx={MapBoxStyle.vacantUnitsText}>{`${popupInfo?.unitsAvailable} Units Available`}</Typography>
                  </Stack>
                </Box>
              </Box>

            </Grid>

          </Grid> */}

          {/* // old Ui pls don't delete this */}

          {/* <Box as="img" src={popupInfo?.logo !== null ? popupInfo.logo : '/images/badminton.png'} sx={MapBoxStyle?.logoNew} />
          <Box sx={MapBoxStyle.content}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography sx={MapBoxStyle.boldHeader}>{popupInfo?.subheader}</Typography>
              <MapDeleteIcon style={{ textAlign: "right" }} />
            </Stack>
            <Box sx={MapBoxStyle.flex}>
              <LocationIcon />
              <Typography sx={MapBoxStyle.locationText}>{popupInfo?.location}</Typography>
            </Box>
            <Box sx={MapBoxStyle.g1}>
              <Chip
                icon={<BlueTick rootStyle={MapBoxStyle.blueIcon} />}
                label={`${popupInfo?.unitsAvailable} Vacant Units`}
                sx={MapBoxStyle.pxNew}
              />
              <Chip label={popupInfo?.type} sx={MapBoxStyle.blueChipNew} />
            </Box>
            <Box>
              <Typography sx={MapBoxStyle?.startFrom}>Starts From</Typography>
              <Typography sx={MapBoxStyle?.startAmnt}>$ {popupInfo?.startfrom_price} <span style={MapBoxStyle.startMonth}>/ Month</span></Typography>
              <Typography sx={MapBoxStyle?.startTaxes}>+ taxes & charges</Typography>
            </Box>
          </Box> */}
        </Box>


      }
    </>
  )
}
export default (withTranslation('popupCard')(PopupCard));