import * as React from "react";
export const MailForgot = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    viewBox="0 0 80 80"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={0.348}
        y1={4.151}
        x2={1.016}
        y2={2.878}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5059c9" />
        <stop offset={0.999} stopColor="#4750b3" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1={-0.039}
        y1={3.899}
        x2={0.808}
        y2={3.132}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5961c3" />
        <stop offset={1} stopColor="#3a41ac" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1={0.348}
        y1={3.278}
        x2={1.016}
        y2={2.005}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#28afea" />
        <stop offset={1} stopColor="#0b88da" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-4"
        x1={-0.039}
        y1={3.039}
        x2={0.807}
        y2={2.27}
        xlinkHref="#linear-gradient-3"
      />
      <linearGradient
        id="linear-gradient-5"
        x1={13.012}
        y1={52.341}
        x2={13.012}
        y2={51.223}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c965eb" />
        <stop offset={1} stopColor="#c767e5" />
      </linearGradient>
    </defs>
    <g
      id="Group_110801"
      data-name="Group 110801"
      transform="translate(-595.521 -266.521)"
    >
      <g
        id="Rectangle_56376"
        data-name="Rectangle 56376"
        transform="translate(595.521 266.521)"
        fill="#fff"
        stroke="#707070"
        strokeWidth={1}
        opacity={0}
      >
        <rect width={80} height={80} stroke="none" />
        <rect x={0.5} y={0.5} width={79} height={79} fill="none" />
      </g>
      <g id="icons8-send-email" transform="translate(604.783 275.783)">
        <path
          id="Path_100179"
          data-name="Path 100179"
          d="M8.857,25.991,51.515,9.036V40.21a2.369,2.369,0,0,1-2.37,2.37H8.857Z"
          transform="translate(-1.748 -4.662)"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_100180"
          data-name="Path 100180"
          d="M4,9V40.3a2.37,2.37,0,0,0,2.37,2.37H51.4a2.351,2.351,0,0,0,1.521-.566Z"
          transform="translate(-4 -4.679)"
          fill="url(#linear-gradient-2)"
        />
        <path
          id="Path_100181"
          data-name="Path 100181"
          d="M6.37,6.048H51.4a2.369,2.369,0,0,1,2.37,2.37v2.005L31.552,25.558a4.736,4.736,0,0,1-5.337,0L4,10.422V8.416A2.369,2.369,0,0,1,6.37,6.048Z"
          transform="translate(-4 -6.048)"
          fill="#7b83eb"
        />
        <path
          id="Path_100182"
          data-name="Path 100182"
          d="M51.449,15H12.834A3.833,3.833,0,0,0,9,18.834v21.05h1.464l40.985-23.42Z"
          transform="translate(-1.681 -1.896)"
          opacity={0.05}
        />
        <path
          id="Path_100183"
          data-name="Path 100183"
          d="M51.217,15.548H12.6a3.1,3.1,0,0,0-3.1,3.1V39.63h2.2L51.217,16.21Z"
          transform="translate(-1.449 -1.642)"
          opacity={0.07}
        />
        <path
          id="Path_100184"
          data-name="Path 100184"
          d="M14.857,35.991,57.515,19.036V50.21a2.369,2.369,0,0,1-2.37,2.37H14.857Z"
          transform="translate(1.035 -0.025)"
          fill="url(#linear-gradient-3)"
        />
        <path
          id="Path_100185"
          data-name="Path 100185"
          d="M10,19.036V50.21a2.369,2.369,0,0,0,2.37,2.37H57.4a2.351,2.351,0,0,0,1.521-.566Z"
          transform="translate(-1.217 -0.025)"
          fill="url(#linear-gradient-4)"
        />
        <path
          id="Path_100186"
          data-name="Path 100186"
          d="M10,18.476H59.768v2.042L39.244,36.751a7.009,7.009,0,0,1-8.721,0L10,20.518Z"
          transform="translate(-1.217 -0.284)"
          opacity={0.05}
        />
        <path
          id="Path_100187"
          data-name="Path 100187"
          d="M10,18.071H59.768V20.1L38.4,35.823a5.854,5.854,0,0,1-7.029,0L10,20.1Z"
          transform="translate(-1.217 -0.472)"
          opacity={0.07}
        />
        <path
          id="Path_100188"
          data-name="Path 100188"
          d="M12.37,16.048H57.4a2.369,2.369,0,0,1,2.37,2.37v2.005L37.552,35.558a4.736,4.736,0,0,1-5.337,0L10,20.422V18.416A2.369,2.369,0,0,1,12.37,16.048Z"
          transform="translate(-1.217 -1.41)"
          fill="#50e6ff"
        />
        <path
          id="Path_100189"
          data-name="Path 100189"
          d="M47.18,31.78A2.618,2.618,0,0,0,45.315,31a2.654,2.654,0,0,0-2.654,2.648v5.158H32.415A3.418,3.418,0,0,0,29,42.221v3.9a3.366,3.366,0,0,0,.138.906H48.587a2.37,2.37,0,0,0,2.37-2.37V35.555Z"
          transform="translate(7.594 5.524)"
          opacity={0.05}
        />
        <path
          id="Path_100190"
          data-name="Path 100190"
          d="M46.429,32.065a1.888,1.888,0,0,0-1.345-.565,1.921,1.921,0,0,0-1.922,1.916v5.89H32.184A2.686,2.686,0,0,0,29.5,41.989v3.9a2.662,2.662,0,0,0,.168.906H48.356a2.37,2.37,0,0,0,2.37-2.37V36.36Z"
          transform="translate(7.826 5.756)"
          opacity={0.07}
        />
        <path
          id="Path_100191"
          data-name="Path 100191"
          d="M43.661,33.184v6.622H31.951A1.952,1.952,0,0,0,30,41.758v3.9a1.952,1.952,0,0,0,1.951,1.951h11.71v6.622a1.182,1.182,0,0,0,2.019.836l9.689-9.689a2.365,2.365,0,0,0,0-3.345L45.68,32.349A1.182,1.182,0,0,0,43.661,33.184Z"
          transform="translate(8.058 5.987)"
          fill="url(#linear-gradient-5)"
        />
      </g>
    </g>
  </svg>
);
