import React from "react";
// import { getCoords } from "../../utils/common";
import "./index.css";
import { loadScript } from "./loadScriptFile";
import { TextFieldAdornment } from "./textFieldAdornment";
import { LocalStorageKeys } from "../../utils";
import { config } from "../../config";
import { getCoords } from "../../utils/common";
let autoComplete;

const SearchLocationInput = (props) => {
    const selectCode = JSON.parse(localStorage.getItem(LocalStorageKeys.selected_country_detail))
    const currentLocationLocal = JSON.parse(localStorage.getItem(LocalStorageKeys.current_location))

    const {
        handleChange,
        value,
        placeholder = "",
        parent_id,
        textFieldComponent,
        fieldStyle = {},
        label = ""
    } = props;
    const selectedCountry = selectCode?.code ?? localStorage.getItem(LocalStorageKeys?.selected_country)

    const [query, setQuery] = React.useState("");
    const autoCompleteRef = React.useRef(null);
    const loadRef = React.useRef(null);
    React.useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${config.googlemap_key}&libraries=places`,
            () => handleScriptLoad(autoCompleteRef)
        );
        // eslint-disable-next-line
    }, [autoCompleteRef?.current]);
    React.useEffect(() => {
        setQuery(value ? value : '')
        if (!value) {
            getCurrentLocation()
        }
        //eslint-disable-next-line 
    }, [value])
    React.useEffect(() => {
        if (autoCompleteRef?.current && loadRef?.current)
            handleScriptLoad(autoCompleteRef)
        //eslint-disable-next-line 
    }, [selectedCountry])

    const handleScriptLoad = (autoCompleteRef) => {
        loadRef.current = true
        let options = {};
        // console.log(selectedCountry, "//");
        if (selectedCountry !== "All") {
            options = {
                componentRestrictions: { country: selectedCountry },
            };
        }
        autoComplete = new window.google.maps.places.Autocomplete(
            autoCompleteRef.current, options
        );
        // REFER THIS FOR PLACES FIELDS
        // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
        autoComplete.setFields([
            "address_components",
            "formatted_address",
            "geometry"
        ]);
        autoComplete.addListener("place_changed", () => {

            const addressObject = autoComplete?.getPlace();

            if (addressObject) {
                let _obj = {};
                _obj["address"] = addressObject?.formatted_address;
                _obj["latitude"] = addressObject?.geometry?.location?.lat();
                _obj["longitude"] = addressObject?.geometry?.location?.lng();
                _obj["city"] = (addressObject?.address_components?.find(comp => comp?.types?.includes("locality"))?.long_name || addressObject?.address_components?.[1]?.long_name || "");
                _obj["location"] = addressObject?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_2") || comp?.types?.includes("administrative_area_level_1"))?.long_name || addressObject?.address_components?.[0]?.long_name || "";

                handleChange(_obj);
                setQuery(_obj.address);
            }

        }
        );
    }

    const getCurrentLocation = async () => {
        if (currentLocationLocal?.device?.length > 0 && currentLocationLocal?.device === "mobile") {
            handleChange(currentLocationLocal);
            setQuery(currentLocationLocal?.address)
        } else {
            const locationdata = await getCoords();
            if (locationdata?.latitude && locationdata?.longitude) {
                handleChange(locationdata);
                setQuery(locationdata?.address)
            }
        }
    }


    return (
        <div>
            {
                textFieldComponent ? textFieldComponent(
                    autoCompleteRef,
                    query,
                    setQuery
                ) : (
                    <TextFieldAdornment
                        id={`${parent_id}_location_search_textfield_input`}
                        inputRef={autoCompleteRef}
                        handleChange={(e) => setQuery(e.target.value)}
                        value={query}
                        // adornment={<LocationIcon />}
                        onClick={getCurrentLocation}
                        placeholder={placeholder}
                        fieldStyle={fieldStyle}
                        label={label}
                    />
                )
            }
        </div>
    );

}

export default SearchLocationInput;
