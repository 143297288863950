import * as React from "react";
export const Vacant = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    viewBox="0 0 25 25"
    {...props}
  >
    <g id="Group_112948" data-name="Group 112948" transform="translate(0.384)">
      <rect
        id="Rectangle_56050"
        data-name="Rectangle 56050"
        width={25}
        height={25}
        transform="translate(-0.384)"
        fill="none"
      />
      <g id="SQ_FT" data-name="SQ FT" transform="translate(3.197 2.432)">
        <path
          id="icons8-building_1_"
          data-name="icons8-building (1)"
          d="M11.2,4A2.209,2.209,0,0,0,9,6.2V19a1.009,1.009,0,0,0,1,1h3.2a1.009,1.009,0,0,0,1-1V17.4a.191.191,0,0,1,.2-.2h1.2a.191.191,0,0,1,.2.2V19a1.009,1.009,0,0,0,1,1H20a1.009,1.009,0,0,0,1-1V6.2A2.209,2.209,0,0,0,18.8,4Zm0,1.2h7.6a.991.991,0,0,1,1,1V18.8H17V17.4A1.409,1.409,0,0,0,15.6,16H14.4A1.409,1.409,0,0,0,13,17.4v1.4H10.2V6.2A.991.991,0,0,1,11.2,5.2ZM12,6.4a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2H18a.6.6,0,1,0,0-1.2ZM12,8.8A.6.6,0,1,0,12,10h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2H18a.6.6,0,1,0,0-1.2ZM12,11.2a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2H18a.6.6,0,1,0,0-1.2ZM12,13.6a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2h.4a.6.6,0,1,0,0-1.2Zm2.8,0a.6.6,0,1,0,0,1.2H18a.6.6,0,1,0,0-1.2Z"
          transform="translate(-5.581 -1.433)"
          fill="#4e5a6b"
        />
      </g>
    </g>
  </svg>
);
