import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Divider, Grid, Hidden, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import { withTranslation } from 'react-i18next';
import { AmenetiesGotoIcon, FooterIcon, ListingsLogo, Logo } from "../../assets";
import { config } from "../../config";
import { AuthContext } from "../../contexts";
import { LocalStorageKeys } from "../../utils";
import { CustomButton } from "../Button";
import { footerStyle } from "./style";

const Footer = ({
  onScroll = () => false,
  noTop = false,
  t = () => false,
  footerLinks = {},
  assetsAndLinks = {}
}) => {
  const auth = React.useContext(AuthContext)
  let hostName = window.location.origin;
  let host_arr = [config?.listings_url, config?.realitygoto_url, config?.amenitiesgoto_url];
  hostName = host_arr?.includes(hostName) ? hostName : config?.listings_url;
  const amenitiesUrl = config.amenitiesgoto_url
  const publicListingsUrl = config.listings_url
  const realityUrl = config.realitygoto_url
  const client = localStorage.getItem(LocalStorageKeys.client)

  const onRedirect = () => {
    window.location.pathname = auth?.clientRoutes?.listing

  }
  const onLink = (key) => {
    if (key === "home" && footerLinks?.home) {
      window.open(footerLinks?.home)
    }
    if (key === "about" && footerLinks?.about) {
      window.open(footerLinks?.about)
    }
    if (key === "contact" && footerLinks?.contact) {
      window.open(footerLinks?.contact)
    }
    if (key === "terms_conditions" && footerLinks?.terms_conditions) {
      window.open(footerLinks?.terms_conditions)
    }
    if (key === "privacy_policy" && footerLinks?.privacy_policy) {
      window.open(footerLinks?.privacy_policy)
    }
  }
  const logoConfiguration = {
    [publicListingsUrl]: <ListingsLogo onClick={onRedirect} />,
    // ["http://localhost:3001"]: <ListingsLogo onClick={onRedirect} />,
    [realityUrl]: <Logo onClick={onRedirect} />,
    [amenitiesUrl]: <AmenetiesGotoIcon onClick={onRedirect} />
  }
  const logoConfigurationMobile = {
    [publicListingsUrl]: <ListingsLogo onClick={onRedirect} height={'40px'} />,
    // ["http://localhost:3001"]: <ListingsLogo onClick={onRedirect} height={'40px'} />,
    [realityUrl]: <Logo onClick={onRedirect} height={'40px'} />,
    [amenitiesUrl]: <AmenetiesGotoIcon onClick={onRedirect} height={'40px'} />
  }
  return (
    <Box>
      <Hidden smDown>
        <Box
          sx={footerStyle.main}
        >
          <Grid container sx={footerStyle.container}>
            <Grid item sx={footerStyle.firstContainer}>
              <Box sx={footerStyle.image}>
                {((client?.length && client) || !(host_arr.includes(window.location.origin))) ? (assetsAndLinks?.client_logo?.url ? <img
                  onClick={onRedirect}
                  src={assetsAndLinks?.client_logo?.url}
                  alt="logo"
                  style={{
                    width: 138,
                    height: 40,
                  }} /> : "") : (logoConfiguration[hostName])}</Box>
              <Box sx={footerStyle.btnBox}>
                <CustomButton rootStyle={footerStyle.btn} label={t("Home")} onClick={() => onLink("home")} />
                <CustomButton rootStyle={footerStyle.btn} label={t("About Us")} onClick={() => onLink("about")} />
                <CustomButton rootStyle={footerStyle.btn} label={t("Contact Us")} onClick={() => onLink("contact")} />
              </Box>
            </Grid>
            {!noTop && <Grid item sx={footerStyle.secondContainerTop}>
              <Typography>{t("Top")}</Typography>&nbsp;&nbsp;
              <IconButton size="small" onClick={onScroll} sx={footerStyle.UpArrow}>
                <KeyboardArrowUpIcon sx={{ color: "#091B29" }} /></IconButton>
            </Grid>}

          </Grid>
          <Divider sx={footerStyle.divider} />
          <Grid container sx={footerStyle.secondContainer}>
            <Grid item sx={footerStyle.termsCont}>
              <Typography sx={footerStyle.terms} onClick={() => onLink("terms_conditions")}>{t("Terms of Service")}</Typography>
              {"|"}
              <Typography sx={footerStyle.terms} onClick={() => onLink("privacy_policy")}>{t("Privacy Policy")}</Typography>
            </Grid>
            <Grid item>
              <Typography sx={footerStyle.copyright}>{t("Copyright © 2020–2033")} <Link sx={footerStyle.copyright} target="_blank" href="listingsgoto.com">{t("ListingsGoto.com™")}</Link> {t("All rights reserved")}.</Typography>
            </Grid>
            <Grid item>
              <Box sx={footerStyle.secondImage} >
                {/* <img src="images/logo2.png" alt="" style={footerStyle.footImage} /> */}
                <FooterIcon />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Divider sx={footerStyle.div} />
        <Box sx={footerStyle.mainBox}>
          <Box sx={footerStyle.logobox}>
            <Box sx={footerStyle.image}>
              {((client?.length && client) || !(host_arr.includes(window.location.origin))) ? (assetsAndLinks?.client_logo?.url ? <img
                onClick={onRedirect}
                src={assetsAndLinks?.client_logo?.url}
                alt="logo"
                style={{
                  width: 138,
                  height: 40,
                }} /> : "") : logoConfiguration[hostName]}</Box>
            {!noTop && <Grid item sx={footerStyle.secondContainerTop}>
              <Typography sx={footerStyle.tp}>{t("Top")}</Typography>&nbsp;&nbsp;
              <IconButton size="small" onClick={onScroll} sx={footerStyle.UpArrow}>
                <KeyboardArrowUpIcon sx={{ color: "#091B29" }} />          </IconButton>
            </Grid>}
          </Box>
          <Grid container >
            <Grid item xs={7}>
              <Box>
                <CustomButton rootStyle={footerStyle.bt1} label={t("Home")} onClick={() => onLink("home")} />
                <CustomButton rootStyle={footerStyle.bt2} label={t("About Us")} onClick={() => onLink("about")} />
                <CustomButton rootStyle={footerStyle.bt3} label={t("Contact Us")} onClick={() => onLink("contact")} />
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Typography sx={footerStyle.terms1} onClick={() => onLink("terms_conditions")}>{t("Terms of Service")}</Typography>
              <Typography sx={footerStyle.terms2} onClick={() => onLink("privacy_policy")}>{t("Privacy Policy")}</Typography>
            </Grid>
          </Grid>
          <Box sx={footerStyle.ft}>
            <Box sx={footerStyle.cpy}>
              <Typography sx={footerStyle.copyright} align={"center"}>{t("Copyright © 2020–2033")} <Link sx={footerStyle.copyright} target="_blank" href="listingsgoto.com">{t("ListingsGoto.com™")}</Link> {t("All rights reserved")}.</Typography>
            </Box>
            <Box sx={footerStyle.logoImg} >
              <Typography sx={footerStyle.copyright}>{t("Powered by")} <span style={footerStyle.prop}>{t("Listings")}</span><span style={footerStyle.goto}>{t("GOTO")}</span ><span style={footerStyle.prop}>.</span></Typography>
            </Box>
            <br />
          </Box>
        </Box>
      </Hidden>
    </Box>

  )
}
export default withTranslation('footer')(Footer);