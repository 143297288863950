import React from "react";
import ReactMapGL, {
  Marker,
  Popup,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { Box } from "@mui/material";
import { MapBoxStyle } from "./style";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import PopupCard from "./popupCard";
import moment from "moment";
import "./map.css";
import { config } from "../../config";

export const MapBox = ({ latitude = "", longitude = "", data, original = false, unit = false, onView = false, variant = "" }) => {
  const [popupInfo, setPopupInfo] = React.useState(null);
  const [popData, setPopupData] = React.useState(null);
  const [viewState, setViewState] = React.useState({
    longitude: "",
    latitude: "",
    zoom: 13,
    height: 400,
  });
  React.useEffect(() => {
    setViewState({ ...viewState, latitude: latitude, longitude: longitude });
    const obj = {};
    obj.id = data?.id;
    obj.header = data?.company_name ?? "";
    obj.subheader = data?.name ?? "";
    obj.buildDate = moment(data?.year_built).format("YYYY");
    obj.location = `${data?.district ?? ""},${data?.state ?? ""}`;
    obj.sqft = `${data?.total_area ?? ""} ${data?.area_metric ?? ""}`;
    obj.onebhk = data?.["oneBHK_count"];
    obj.twobhk = data?.["twopluseBHK_count"];
    obj.payment_period = data?.payment_period ?? "";
    obj.startfrom_price = data?.startfrom_price ?? "";
    obj.currency_type = data?.currency_type ?? "";
    obj.unitsAvailable = data?.unit_count ?? "";
    obj.updatedDate = moment(data?.updated_at).format("D MMM YYYY");
    obj.rate = data?.rate ?? "";
    obj.offer = data?.offer ?? "";
    obj.type = data?.type ?? "";
    obj.latitude = parseFloat(data?.latitude);
    obj.longitude = parseFloat(data?.longitude);
    obj.logo = data?.logo;
    setPopupData(obj)
    //eslint-disable-next-line
  }, [data]);
  return (
    <Box style={MapBoxStyle.main}>
      <ReactMapGL
        {...viewState}
        style={MapBoxStyle.mainMap}
        mapboxAccessToken={config.mapbox_key}
        onMove={(evt) => setViewState(evt.viewState)}
        mapStyle={config.mapbox_style}
        onZoom={(e) => {
          console.log(e.viewState.zoom);
        }}
      >
        <Marker
          key={data?.id}
          longitude={longitude}
          latitude={latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo(true);
          }}
        >
          <FmdGoodIcon sx={MapBoxStyle.pin} />
        </Marker>
        {popupInfo && (
          <Popup
            anchor="top"
            longitude={longitude}
            latitude={latitude}
            onClose={() => setPopupInfo(null)}
            className="popupSingle"
            maxWidth="100%"
            closeButton={false}
          >
            <PopupCard popupInfo={original ? data?.data : popData} unit={unit} onViewButton={onView} variant={variant} />
          </Popup>
        )}
        <NavigationControl position="top-right" showCompass={false} />
      </ReactMapGL>
    </Box>
  );
};
