import { config } from "../config";

export const getCoords = async () => {
  const pos = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  const lat = pos.coords.latitude;
  const long = pos.coords.longitude;

  const result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${config.googlemap_key}`)
    .then(data => data.json())
    .then(({ results }) => {
      if (Array.isArray(results)) {
        const [info] = results;
        let city = info?.address_components?.find(comp => comp?.types?.includes("locality"));
        let location = info?.address_components?.find(comp => comp?.types?.includes("administrative_area_level_1"));
        let code = info?.address_components?.find(comp => comp?.types?.includes("country"));
        let _obj = {};
        _obj["address"] = `${city?.long_name},${location?.long_name},${code?.long_name}`;
        _obj["latitude"] = lat;
        _obj["longitude"] = long;
        _obj["city"] = city?.long_name;
        _obj["location"] = location?.long_name;
        _obj["code"] = code?.short_name;
        _obj["country_name"] = code?.long_name;
        return (_obj)

      }
    }).catch((err) => {
      console.log(err);
      return null
    });

  return result
};


/**
 * Generate Image URL
 * @param {String} image_url - S3 Image URL
 * @param {int} width - Required width
 * @param {int} height - Required height
 * @returns String - Converted image size URL
 */
export const generateImageUrl = (image_url, { width, height }) => {
  let image_url_split = image_url?.split('/')
  if (Boolean(image_url_split?.[image_url_split?.length - 2]?.includes("X"))) {
    image_url_split[image_url_split?.length - 2] = `${width}X${height}`
    return image_url_split?.join('/')
  } else {
    return image_url
  }
}

export const img_size = {
  small_square: { width: 150, height: 150, },
  small_rectangle: { width: 192, height: 108, },
  medium_square: { width: 500, height: 500, },
  medium_rectange: { width: 896, height: 504, },
  large_square: { width: 1000, height: 1000, },
  large_rectangle: { width: 1792, height: 1008, },
}


export const getTimeWithAmPm = (timeString) => {

  const [hour] = timeString?.split(":")
  if (hour === "00") {
    return `12:00 AM`
  }
  else if (hour === "24") {
    return `12:00 PM`
  }
  else {
    if (hour >= 12) {
      if (hour % 12 === 0) return `12:00 PM`
      else return `${hour % 12}:00 PM`.padStart(8, '0')
    } else return `${hour}:00 AM`
  }
}

export const minArea = [{
  value: 0,
  label: 0
}, {
  value: 800,
  label: 800
}, {
  value: 1000,
  label: 1000
}, {
  value: 1500,
  label: 1500
}, {
  value: 2000,
  label: 2000
},
{
  value: 2500,
  label: 2500
}, {
  value: 3000,
  label: 3000
}, {
  value: 4000,
  label: 4000
}, {
  value: 5000,
  label: 5000
},
{
  value: 6000,
  label: 6000
}, {
  value: 7000,
  label: 7000
}, {
  value: 8000,
  label: 8000
}, {
  value: 9000,
  label: 9000
}, {
  value: 10000,
  label: 10000
},
]
export const maxArea = [{
  value: "Any",
  label: "Any"
}, {
  value: 800,
  label: 800
}, {
  value: 1000,
  label: 1000
}, {
  value: 1500,
  label: 1500
}, {
  value: 2000,
  label: 2000
},
{
  value: 2500,
  label: 2500
}, {
  value: 3000,
  label: 3000
}, {
  value: 4000,
  label: 4000
}, {
  value: 5000,
  label: 5000
},
{
  value: 6000,
  label: 6000
}, {
  value: 7000,
  label: 7000
}, {
  value: 8000,
  label: 8000
}, {
  value: 9000,
  label: 9000
}, {
  value: 10000,
  label: 10000
}
]