import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const lstingSearchStyle = {
    main: {
        borderRadius: borderRadius,
        height: {
            sm: 'auto',
            lg: "auto"
        },
        border: "1px solid #CED3DD",
        mt: 2,
        cursor: "pointer",
        // boxShadow: "0px 0px 32px #0000000f"

    },
    rightArrow: {
        mt: '10px',
        mr: '-10px'
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: borderRadius,
        cursor: 'pointer'
    },
    relative: {
        position: 'relative',
        height: {
            sm: "200px",
            md: "200px",
            lg: "301px"
        }
    },
    inherit: {
        height: 'inherit'
    },
    alignEnd: {
        textAlign: 'end'
    },
    subheading: {
        fontFamily: Regular,
        color: 'text.secondary',
        fontSize: "0.875rem",
        // pb: {
        //     sm: "10px",
        //     lg: "0px"
        // }
    },
    subheadingCustom: {
        fontFamily: Regular,
        color: 'text.secondary',
        fontSize: "0.75rem",
        mt: {
            sm: "4px",
            lg: "0px"
        }
    },
    subHeading1: {
        fontFamily: Regular,
        color: 'text.secondary',
        fontSize: "0.875rem"
    },
    subHeading2: {
        fontFamily: Regular,
        color: 'text.secondary',
        fontSize: "0.75rem",
        marginTop: '-2px'
    },
    pl: {
        paddingInlineStart: '20px'
    },
    subHeading3: {
        fontFamily: SemiBold,
        color: 'text.secondary',
        fontSize: "0.875rem",
        // marginTop:'-7px'
    },
    underline: {
        fontFamily: Medium,
        fontSize: "0.75rem",
        textDecoration: 'underline',
        color: 'primary.main',
        marginInlineStart: "16px"
    },
    viewonMap: {
        display: 'flex', gap: 2, alignItems: 'center'
    },
    mapIcons: {
        display: 'flex', alignItems: 'center', gap: 0.75
    },
    pdetails2: {
        display: 'flex', alignItems: 'center', gap: 0.5, padding: '10px', mt: "5px"
    },
    pdetails3: {
        display: 'flex', alignItems: 'center', gap: 0.5
    },
    pdetails: {
        height: '40px',
        backgroundColor: '#F2F4F7',
        borderRadius: borderRadius,
        display: 'flex',
        padding: "8px",
        width: {
            xl: "fit-content"
        }
    },
    firstHalf: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px'
    },
    firstHeading: {
        fontFamily: Medium,
        fontSize: "1rem"
    },
    chip: {
        position: "absolute",
        top: "18px",
        left: "17px",
        backgroundColor: "hsla(170, 61%, 48%, 1)",
        color: "#fff",
        fontSize: "1rem",
        zIndex: 1,
        fontFamily: SemiBold
    },
    chipArabic: {
        position: "absolute",
        top: "18px",
        right: "17px",
        backgroundColor: "hsla(170, 61%, 48%, 1)",
        color: "#fff",
        fontSize: "1rem",
        zIndex: 1,
        fontFamily: SemiBold
    },
    chip2: {
        backgroundColor: "#091B29",
        color: "#fff",
        fontSize: "0.75rem",
        zIndex: 1,
        fontFamily: Regular,
        height: "24px",
        marginRight: "6px"
    },

    companyIcon: {
        width: '77px', height: '46px', border: '1px solid #E4E8EE',
        borderRadius: borderRadius,
    },
    normalBlack: {
        fontSize: "0.75rem",
        fontFamily: Medium,
        color: '#091B29',
        marginInlineStart: "4px"
    },
    normalBlackWithRegular: {
        fontSize: "0.875rem",
        fontFamily: Regular,
        color: '#091B29'
    },
    span: {
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        color: '#98A0AC',
    },
    red: {
        fontSize: "0.75rem",
        color: 'error.light'
    },
    secondHalf: {
        display: 'flex', p: "12px", flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end',
        backgroundColor: "#F7F7F7",
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
    },
    imageViewer: {
        padding: 2
    },
    viewButton: {
        marginRight: "4px"
    },
    imageViewBox: {
        height: '332px', borderRadius: borderRadius
    },
    imageViewImg: {
        width: '100%', height: '100%', borderRadius: borderRadius
    },
    imageViewSmall: {
        padding: 1, display: 'flex', gap: '12px', flexWrap: 'nowrap', width: 'fit-content'
    },
    imageViewSmallBox: {
        borderRadius: borderRadius, height: '64px', width: '64px', overflow: 'hidden'
    },
    imageViewSmallBoxImg: {
        width: '100%', height: '100%', borderRadius: borderRadius, objectFit: 'cover'
    },
    noIg: {
        display: "flex",
        justifyContent: "center",
        mt: "100px"
    },
    displayFlex: {
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        marginTop: "16px"

    },
    floatRight: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        textAlign: "end",
        flexGrow: 1
    },
    logo: {
        borderRadius: borderRadius,
        border: "1px solid #CED3DD",
        height: "40px",
        width: "60px",
        mr: "8px"
    },
    stackbox: {
        display: "flex",
        alignItems: "center",
        spacing: 1,
        mt: "10px"
    },
    img: {
        height: '40px',
        width: "60px",
        borderRadius: borderRadius,
        objectFit: "fill"
    },
    btn: {
        height: "41px",
        width: "114px",
        p: 0,
        mr: {
            sm: "-10px",
            md: "0px",
            lg: "0px",
            xl: "0px"
        }
    }
}