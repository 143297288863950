import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const VacantCardStyle = {
  maincard: {
    borderRadius: borderRadius,
    backgroundColor: "text.default",
    display: "flex",
    position: "relative"
  },
  image: {
    height: "110px",
    width: "170px",
    objectFit: "contain",
    borderRadius: borderRadius,
    cursor: "pointer"
  },
  maintext: {
    padding: "12px",
  },
  textheader: {
    color: "text.primary",
    fontSize: "1rem",
    fontFamily: Medium,

  },
  doticon: {
    color: "#4E5A6B",
    height: "6px",
    width: "6px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  units: {
    display: "flex",
    alignItems: "center",
    padding: "4px 12px 12px 0px"

  },
  unittext: {
    fontSize: "0.75rem !important",
    fontFamily: SemiBold,
    color: "#98A0AC",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",

  },
  textcontent: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "110px",
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none'
    },
    '-ms-overflow-style': 'none',  /* IE and Edge */
    scrollbarWidth: 'none'
  },
  chipdata: {
    position: "absolute",
    backgroundColor: "hsla(170, 61%, 48%, 1)",
    color: "primary.contrastText",
    fontSize: "0.75rem",
    fontFamily: SemiBold,
    height: "24px",
    width: "75px",
    left: 0,
    marginTop: "10px",
    marginLeft: "10px",
    "& .MuiChip-label": {
      padding: "10px"
    },
    zIndex: 2

  },
  icons: {
    display: "flex",
    alignItems: "center"
  },
  month: {
    fontFamily: Regular,
    fontSize: "1rem !important",
    whiteSpace: "noWrap",
  },
  rupeestext: {
    fontSize: "1rem !important",
    whiteSpace: "noWrap",
    fontFamily: Medium,
    color: "#091B29"
  },
  bedicon: {
    height: "20px",
    width: "20px"
  },
  doticongrey: {
    color: "#CED3DD",
    height: "6px",
    width: "6px",
    marginLeft: "4px",
    marginRight: "4px",
  },
  icontext: {
    color: "#98A0AC",
    fontSize: "0.875rem !important",
    fontFamily: Regular,
    marginLeft: "4px",
    marginRight: "4px",
    marginTop: "2px"
  },
  availabletext: {
    color: "#5AC782",
    fontSize: {
      sm: '1.2rem',
      lg: '1.6rem'
    },
    fontFamily: Medium,
    padding: " 8px 8px 8px 0px"
  },
  Amount: {
    display: "flex",
    columnGap: "4px",
    alignItems: "center"

  },
  mainbutton: {
    height: "40px",
    borderRadius: "20px",
    width: "114px",
    color: "#4E5A6B",
    fontFamily: Medium,
    fontSize: "0.875rem",
    border: "1px solid #E4E8EE",
  },
  selectedbutton: {
    height: "40px",
    borderRadius: "20px",
    width: "114px",
    color: "#fff",
    fontFamily: Medium,
    fontSize: "0.875rem",
    border: "1px solid #E4E8EE",
  },
  subbutton: {
    height: "40px",
    borderRadius: "20px ",
    width: "116px",
    color: "#1F74EA",
    fontFamily: Medium,
    fontSize: "0.875rem",
    padding: "4px",
    marginTop: "8px",
    border: "none",

    "&:hover": {
      border: "none",
      backgroundColor: "#fff",
    },
  },
  buttonbox: {
    padding: "12px"
  },
  Amountbox: {

    display: "grid",
    alignItems: "center",
    padding: "21px"
  },
  whiteTick: {
    mt: 1, mr: -1
  },
  parentBox: {
    display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%",
  }
}
