import { borderRadius, remCalc } from "../../utils";
import { Medium, SemiBold } from "../../utils/font";

export const CompanyCardStyle = {
  topbox: (borderHide, borderBottom) => {
    return ({
      display: "flex",
      // height: "112px",
      backgroundColor: "primary.contrastText",
      padding: "4px 2px 4px 2px",
      border: !borderHide && "1px solid #E4E8EE",
      borderRadius: borderRadius,
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: borderBottom && "1px solid #E4E8EE",

    })
  },
  topbox2: {
    display: "flex",
    // height: "112px",
    backgroundColor: "primary.contrastText",
    padding: "4px 2px 4px 2px",
    border: "1px solid #0071F2",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "space-between",


  },
  image: {
    height: "36px",
    width: "36px",
  },
  boximage: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
  },
  text: {
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  maintext: {
    fontFamily: SemiBold,
    fontSize: remCalc(12),
    color: "text.primary",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  subtext: {
    fontSize: remCalc(12),
    fontFamily: Medium,
    color: "#4E5A6B",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: "95%"
    // // '@media screen and (min-width: 320px)': {
    // //   width: "90%"
    // // },
    // // '@media screen and (min-width: 375px)': {
    // //   width: "100%"
    // // },
    // // '@media screen and (min-width: 425px)': {
    // //   width: "100%"
    // // },
    // width: { xl: "100%", lg: "100%", md: "75%", sm: "100%", xs: "100%" }
  },
};
