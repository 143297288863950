import { Card, CardContent, Divider, Grid, Hidden, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { withTranslation } from "react-i18next";
import { CalenderScroller } from "../calenderScoller";
import { SlotsCard } from "../slotsCard";
import { slotSelectorStyle } from "./style";
import moment from 'moment';
import { getTimeWithAmPm } from "../../utils/common";

const SlotSelector = ({
  isSelected = '',
  handleCalenderSelect = () => 'false',
  handleSLotSelection = () => 'false',
  slotSelected = '',
  t = () => false,
  calendarData = [],
  slotsData = [],
  data = {},
  selectedIndex = []


}) => {
  const [selectedDate, setSelectedDate] = React.useState(new Date())



  return (
    <Card sx={slotSelectorStyle.main}>
      <CardContent sx={slotSelectorStyle.ac}>
        {data?.viewFacilityBookingAmenity?.period !== "Daily" &&
          <>
            <CalenderScroller>
              {calendarData?.map((val, index) => (
                <Box
                  sx={moment(val).format("DD-MM-YYYY") === moment(selectedDate).format("DD-MM-YYYY") ? slotSelectorStyle.calenderSelected : slotSelectorStyle.calendar}
                  onClick={() => {
                    handleCalenderSelect(index, val)
                    setSelectedDate(val)
                  }}
                >
                  <Typography sx={moment(val).format("DD-MM-YYYY") === moment(selectedDate).format("DD-MM-YYYY") ? slotSelectorStyle.monthSelected : slotSelectorStyle.month}>{moment(val).format("ddd")}</Typography>
                  <Typography sx={moment(val).format("DD-MM-YYYY") === moment(selectedDate).format("DD-MM-YYYY") ? slotSelectorStyle.dateSelected : slotSelectorStyle.date}>{moment(val).format("DD")}</Typography>
                  {/* <Box sx={{ backgroundColor: val?.isactive === 'true' ? 'green' : val.isactive === 'false' ? 'red' : 'orange', width: '5px', height: '5px', borderRadius: '50px' }}></Box> */}

                </Box>
              ))}
            </CalenderScroller>
            <Hidden smDown>
              <Divider />
            </Hidden>
          </>
        }
        <Box sx={data?.viewFacilityBookingAmenity?.period === "Hourly" ? slotSelectorStyle.bottomSlots : slotSelectorStyle.bottomSlotsDaily}>
          {/* <Hidden smDown> */}
          <Typography sx={slotSelectorStyle.fontSmall}>{t("AVAILABLE SLOTS")}</Typography>
          {/* </Hidden> */}
          <Grid container sx={slotSelectorStyle.slotCard}>
            <Grid item xs={12} md={12} sm={12} lg={12} >
              <Grid container spacing={1.5} mt={"0px"}>
                {slotsData?.length > 0 ?
                  <>
                    {slotsData?.map((val, index) => (
                      
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <SlotsCard
                          time={`${data?.viewFacilityBookingAmenity?.period === "Hourly" ? `${getTimeWithAmPm(val?.check_in_time)} - ${getTimeWithAmPm(val?.check_out_time)}` : `${moment(val?.check_in_date?.date).format("DD MMM YY")}`}`}
                          hour={val?.hour}
                          slot={val?.participants_count}
                          conform={val?.conform}
                          selected={slotSelected}
                          selection={handleSLotSelection}
                          peakHour={val?.slot_detail?.is_peak}
                          val={val}
                          index={index}
                          booked={val?.slot_detail?.is_booked}
                          data={data}
                          selectedIndex={selectedIndex}
                          t={t}
                        />
                      </Grid>
                    ))}
                  </>
                  :
                  <Grid item xs={12}>
                    <Box sx={slotSelectorStyle?.noDataBox}>
                      <Typography sx={slotSelectorStyle.noData}>
                        {t("No slots available for Booking")}
                      </Typography>
                    </Box>
                  </Grid>
                }

              </Grid>

            </Grid>
          </Grid>

        </Box>
      </CardContent>
    </Card>
  );
};

export default withTranslation()(SlotSelector)