import { borderRadius } from "../../utils";
import { Medium, SemiBold } from "../../utils/font";

export const CreateNewAccountStyle = {
    mainBox: {
        mt: "-25px",
        padding: "3px 14px 19px 8px",
        backgroundColor: "text.default",
        borderRadius: borderRadius,
    },
    mainBoxpadding: {
        mt: "-25px",
        // padding: "3px 14px 19px 8px",
        backgroundColor: "text.default",
        borderRadius: borderRadius,
    },
    headerText: {
        fontFamily: Medium,
        fontSize: '1rem', color: 'text.secondary'

    },
    inputBarSx: {
        padding: "0px 20px 0px 20px",
        mt: "24px",
        "& .MuiTypography-root": {
            color: "text.tertiary",
            fontFamily: SemiBold,
            fontSize: "0.75rem",
        }
    },
    email: {
        "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
            height: "40px",
            borderColor: "border.default",


            "&.Mui-focused fieldset": {
                border: "1px solid #CED3DD"
            },
            '&:hover fieldset': {
                border: "1px solid #CED3DD"
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: "4px",

            },
        },
        "& .MuiTypography-root": {
            color: "text.tertiary",
            fontFamily: SemiBold,
            fontSize: "0.75rem",
        },

    },

    button: {
        mt: "70px",
        ml: "14px",
        backgroundColor: "#1F74EA",
        height: "48px",
        borderRadius: "26px",
        width: "93%",
        color: "text.default",
        fontFamily: Medium,

        fontSize: "0.875rem",
        border: "none",
        "&:hover": {
            border: "none",
            color: "text.default",
            backgroundColor: "#1F74EA",
        },
    },

    headerBox: {
        display: "flex",
        justifyContent: "space-between"
    },
    iconBox: {
        backgroundColor: "#dbe2e2",
        borderRadius: "50%"
    },
    icon: {
        margin: "5px 4px -4px 10px"


    }

}
