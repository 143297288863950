import {
  Grid,
  Typography,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
  Rating,
  Avatar
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { staysSearchStyle } from "./style";
import {
  RightArrow,
  GreenTick,
  Spoon,
  BedroomIcon
} from "../../assets";
import { CustomButton } from "../Button";
import { DialogBox } from "../dialogBox";
import { withTranslation } from "react-i18next";

const StaysDetailCard = ({
  header = "",
  subheader = "",
  updatedDate = "",
  rate = "",
  offer = "",
  type = "",
  // src = "/images/NoPath - Copy (10)@2x.jpg",
  src = "",
  handleClick = () => false,
  t=()=> false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [alignment, setAlignment] = React.useState("");
  const [image, setImage] = React.useState("");

  // Handle Image Toggle
  const handleToggleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // handle Image Click
  const handleImageClick = (src) => {
    setOpen(true);
    setImage(src);
  };
  // Handle Image Click
  const handleModalClose = () => {
    setOpen(false);
  };

  const ImageToggle = () => (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleToggleChange}
      aria-label="Platform"
    >
      <ToggleButton value="Units Images">{t("Units Images")}</ToggleButton>
      <ToggleButton value="Amenities">{t("Amenities")}</ToggleButton>
      <ToggleButton value="Floor Plan">{t("Floor Plan")}</ToggleButton>
      <ToggleButton value="Video">{t("Video")}</ToggleButton>
      <ToggleButton value="VR & 360 view">{t("VR & 360 view")}</ToggleButton>
      <ToggleButton value="Documents">{t("Documents")}</ToggleButton>
    </ToggleButtonGroup>
  );
  const ImageViewer = () => (
    <Box sx={staysSearchStyle.imageViewer}>
      <Box sx={staysSearchStyle.imageViewBox}>
        <Box as="img" src={image} sx={staysSearchStyle.imageViewImg} />
      </Box>
      <Box container sx={staysSearchStyle.imageViewSmall}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((val) => (
          <Box sx={staysSearchStyle.imageViewSmallBox}>
            <Box
              as="img"
              src={image}
              sx={staysSearchStyle.imageViewSmallBoxImg}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
  return (
    <Box sx={staysSearchStyle.main}>
      {/* Main Card */}
      <Grid container sx={staysSearchStyle.inherit}>
        {/* Left Segment */}
        <Grid item xs={12} sm={12} lg={3} sx={staysSearchStyle.relative}>
          {
            src ?
              <img src={src} alt="img" style={staysSearchStyle.height} onClick={() => handleImageClick(src)} />
              :
              <Box onClick={() => handleImageClick(src)} sx={staysSearchStyle.avBox}>
                <Box sx={staysSearchStyle.avatar} >
                  <Avatar />
                </Box>
                <Box sx={staysSearchStyle.avatarBox}>
                  <Typography >{t("No Data Found")}</Typography>
                </Box>
              </Box>
          }
          <Chip label={type} sx={staysSearchStyle.chip} />
        </Grid>
        {/* Right Segment */}
        <Grid
          item
          container
          xs={12}
          sm={12}
          lg={9}
          sx={staysSearchStyle.pl}
          spacing={2}
        >
          {/* Right 1st Segment */}
          <Grid item xs={8} sx={staysSearchStyle.firstHalf}>
            {/* 1 */}
            <Typography sx={staysSearchStyle.firstHeading}>
              {header}
            </Typography>
            <Typography sx={staysSearchStyle.viewonmap}>
              {subheader}  <Typography sx={staysSearchStyle.underline}>
                {t("View on Map")}
              </Typography>
            </Typography>
            <Box sx={staysSearchStyle.viewonmap}>
              <Rating name="read-only" value={4} readOnly size="small"/>
              <Typography sx={staysSearchStyle.normalSmall}>{'(4.5 rating | 220 reviews)'}</Typography>
            </Box>
            {/* 4 */}
            <Box sx={staysSearchStyle.pdetails3}>
              {" "}
              <GreenTick  style={{marginBottom:"8px"}}/>
              <Typography sx={staysSearchStyle.subHeading3}>
                Free Cancelation
              </Typography>
              <Spoon style={{marginBottom:"8px"}} />
              <Typography sx={staysSearchStyle.subHeading3}>
                Complimentry Breakfast
              </Typography>
            </Box>

            <Box sx={staysSearchStyle.bedroom}>
              <BedroomIcon rootStyle={staysSearchStyle.bedroomIcon} />
              <Typography sx={staysSearchStyle.bedroomIconText}>12 bedroom available</Typography>
            </Box>
          </Grid>
          {/* Right 2nd Segment */}
          <Grid item xs={4} sx={staysSearchStyle.secondHalf}>
            <Box sx={staysSearchStyle.alignEnd}>
              <Typography sx={staysSearchStyle.normalBlack}>
                Stays From
              </Typography>
              <Typography sx={staysSearchStyle.rate}>
                {rate}
                <Box as="span" sx={staysSearchStyle.subheading}>
                  {" "}
                  / Month
                </Box>
              </Typography>
              {offer && (
                <Typography sx={staysSearchStyle.red}>
                  Upto {offer} offer
                </Typography>
              )}
            </Box>
            <Box sx={staysSearchStyle.viewButton}>
              <CustomButton
                label={t("Check Stays")}
                variant="outlined"
                color="primary"
                onClick={handleClick}
                endIcon={
                  <RightArrow rootStyle={staysSearchStyle.rightArrow} />
                }
                rootStyle={staysSearchStyle.checkBtn}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* Image View Dialog */}
      <DialogBox
        open={open}
        handleClose={handleModalClose}
        maxWidth="md"
        header={<ImageToggle />}
        component={<ImageViewer />}
      />
    </Box>
  );
};

export default withTranslation("staysDetailCard")(StaysDetailCard)