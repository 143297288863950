import { borderRadius, remCalc } from "../../../utils";
import { Medium, Regular, SemiBold } from "../../../utils/font";

export const topBarStyles = {
    appBarSx: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        color: 'text.primary',
        opacity: 1,
        position: "fixed",
        boxShadow: '0px 0px 32px #0000000F',

    },
    app_bar_grid: {
        position: "relative"
    },
    titleContainerSx: {
        marginLeft: 2
    },
    spacebt: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: "inherit",
        paddingInlineStart: {
            sm: "0px",
            md: '70px',
            lg: "0px",
            xl: "0px"
        },
        paddingInlineEnd: {
            sm: "0px",
            md: '70px',
            lg: "0px",
            xl: "0px"
        }
    },
    spacebtItem: {
        display: 'flex',
        justifyContent: 'end'
    },
    toolbarSx: {
        display: "block !important",
        minHeight: {
            lg: "66px !important",
            sm: "55px !important"
        },
        boxShadow: '0px 0px 32px #0000000F',
        paddingLeft: {
            lg: '80px !important',
            sm: '15px !important'
        },
        paddingRight: {
            lg: '80px !important',
            sm: '15px !important'
        },
        width: "inherit"
    },
    titleSx: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: 1.7
    },
    signinSx: {
        color: "#1F74EA",
        border: '1px solid #1F74EA',
        marginLeft: "-5px",
        borderRadius: "21px",
        height: "40px",
        fontFamily: Regular,
        float: "right !important",
        width: {
            sm: "80px",
            lg: "80px",
            xl: "85px"
        },

    },
    buttonSx: {
        textTransform: 'none',
        color: 'text.primary',
        fontSize: remCalc(16),
        fontFamily: SemiBold
    },
    buttonSxMob: {
        textTransform: 'none',
        color: 'text.tertiary',
        fontSize: remCalc(16),
        fontFamily: SemiBold
    },
    menuIconMob: {
        borderRadius: "4px",
        padding: "4px",
        width: "32px",
        height: "32px",
        backgroundColor: "#F1F7FF",
    },
    globeIconMob: {
        color: 'text.tertiary',
    },
    countryLabel: {
        color: 'text.primary',
        fontSize: remCalc(16),
        fontFamily: SemiBold,
        marginInlineStart: "8px"
    },
    countryLabelMob: {
        color: 'text.tertiary',
        fontSize: remCalc(16),
        fontFamily: SemiBold,
        marginInlineStart: "8px"
    },
    countryAvatar: {
        height: 24,
        width: 24
    },
    Logo: {
        mt: "10px",
        cursor: "pointer"
    },
    propertyListHeader: {
        fontFamily: Medium,
        fontWeight: 600
    },
    propertyListsubHead: {
        fontFamily: Regular,
        fontSize: remCalc(12),
        color: 'text.secondary'
    },
    propertyListsubHead2: {
        fontFamily: SemiBold,
        fontSize: remCalc(14),
        color: 'text.secondary'
    },
    country: {
        p: 3, display: 'flex', flexDirection: 'column', gap: 1, minHeight: 400,
        paddingTop: "0px !important"
    },
    countrySelect: {
        fontFamily: Medium,
        fontSize: remCalc(16),
        color: "text.secondary",
        mt: "24px"
    },
    countrySelectSub: {
        fontFamily: Regular,
        fontSize: remCalc(14),
    },
    headerText: {
        fontFamily: Medium, fontSize: remCalc(16), color: 'text.secondary',
    },
    logoBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    listText: {
        borderRadius: "21px",
        height: "40px",
        fontFamily: SemiBold,
        fontSize: remCalc(12),
    },
    contryBox: {
        cursor: "pointer"
    },
    contryBoxTab: {
        cursor: "pointer",
        textAlign: "left !important",
        width: "100% !important",
        borderBottom: "1px solid #CED3DD",
        padding: "16px 0",
        // justifyContent:"center",        
    },
    propertyBox: {
        border: "1px solid",
        borderColor: "border.primary",
        borderRadius: "4px",
        marginBottom: "16px"
    },
    mobDrawer: {
        display: { xs: 'block', sm: 'none' },
        '& .MuiDrawer-paper': {
            borderRadius: "0px 0px 16px 16px",
            width: "100%",
            height: "auto"
        },
    },
    menuList: {
        fontSize: remCalc(14),
        color: "text.primary",
        // borderBottom: "1px solid #CED3DD",
        padding: "12px 0",
        textAlign: "center",
        fontFamily: Regular
    },
    menuListTab: {
        fontSize: remCalc(14),
        color: "text.primary",
        // borderBottom: "1px solid #CED3DD",
        padding: "12px 0",
        textAlign: "left",
        width: "100%",
    },
    menuListTabSignIn: {
        fontSize: remCalc(14),
        color: "text.primary",
        padding: "16px 0",
        textAlign: "left !important",
        width: "100%",
    },
    menuListTabGlobe: {
        fontSize: remCalc(14),
        color: "text.primary",
        borderBottom: "1px solid #CED3DD",
        padding: "16px 0",
        textAlign: "left !important",
        width: "100%",
        borderRadius: "0px !important",
        justifyContent: "start",
    },
    menuListMob: {
        fontSize: remCalc(14),
        color: "text.primary",
        padding: "16px 0",
        fontFamily: Medium,
        marginInlineStart: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",

    },
    menuListActiveMob: {
        fontSize: remCalc(16),
        color: "error.light",
        padding: "16px 0",
        fontFamily: SemiBold,
        marginInlineStart: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    menuListActiveTab: {
        fontSize: remCalc(16),
        color: "error.light",
        padding: "16px 0",
        fontFamily: SemiBold,
        width: "100%",
        textAlign: "left !important"
    },
    menuListStyles: {
        padding: "0px"
    },
    signinTitle: {
        fontSize: remCalc(16),
        color: "#091B29",
        padding: "16px 0",
        marginInlineStart: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontFamily: SemiBold,

    },
    signinTitleWeb: {
        fontSize: remCalc(14),
        color: "#091B29",
        marginInlineStart: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        fontFamily: Medium,

    },
    avtlogo: {
        height: "24px",
        width: "24px"
    },
    nameBox: {
        display: "flex",
        backgroundColor: "#F2F4F7",
        borderRadius: "16px",
        padding: '6px '
    },
    menuListActive: {
        fontSize: remCalc(14),
        color: "error.light",
        borderBottom: "none",
        padding: "16px 0",
        textAlign: "center",
        fontFamily: Regular
    },
    menuBox: {
        padding: "0px 16px"
    },
    menu: {
        padding: "0 !important",
        "& .MuiPaper-root": {
            borderRadius: "12px",
        }
    },
    bottomBox: {
        margin: "0px auto",
        width: "80%"
    },
    avatarIcon: {
        "& .MuiChip-root": {
            marginInlineStart: "5px !important",

        }
    },
    chipIcon: {
        "& .MuiChip-avatar": {
            marginInlineStart: "4px",
            marginInlineEnd: "-6px"
        }
    },
    downArrow: {
        "& .MuiChip-deleteIcon": {
            marginInlineEnd: "5px"
        }
    },
    selectMenu: {
        height: "40px",
        borderRadius: "20px",
        backgroundColor: "#F5F7FA",
        padding: "12px",
        // width: "136px",
        display: "flex",
        alignItems: 'center',
        cursor: 'pointer',
    },
    menuBorder: {
        height: "40px",
        borderRadius: "20px",
        backgroundColor: "#F5F7FA",
        padding: "12px",
        // width: "136px",
        display: "flex",
        alignItems: 'center',
        cursor: 'pointer',
        border: '1px solid #1F74EA80',
    },
    divider: {
        border: '1px solid #CED3DD',
        height: "9px",
        marginInline: '8px'
    },
    dividerSx: {
        border: '1px solid #CED3DD',
        height: "24px",
    },
    enText: {
        fontSize: remCalc(12),
        fontFamily: Medium,
        textTransform: "uppercase",
        whiteSpace: 'nowrap'
    },
    arrow: {
        color: "#98A0AC",
    },
    box: {
        height: '253px',
        border: "1px solid #E4E8EE",
        width: "270px"
    },
    contentBox: {
        height: '253px',
        // width: '460px',
        p: 2
    },
    title: {
        fontSize: remCalc(12),
        fontFamily: Medium,
        color: '#091B29'
    },
    lang: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: '#4E5A6B',
        marginInlineStart: "6px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"

    },
    langmob: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: '#4E5A6B',
        marginInlineStart: "6px"
    },
    div: {
        height: '208px',
        mt: '16px',
        marginInline: '9px'
    },
    unselected: {
        display: 'flex', alignItems: 'center', mt: '16px', height: '24px', padding: "4px", cursor: "pointer"
    },
    selected: {
        display: 'flex', alignItems: 'center', mt: '16px', padding: "4px", backgroundColor: "#F1F7FF", borderRadius: borderRadius, cursor: "pointer"
    },
    selectedmob: {
        display: 'flex', alignItems: 'center', mt: '16px', padding: "4px", backgroundColor: "#F2F4F7", borderRadius: borderRadius,
    },
    langSelected: {
        fontSize: remCalc(12),
        fontFamily: SemiBold,
        color: '#78B1FE',
        marginInlineStart: "6px",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden"
    },
    langSelectedmob: {
        fontSize: remCalc(12),
        fontFamily: Medium,
        color: '#091B29',
        marginInlineStart: "6px",
    },
    dataBox: {
        height: '200px', overflow: 'auto'
    },
    yourAcc: {
        backgroundColor: '#F5F7FA',
        borderRadius: '8px',
        padding: "8px 12px 0px 12px",
        mt: "8px"
    },
    ya: {
        color: '#98A0AC',
        fontSize: remCalc(12),
        fontFamily: SemiBold
    },
    pad: {
        padding: "0px 0px 0px 16px"
    }
}