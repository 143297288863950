import { Typography, Box, IconButton, Avatar } from "@mui/material";
import React from "react";
import { propertyCardStyle } from "./style";
import { PropertyArrow } from "../../assets/components/propertyCard";

export const PropertyCard = ({
  img = "",
  header = "",
  isSearched = true,
  desc = '',
  both = false,
  NoImageIcon = false,
  data = "",
  onClick = () => false
}) => {
  const language = localStorage.getItem("i18nextLng");

  return (
    <Box
      sx={isSearched ? propertyCardStyle.searchMain : propertyCardStyle.propertyMain}
      onClick={() => onClick(data)}
    >
      <Box>

        <Avatar style={isSearched ? propertyCardStyle.height2 : propertyCardStyle.height} src={img ? img : "../images/noImageFound.svg"}></Avatar>
      </Box>
      <Box sx={isSearched ? propertyCardStyle.text : propertyCardStyle.propertyText}>
        <Typography sx={isSearched ? propertyCardStyle.propertyHeaderwrap : propertyCardStyle.propertyHeader}>{header}</Typography>
        {both ?
          <>
            <Typography sx={propertyCardStyle.desc}>{desc}</Typography>
            <IconButton>
              <PropertyArrow />
            </IconButton>
          </>
          :
          <>
            {!isSearched && <Typography sx={propertyCardStyle.desc1}>{desc}</Typography>}
            <IconButton >
              {isSearched && <PropertyArrow style={{ transform: language === 'ar' ?  "rotate(180deg)" : '' }} />}
            </IconButton>
          </>
        }
      </Box>

    </Box>
  )
}