import * as React from "react"

export const Filter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 112258">
      <path
        data-name="icons8-funnel (1)"
        d="M4.25 3A1.261 1.261 0 0 0 3 4.25v1.545A3.752 3.752 0 0 0 4.43 8.74L9.5 12.7v7.55a.75.75 0 0 0 1.186.61l3.5-2.5a.75.75 0 0 0 .314-.61V12.7l5.067-3.954A3.752 3.752 0 0 0 21 5.795V4.25A1.261 1.261 0 0 0 19.75 3Zm.25 1.5h15v1.295a2.247 2.247 0 0 1-.857 1.767l-5.354 4.177a.75.75 0 0 0-.289.591v5.034l-2 1.429V12.33a.75.75 0 0 0-.289-.592L5.357 7.562A2.247 2.247 0 0 1 4.5 5.795Z"
        fill="#4e5a6b"
        stroke="#4e5a6b"
        strokeWidth={0.3}
      />
    </g>
  </svg>
)

