import * as React from "react"

const TransportIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <g data-name="Group 111271">
            <path
                data-name="icons8-public-transportation (1)"
                d="M6.878 3a3.855 3.855 0 0 0-3.851 3.851v1.318A.768.768 0 0 0 2 8.905v2.568a.77.77 0 0 0 1.027.736v8.764A1.027 1.027 0 0 0 4.054 22h.514a1.027 1.027 0 0 0 1.027-1.027H9.7v-3.851a3.271 3.271 0 0 1 .6-1.887l.456-1.953a3.4 3.4 0 0 1 .174-.535l-6.362.008v-5.9a2.314 2.314 0 0 1 2.31-2.314h9.757a2.314 2.314 0 0 1 2.311 2.311v3.867a3.311 3.311 0 0 1 2.568 1.454V8.905a.77.77 0 0 0-.77-.77.762.762 0 0 0-.257.047V6.851A3.855 3.855 0 0 0 16.635 3Zm3.595 2.568a.77.77 0 1 0 0 1.541h2.567a.77.77 0 1 0 0-1.541Zm3.533 6.162a2.318 2.318 0 0 0-2.251 1.785l-.492 2.11-.009.049a2.287 2.287 0 0 0-.525 1.447v4.365a.514.514 0 0 0 .514.514h1.027a.514.514 0 0 0 .514-.514v-.514h7.189v.514a.514.514 0 0 0 .514.514h1.027a.514.514 0 0 0 .514-.514v-4.364a2.287 2.287 0 0 0-.524-1.446q0-.025-.011-.05L21 13.516a2.317 2.317 0 0 0-2.25-1.785Zm0 1.541h4.744a.762.762 0 0 1 .749.595l.221.947h-6.685l.221-.947a.763.763 0 0 1 .75-.596Zm-6.871 2.567a1.027 1.027 0 1 1-1.027 1.027 1.027 1.027 0 0 1 1.027-1.027Zm5.905 1.541a.77.77 0 1 1-.77.77.771.771 0 0 1 .77-.771Zm6.676 0a.77.77 0 1 1-.77.77.771.771 0 0 1 .77-.771Z"
                fill={props?.color ?? "#4e5a6b"}
            />
        </g>
    </svg>
)

export default TransportIcon
