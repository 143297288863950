import { remCalc } from "../../utils"
import { Medium } from "../../utils/font"

export const selectBarStyle = {
      container: (styles) => ({ ...styles, width: "100%" }),
      placeholder: (styles) => ({ ...styles, display: 'none' }),
      input: (styles) => ({ ...styles }),
      singleValue: (styles, { data }) => ({ ...styles, textAlign: 'left', marginLeft: 22, color: '#111' }),
      control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: "10px", width: '100%' }),
      menu: (styles) => ({ ...styles, fontSize: remCalc(14), fontFamily: Medium, color: "primary.light1" })
}

export const otherStyles = {
      buttonBox: {
            p: 1
      },
      button: {
            width: '100%',
            height: "40px"
      }

}