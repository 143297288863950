import * as React from "react"

export const MailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={80}
    height={80}
    {...props}
  >
    <defs>
      <linearGradient
        id="b"
        x1={0.348}
        y1={4.151}
        x2={1.016}
        y2={2.878}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5059c9" />
        <stop offset={0.999} stopColor="#4750b3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-0.039}
        y1={3.899}
        x2={0.808}
        y2={3.132}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#5961c3" />
        <stop offset={1} stopColor="#3a41ac" />
      </linearGradient>
      <linearGradient
        id="a"
        x1={0.348}
        y1={3.278}
        x2={1.016}
        y2={2.005}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#28afea" />
        <stop offset={1} stopColor="#0b88da" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={-0.039}
        y1={3.039}
        x2={0.807}
        y2={2.27}
        xlinkHref="#a"
      />
      <linearGradient
        id="e"
        x1={13.012}
        y1={52.341}
        x2={13.012}
        y2={51.223}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c965eb" />
        <stop offset={1} stopColor="#c767e5" />
      </linearGradient>
    </defs>
    <g data-name="Group 110801">
      <path
        data-name="Path 100179"
        d="M8.857 25.991 51.515 9.036V40.21a2.369 2.369 0 0 1-2.37 2.37H8.857Z"
        transform="translate(7.514 4.6)"
        fill="url(#b)"
      />
      <path
        data-name="Path 100180"
        d="M4 9v31.3a2.37 2.37 0 0 0 2.37 2.37H51.4a2.351 2.351 0 0 0 1.521-.566Z"
        transform="translate(5.262 4.583)"
        fill="url(#c)"
      />
      <path
        data-name="Path 100181"
        d="M11.632 9.262h45.03a2.369 2.369 0 0 1 2.37 2.37v2.005L36.814 28.772a4.736 4.736 0 0 1-5.337 0L9.262 13.636V11.63a2.369 2.369 0 0 1 2.37-2.368Z"
        fill="#7b83eb"
      />
      <path
        data-name="Path 100182"
        d="M59.03 22.366H20.415a3.833 3.833 0 0 0-3.834 3.834v21.05h1.464L59.03 23.83Z"
        opacity={0.05}
      />
      <path
        data-name="Path 100183"
        d="M59.03 23.168H20.413a3.1 3.1 0 0 0-3.1 3.1V47.25h2.2L59.03 23.83Z"
        opacity={0.07}
      />
      <path
        data-name="Path 100184"
        d="m14.857 35.991 42.658-16.955V50.21a2.369 2.369 0 0 1-2.37 2.37H14.857Z"
        transform="translate(10.297 9.237)"
        fill="url(#a)"
      />
      <path
        data-name="Path 100185"
        d="M10 19.036V50.21a2.369 2.369 0 0 0 2.37 2.37H57.4a2.351 2.351 0 0 0 1.521-.566Z"
        transform="translate(8.045 9.237)"
        fill="url(#d)"
      />
      <path
        data-name="Path 100186"
        d="M18.045 27.454h49.768v2.042L47.289 45.729a7.009 7.009 0 0 1-8.721 0L18.045 29.496Z"
        opacity={0.05}
      />
      <path
        data-name="Path 100187"
        d="M18.045 26.861h49.768v2.029L46.445 44.613a5.854 5.854 0 0 1-7.029 0L18.045 28.89Z"
        opacity={0.07}
      />
      <path
        data-name="Path 100188"
        d="M20.415 23.9h45.03a2.369 2.369 0 0 1 2.37 2.37v2.005L45.597 43.41a4.736 4.736 0 0 1-5.337 0L18.045 28.274v-2.006a2.369 2.369 0 0 1 2.37-2.368Z"
        fill="#50e6ff"
      />
      <path
        data-name="Path 100189"
        d="M64.036 46.566a2.618 2.618 0 0 0-1.865-.78 2.654 2.654 0 0 0-2.654 2.648v5.158H49.271a3.418 3.418 0 0 0-3.415 3.415v3.9a3.366 3.366 0 0 0 .138.906h19.449a2.37 2.37 0 0 0 2.37-2.37v-9.102Z"
        opacity={0.05}
      />
      <path
        data-name="Path 100190"
        d="M63.517 47.083a1.888 1.888 0 0 0-1.345-.565 1.921 1.921 0 0 0-1.922 1.916v5.89H49.272a2.686 2.686 0 0 0-2.684 2.683v3.9a2.662 2.662 0 0 0 .168.906h18.688a2.37 2.37 0 0 0 2.37-2.37v-8.065Z"
        opacity={0.07}
      />
      <path
        data-name="Path 100191"
        d="M43.661 33.184v6.622h-11.71A1.952 1.952 0 0 0 30 41.758v3.9a1.952 1.952 0 0 0 1.951 1.951h11.71v6.622a1.182 1.182 0 0 0 2.019.836l9.689-9.689a2.365 2.365 0 0 0 0-3.345l-9.689-9.684a1.182 1.182 0 0 0-2.019.835Z"
        transform="translate(17.32 15.249)"
        fill="url(#e)"
      />
    </g>
  </svg>
)

