import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { simpleSelectStyles } from "./style";
import React from "react";
import { Box } from "@mui/system";

const getValue = (value, options) => {
  if (value && options?.length > 0) {
    return options.filter((val) => val?.value === value)?.[0];
  } else {
    return value ?? null;
  }
};

export const SimpleSelect = ({
  placeholder = "",
  id = "",
  options = [],
  disabled = false,
  handleChangeSelect = () => false,
  value = "",
  fieldStyle = {}
}) => {

  const giveMeValueLabel = (option) => {
    return `${option?.value}`;
  };

  return (
    <Box sx={simpleSelectStyles.margin}>
      <Autocomplete
        id={id}
        sx={{ ...simpleSelectStyles.autocomplete, ...fieldStyle }}
        placeholder={placeholder}
        options={options}
        getOptionLabel={(option) => giveMeValueLabel(option)}
        disabled={disabled}
        onChange={(event, value) => handleChangeSelect(value?.value)}
        value={getValue(value, options) ? getValue(value, options) : null}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            placeholder={placeholder}
            autoComplete={"true"}
          />
        )}
      />
    </Box>
  );
};
