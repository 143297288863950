import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CancelIcon, CloseIcon } from "../../assets";
import { dialogStyle } from "./style";
import * as React from "react";
import { DialogActions, Drawer, Grid, Hidden, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";


export const DialogBox = ({
  open = false,
  handleClose = () => false,
  fullScreen = false,
  maxWidth = '',
  isnotTitle = false,
  header,
  component,
  action,
  isDivided = false,
  dialogContentStyle = {},
  isPadding = false,
  onClose = "",
  footer = "",
  height = "",
  padding = "",
  isheight = "",
  rootStyle = {},
  dialogpadding = '',
  width = "100%",
}) => {

  const language = localStorage.getItem("i18nextLng");
  const dialog_style = dialogStyle({ width })

  return (
    <div >
      <Hidden smDown>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={isDivided ? dialog_style.rootmainDivided : { ...dialog_style.rootmain, ...rootStyle }}
          fullWidth
          fullScreen={fullScreen ? true : false}
          maxWidth={maxWidth ?? "lg"}
        >
          {
            !isnotTitle &&
            <DialogTitle sx={isPadding ? dialog_style.viewHeader : dialog_style.header}>
              <span>{header}</span>{" "}
              <IconButton onClick={() => handleClose()}>
                <CancelIcon />
              </IconButton>
            </DialogTitle>
          }
          <DialogContent sx={{ ...dialog_style.root, ...dialogContentStyle }} dividers={isDivided ? true : false}>
            <DialogContentText id="alert-dialog-description">
              {component}
            </DialogContentText>
          </DialogContent>
          {action && <DialogActions>
            {action}
          </DialogActions>}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          sx={isheight ? dialog_style.drawer : dialog_style.drawer1}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          {
            !isnotTitle &&
            <DialogTitle sx={{ ...dialog_style.hd, ...dialogpadding }}>
              <Box sx={dialog_style.headTitle}>
                <Grid container>
                  <Grid item xs={10.5}>
                    <Typography sx={dialog_style.filt} > {header}</Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Box sx={dialog_style.closeBox}>
                      <CloseIcon rootStyle={language === 'ar' ? dialog_style.clRtl : dialog_style.cl} onClick={() => handleClose()} />
                    </Box>
                    {/* <CircleCancel onClick={() => handleClose()}/> */}
                  </Grid>
                </Grid>
              </Box>
            </DialogTitle>
          }
          <Box height={height ?? "100%"} overflow="auto" padding={padding ?? 1}>
            {component}
          </Box>

          <DialogActions sx={{ justifyContent: "space-between" }}>
            {footer ??
              <Box sx={dialog_style?.footer}>
                {footer}
              </Box>
            }
          </DialogActions>
        </Drawer>
      </Hidden>
    </div>
  );
}
