import { borderRadius } from "../../utils";

export const imageGridstyle = {
    image: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        width: '100%',
        opacity: 1,
        objectFit: 'cover',
        height: '100%',
        cursor: "pointer"
    },
    last: {
        background: '#00000082',
        position: 'absolute',
        width: '100%',
        height: '180px',
        borderRadius: borderRadius
    },
    text: {
        position: 'absolute',
        top: {
            sm: '60px',
            md: '70px',
            lg: '85px',
            xl: '140px'
        },
        bottom: '0px',
        left: '0px',
        right: '0px',
        textAlign: 'center',
        color: 'white',
        fontSize: {
            xl: '2.0rem'
        }
    },
    image2: {
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: borderRadius,
        width: '100%',
        opacity: 1,
        objectFit: 'cover',
        height: '180px',
        cursor: "pointer"

    },
    main: {
        gridTemplateColumns: 'repeat(auto-fill,minmax(280px,1fr)) !important'
    }
}