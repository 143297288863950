import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = localStorage.getItem(LocalStorageKeys.authToken);
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    },

});

const baseQueryWithRetry = retry(baseQuery);
export const facilitiesAPI = createApi({
    reducerPath: "facilitiesAPI",
    baseQuery: baseQueryWithRetry,
    tagTypes: ["Facilities"],
    endpoints: (builder) => ({

        // getAllFacilities
        getAllFacilities: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/search/facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
        // getAllFacilitiesView
        getAllFacilitiesView: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/search/single_property_facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
        // getAllFacilities booking
        getAllFacilitiesBooking: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/search/facility_info`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
        // getAllFacilities booking
        getAllFacilitiesBookingConfirmation: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/search/book_facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),

        // Facilities booked table
        getAllBookedFacilities: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/booking/facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),

        // View Facilities booked
        getViewAllBookedFacilities: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/booking/view_facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),

        // Cancel Facilities booked
        getCancelBookedFacilities: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/booking/cancle_facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;

            }
        }),

        // Cancel Facilities booked
        // getCancelBookedFacilities: builder.query(
        //     {
        //         query: (payload) => {
        //             return {
        //                 url: `api/v1/booking/cancle_facility`,
        //                 method: "POST", 
        //                 body: payload
        //             }
        //         },

        //         transformResponse: (response, meta, arg) => {
        //             if (response.type === "success") {
        //                 return { data: response.data, totalCount: response.totalRecords };
        //             }
        //             return { data: [], totalCount: 0 };
        //         },
        //     }),

        // Get Popular Facilities
        getPopularFacilities: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/search/popular_facility`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
        // Get Popular Facilities
        getCategory: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/master/amenity_category`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
        // 
        // Get Currency Ratio
        getCurrencyRatio: builder.mutation({
            query: (payload) => {
                return {
                    url: `api/v1/search/currency_ratio`,
                    method: "POST",
                    body: payload
                }
            },
            transformResponse: (response, meta, arg) => {
                return response;
            }
        }),
    }),
});

// Export hooks for usage in functional components
export const {
    useGetAllFacilitiesMutation,
    useGetAllFacilitiesViewMutation,
    useGetAllFacilitiesBookingMutation,
    useGetAllFacilitiesBookingConfirmationMutation,
    useGetAllBookedFacilitiesMutation,
    useGetViewAllBookedFacilitiesMutation,
    useGetCancelBookedFacilitiesMutation,
    // useLazyGetCancelBookedFacilitiesQuery,
    useGetPopularFacilitiesMutation,
    useGetCategoryMutation,
    useGetCurrencyRatioMutation


} = facilitiesAPI;
