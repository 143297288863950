import { borderRadius } from "../../../utils";
import { Medium, Regular, SemiBold } from "../../../utils/font";

export const searchResultsStyles = {
    mainBox: {
        height: "125px",
        borderRadius: borderRadius,
        backgroundColor: "text.default",
        position: "relative",
        display: "flex",
        boxShadow: 3,
        border: "2px solid #0071F2",
        cursor: "pointer",
        width: {
            320: "287px",
            xs: "318px"
        }
    },
    img: {
        height: "122px",
        width: "125px",
        borderRadius: borderRadius,
        objectFit: "cover"
    },
    chip: {
        // position: "absolute",
        fontFamily: SemiBold,
        fontSize: "0.75rem",
        backgroundColor: "#30C4AB",
        height: "22px",
        // width: "84px",
        color: "text.default",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        left: "20px",
        right: " 25px",
        top: "8px"
    },
    amt: {
        fontFamily: Medium,
        fontSize: "0.75rem",
        color: "text.default",
        backgroundColor: "#000",
        height: "24px",
        maxWidth: "100px",
        width: "auto",
        padding: "8px",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        borderRadius: "15px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    month: {
        fontFamily: Regular
    },
    textbox: {
        marginInlineStart: "12px",
        mt: "8px",
        maxWidth: "181px"
    },
    title: {
        fontFamily: Medium,
        fontSize: "1rem",
        color: "text.primary",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        mt: "8px",
        width: "100%"
    },
    subtitle: {
        fontFamily: Regular,
        fontSize: "0.875rem",
        color: "text.tertiary",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        mt: "4px",
        width: "97%"
    },
    units: {
        fontFamily: SemiBold,
        fontSize: "0.875rem",
        color: "#4E5A6B",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        mt: "6px"
    },
    unitbox: {
        display: "flex"
    },
    tick: {
        mt: "8px"
    }

}