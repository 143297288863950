import { Box, Chip, Divider, Grid, Typography, FormControlLabel, Checkbox, Hidden } from "@mui/material";
import { StaysCardStyle } from "./style";
import { PersonIcon } from "../../assets/components";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { CustomButton } from "../Button";
import Tooltip from "@mui/material/Tooltip";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";

export const StaysCard = ({
  onClickBooking = () => false,
  roomname = "",
  persons = "",
  amenities = [],
  cancelDate = "",
  price = "",
  gst = "",
  day = "",
  onClickViewMore = () => false,
  onClickPopup = () => false,
  isButton = false,
  buttonName = "",
  available
}) => {

  const CheckboxMod = ({ label, icon, checkedIcon }) => {
    return (
      <FormControlLabel
        label={label}
        sx={StaysCardStyle.checkboxLabel}
        control={
          <Checkbox icon={icon} checkedIcon={checkedIcon} />
        }
      />
    );
  }


  const data = [
    {
      name: "Without Breakfast",
      value: "1",
    },
    {
      name: "With Breakfast @ $50/ person",
      value: "2",
    },
    {
      name: "With Breakfast, Lunch @ $100/ person",
      value: "3",
    },
    {
      name: "All Meal Inclusive @ $150/ person",
      value: "4",
    },
  ];
  return (
    <>
      {/* main card and image */}
      <Hidden smDown>
        <Box sx={StaysCardStyle.stayscardmainbox}>
          <Grid container>
            <Grid item md={6} sm={6} lg={3} xl={3}>
              <Box sx={StaysCardStyle.stayscardimagebox}>
                <img
                  src="https://fastly.picsum.photos/id/140/206/271.jpg?hmac=o9MRdgRq4RqX1htvOlXLe-xbFTIA4v4mD2ZNJ3GaADw"
                  alt="house"
                  style={StaysCardStyle.stayscardimage}
                />
              </Box>
            </Grid>
            {/* room name and facilities */}
            <Grid item md={6} sm={6} lg={3} xl={3} sx={StaysCardStyle.gridCard}>
              <Box sx={StaysCardStyle.roomFacilitiesBox}>
                <Typography sx={StaysCardStyle.roomName}>{roomname}</Typography>
                <Chip
                  icon={<PersonIcon />}
                  label={persons}
                  sx={StaysCardStyle.chipData}
                />
                <Box sx={StaysCardStyle.iconData}>
                  {amenities.length > 0 &&
                    amenities?.map((val) => (
                      <Box sx={StaysCardStyle.icons}>
                        <FiberManualRecordIcon sx={StaysCardStyle.doticongrey} />
                        <Typography sx={StaysCardStyle.icontext}>
                          {val}
                        </Typography>
                      </Box>
                    ))}
                </Box>
                <Typography sx={StaysCardStyle.moreText} onClick={onClickViewMore}>{"View More"}</Typography>
              </Box>
              <Hidden mdDown>
                <Box>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={StaysCardStyle.dividerStyle}
                  />
                </Box>
              </Hidden>
            </Grid>
            {/* radio buttons  */}
            <Grid item md={6} sm={6} lg={3} xl={3} sx={StaysCardStyle.gridCard}>
              <Box sx={StaysCardStyle.staysCardRadioButton}>
                {data?.map((val) => (
                  <CheckboxMod
                    label={val.name}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                  />
                ))}

              </Box>
              <Box>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={StaysCardStyle.dividerStyle}
                />
              </Box>
            </Grid>
            {/* booking details price and gst */}
            <Grid item md={6} sm={6} lg={3} xl={3}>
              {available ?
                <Box sx={StaysCardStyle.bookingbox}>
                  <Box>
                    <Box sx={StaysCardStyle.cancelTextbox}>
                      <Typography
                        sx={StaysCardStyle.cancelText}
                        component="h6"
                        align="right"
                      >
                        {available ? cancelDate : "Rooms Not Available"}
                      </Typography>
                      {available && <Tooltip
                        title="Cancellation policy term and condition will display here"
                        arrow
                        placement="left"
                        PopperProps={{
                          sx: StaysCardStyle.tooltip,
                        }}
                      >
                        <ErrorOutlineIcon sx={StaysCardStyle.errorIcon} />
                      </Tooltip>}

                    </Box>
                    <Typography
                      sx={StaysCardStyle.nightText}
                      component="h6"
                      align="right"
                    >
                      {day}
                    </Typography>
                    <Typography
                      sx={StaysCardStyle.amountText}
                      component="h6"
                      align="right"
                    >
                      {price}
                    </Typography>
                    <Typography
                      sx={StaysCardStyle.taxText}
                      component="h6"
                      align="right"
                    >
                      {gst}
                    </Typography>
                  </Box>
                  {isButton &&
                    <Box sx={StaysCardStyle.stayCardButtonBox}>
                      <CustomButton
                        variant="outlined"
                        size="medium"
                        label={buttonName}
                        rootStyle={StaysCardStyle.staysCardButton}
                        onClick={buttonName === "Book Now" ? onClickBooking : onClickPopup}
                      ></CustomButton>
                    </Box>
                  }
                </Box>
                :
                <Box sx={StaysCardStyle.roomsNotAvailableBoxMob}>
                  <Typography sx={StaysCardStyle.roomsNotavailable}>Room not available</Typography>
                  <Typography sx={StaysCardStyle.roomsNotavailableStubText}>Please check availability on different dates</Typography>
                </Box>
}
            </Grid>
          </Grid>
        </Box>
      </Hidden>

      <Hidden smUp>
        <Box sx={StaysCardStyle.stayscardmainbox}>
          <Grid container>
            <Grid item xs={12} md={6} sm={6} lg={3} xl={3}>
              <Box sx={StaysCardStyle.stayscardimageboxMob}>
                <img
                  src="https://fastly.picsum.photos/id/140/206/271.jpg?hmac=o9MRdgRq4RqX1htvOlXLe-xbFTIA4v4mD2ZNJ3GaADw"
                  alt="house"
                  style={StaysCardStyle.stayscardimageMob}
                />
                <Box sx={available ? StaysCardStyle.successTextboxMob : StaysCardStyle.cancelTextboxMob}>
                  <Typography
                    sx={StaysCardStyle.cancelTextMob}
                  >
                    {available ? cancelDate : "Rooms Not Available"}
                  </Typography>
                  {available && <Tooltip
                    title="Cancellation policy term and condition will display here"
                    arrow
                    placement="left"
                    PopperProps={{
                      sx: StaysCardStyle.tooltipMob,
                    }}
                  >
                    <ErrorOutlineIcon sx={StaysCardStyle.errorIconMob} />
                  </Tooltip>
                  }


                </Box>
              </Box>
            </Grid>
            {/* room name and facilities */}
            <Grid item xs={12} md={6} sm={6} lg={3} xl={3} sx={StaysCardStyle.gridCard}>
              <Box sx={StaysCardStyle.roomFacilitiesBoxMob}>
                <Box sx={StaysCardStyle.titleSeperation}>
                  <Typography sx={StaysCardStyle.roomNameMob}>{roomname}</Typography>
                  <Typography sx={StaysCardStyle.moreTextMob} onClick={onClickViewMore}>{"View More"}</Typography>
                </Box>
                {/* StaysCardStyle.chipUserIconMob */}
                <Chip
                  icon={<PersonIcon />}
                  // rootStyle={{ color: "#5AC782 !important", marginTop:"5px" }}
                  label={persons}
                  sx={StaysCardStyle.chipDataMob}
                />
                <Box>

                </Box>
                <Grid container>
                  {amenities.length > 0 &&
                    amenities?.map((val) => (
                      <Grid item xs={6}>
                        <Box sx={StaysCardStyle.iconsMob}>
                          <FiberManualRecordIcon sx={StaysCardStyle.doticongreyMob} />
                          <Typography sx={StaysCardStyle.icontextMob}>
                            {val}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
            <Divider sx={StaysCardStyle?.DividerMob} />
            {/* radio buttons  */}
            <Grid item xs={12} md={6} sm={6} lg={3} xl={3} sx={StaysCardStyle.gridCard}>
              <Box sx={StaysCardStyle.staysCardRadioButtonMob}>
                {data?.map((val) => (
                  <CheckboxMod
                    label={val.name}
                    icon={<RadioButtonUnchecked />}
                    checkedIcon={<RadioButtonChecked />}
                  />
                ))}
              </Box>
            </Grid>
            <Divider sx={StaysCardStyle?.DividerMobRooms} />
            {/* booking details price and gst */}
            <Grid item xs={12} md={6} sm={6} lg={3} xl={3}>
              {available ?
                <Box sx={StaysCardStyle.bookingboxMob}>
                  <Box>

                    <Typography
                      sx={StaysCardStyle.nightTextMob}
                      component="h6"
                    >
                      {day}
                    </Typography>
                    <Typography
                      sx={StaysCardStyle.amountTextMob}
                      component="h6"
                    >
                      {price}
                    </Typography>
                    <Typography
                      sx={StaysCardStyle.taxTextMob}
                      component="h6"
                    >
                      {gst}
                    </Typography>
                  </Box>
                  {isButton &&
                    <Box sx={StaysCardStyle.stayCardButtonBox}>
                      <CustomButton
                        variant="outlined"
                        size="medium"
                        label={buttonName}
                        rootStyle={StaysCardStyle.staysCardButton}
                        onClick={buttonName === "Book Now" ? onClickBooking : onClickPopup}
                      ></CustomButton>
                    </Box>
                  }
                </Box>
                :
                <Box sx={StaysCardStyle.roomsNotAvailableBoxMob}>
                  <Typography sx={StaysCardStyle.roomsNotavailable}>Room not available</Typography>
                  <Typography sx={StaysCardStyle.roomsNotavailableStubText}>Please check availability on different dates</Typography>
                </Box>
              }
            </Grid>
          </Grid>
        </Box>

      </Hidden>

      {/* <Box>
        <Typography sx={StaysCardStyle.roomsNotavailable}>Room not available</Typography>
        <Typography sx={StaysCardStyle.roomsNotavailableStubText}>Please check availability on different dates</Typography>
      </Box> */}

    </>
  );
};
