import * as React from "react"

export const KeysIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 111261" transform="translate(-691 -150)">
      <rect
        data-name="Rectangle 57032"
        width={40}
        height={40}
        rx={8}
        transform="translate(691 150)"
        fill="#fff4eb"
      />
      <g data-name="icons8-keys-holder (1)" transform="translate(691 156)">
        <path
          data-name="Path 100346"
          d="M12 8.545V3.091A1.091 1.091 0 0 1 13.091 2h9.273a1.091 1.091 0 0 1 1.091 1.091v5.454a1.091 1.091 0 0 1-1.091 1.091h-9.273A1.091 1.091 0 0 1 12 8.545Z"
          fill="#795548"
        />
        <path
          data-name="Path 100347"
          d="M17.727 5.273a4.5 4.5 0 0 0-.818.082v1.111a3.434 3.434 0 0 1 .818-.1 3.6 3.6 0 0 1 3.545 3.648 3.708 3.708 0 0 1-.466 1.807 2.694 2.694 0 0 1-2.397 1.267 3.278 3.278 0 0 1 .989 1.338 4.743 4.743 0 0 0 2.961-4.414 4.694 4.694 0 0 0-4.632-4.739Z"
          fill="#8ecbf8"
        />
        <path
          data-name="Path 100348"
          d="M27.95 19.454h-1.223v-1.227l-.413-.413h-1.223v-1.219l-.773-.773a3.81 3.81 0 0 0-6.284-3.97 3.887 3.887 0 0 0-.558.709 3.278 3.278 0 0 1 1.629 1.291 1.091 1.091 0 1 1 .44 1.03 3.239 3.239 0 0 1-.749 2.936 3.77 3.77 0 0 0 3.2.309l4.053 4.053h1.568l.746-.8v-1.512Z"
          fill="#fb8c00"
        />
        <path
          data-name="Path 100349"
          d="m28.363 21.385-4.044-4.044-.771.771 4.07 4.07Z"
          fill="#ef6c00"
        />
        <path
          data-name="Path 100350"
          d="M20.181 15.636a3.818 3.818 0 1 0-5.455 3.453v5.82L15.817 26h1.091l1.091-1.091v-1.091L17.181 23l.818-.818v-.545l-.545-.819.545-.818v-.911a3.79 3.79 0 0 0 2.182-3.453Zm-3.818-2.182a1.091 1.091 0 1 1-1.091 1.091 1.091 1.091 0 0 1 1.091-1.091Z"
          fill="#ffc107"
        />
        <path
          data-name="Path 100351"
          d="M15.818 20h1.091v6h-1.091Z"
          fill="#c98d09"
        />
        <path
          data-name="Path 100352"
          d="M16.705 13.507c-.107-.035-.331-.128-.331-.128a3.677 3.677 0 0 1 .536-6.916V5.351a4.746 4.746 0 0 0 .526 9.384 1.074 1.074 0 0 0-.731-1.226Z"
          fill="#8ecbf8"
        />
        <path
          data-name="Path 100353"
          d="M18.546 5.352a4.1 4.1 0 0 0-1.636 0 4.587 4.587 0 0 0-2.167 1.041 2.976 2.976 0 0 0 .374 1.173 3.523 3.523 0 0 1 1.792-1.1 3.315 3.315 0 0 1 1.636 0 3.526 3.526 0 0 1 1.792 1.1 2.97 2.97 0 0 0 .378-1.173 4.587 4.587 0 0 0-2.169-1.041Z"
          fill="#54a8ed"
        />
        <circle
          data-name="Ellipse 129699"
          cx={1.909}
          cy={1.909}
          r={1.909}
          transform="translate(15.818 4.182)"
          fill="#af9a92"
        />
      </g>
    </g>
  </svg>
)
