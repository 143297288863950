import * as React from "react"
export const MemberIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 111608">
      <path
        fill="#98a0ac"
        d="M12 4a4.21 4.21 0 0 0-4.375 4A4.21 4.21 0 0 0 12 12a4.21 4.21 0 0 0 4.375-4A4.21 4.21 0 0 0 12 4Zm0 1.2A2.931 2.931 0 0 1 15.063 8 2.931 2.931 0 0 1 12 10.8 2.931 2.931 0 0 1 8.937 8 2.931 2.931 0 0 1 12 5.2Zm-5.031 8.4A1.9 1.9 0 0 0 5 15.4v.48a3.611 3.611 0 0 0 2.06 2.957A9.88 9.88 0 0 0 12 20a9.88 9.88 0 0 0 4.94-1.163A3.611 3.611 0 0 0 19 15.88v-.48a1.9 1.9 0 0 0-1.969-1.8Zm0 1.2h10.062a.621.621 0 0 1 .656.6v.48a2.437 2.437 0 0 1-1.454 1.945A8.564 8.564 0 0 1 12 18.8a8.564 8.564 0 0 1-4.234-.975 2.437 2.437 0 0 1-1.454-1.945v-.48a.621.621 0 0 1 .657-.6Z"
        data-name="icons8-account (1)"
      />
    </g>
  </svg>
)

