import { Avatar, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { bookingCardStyles } from "./style"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getTimeWithAmPm } from "../../../utils/common";
import moment from "moment";
import { withTranslation } from "react-i18next";

const BookingCards = ({

    booked = "",
    booking = "facility",
    date = "",
    id = "",
    roomname = "",
    propname = "",
    onClick = () => false,
    val = {},
    t = () => false

}) => {


    return (
        // card images and text content
        <Box sx={bookingCardStyles.mainBox} onClick={() => onClick(val)}>

            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"}>
                    <Avatar src={val?.facility_assets?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"} sx={bookingCardStyles.image} />
                    <Stack sx={bookingCardStyles.textStack}>
                        <Typography sx={bookingCardStyles.text}>{roomname}</Typography>
                        <Typography sx={bookingCardStyles.text2}> {propname}</Typography>
                    </Stack>

                </Stack>
                <Stack>
                    {val?.booking_status === 'Booked' ? (
                        <Typography sx={bookingCardStyles.chipbooked}>{t("Booked")}</Typography>
                    ) : val?.booking_status === 'Cancelled' ? (
                        <Typography sx={bookingCardStyles.chipcancel}>{t("Cancelled")}</Typography>
                    ) : val?.booking_status === 'Contacted Stay Team' ? <Typography sx={bookingCardStyles.chipcontacted}>{t("Pending")}</Typography> : null}
                </Stack>

            </Stack>
            {/* Date and Time details */}
            <Box sx={bookingCardStyles.details}>
                {
                    booking === "facility" ?
                        <>
                            <Typography sx={bookingCardStyles.time}>
                                {/* {val?.period === "Hourly" ? `${getTimeWithAmPm(val?.booked_slots?.[0]?.[0])} - ${getTimeWithAmPm(val?.booked_slots?.[val?.booked_slots?.length - 1]?.[1])}` : `${moment(val?.booking_start_time).format("DD MMM YY")} - ${moment(val?.booking_end_time).format("DD MMM YY")}`} */}


                                {val?.period === "Hourly" ? `${getTimeWithAmPm(val?.booked_slots?.[0]?.[0])} - ${moment(val?.booked_slots?.[val?.booked_slots?.length - 1]?.[1], "hh:mm:ss").add(1, "seconds").format("hh:mm A")}` : `${moment(val?.booking_start_time).format("DD MMM YY")} - ${moment(val?.booking_end_time).utc().format("DD MMM YY")}`}
                            </Typography>
                            <FiberManualRecordIcon sx={bookingCardStyles.dot} />
                            <Typography sx={bookingCardStyles.time}>
                                {moment(date).format("DD-MMM-YY")}
                            </Typography>
                            <FiberManualRecordIcon sx={bookingCardStyles.dot} />
                            <Typography sx={bookingCardStyles.time}>{val?.booking_no}</Typography>
                        </>
                        :
                        <>
                            <Typography sx={bookingCardStyles.time}>{date}</Typography>
                            <FiberManualRecordIcon sx={bookingCardStyles.dot} />
                            <Typography sx={bookingCardStyles.time}>{id}</Typography>
                        </>
                }
            </Box>
        </Box>
    )
}


export default withTranslation('confirmBooking')(BookingCards)