import * as React from "react"
export const MyBooking = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 116314">
      <path
        fill="#98a0ac"
        d="M11.857 4.002a.574.574 0 0 0-.354.123L6.089 8.389A2.838 2.838 0 0 0 5 10.633v6.51a.953.953 0 0 0 .952.952h2.1v-5.142a2.481 2.481 0 0 1 2.476-2.476h2.662a2.481 2.481 0 0 1 2.476 2.476v5.143h2.1a.953.953 0 0 0 .952-.952v-6.511a2.838 2.838 0 0 0-1.089-2.244l-5.418-4.266a.574.574 0 0 0-.354-.121Zm-1.333 7.619a1.342 1.342 0 0 0-1.333 1.333v6.476a.571.571 0 0 0 .908.462l1.759-1.279 1.759 1.279a.571.571 0 0 0 .908-.462v-6.477a1.342 1.342 0 0 0-1.333-1.333Z"
        data-name="icons8-booking (3)"
      />
    </g>
  </svg>
)

