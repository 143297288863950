import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const MapviewCardStyle = {
  main: {
    height: { md: "310px", sm: "310px", },
    borderRadius: borderRadius,
    opacity: 1,
    width: "auto",
    boxShadow: "0px 0px 32px #0000000f",
    border: "1px solid #CED3DD",
    position: "relative",
    cursor: "pointer"
  },
  mapIcons: {
    display: 'flex', alignItems: 'center', gap: 0.2
  },
  subheading: {
    fontFamily: Medium,
    color: 'text.secondary',
    fontSize: "0.75rem",
    pb: {
      sm: "10px",
      lg: "0px"
    }
  },
  subheading2: {
    fontFamily: Regular,
    color: 'text.secondary',
    fontSize: "0.75rem",
    pb: {
      sm: "10px",
      lg: "0px"
    }
  },
  pdetails: {
    display: 'flex',
    gap: 1,
    mt: 1
  },
  maintext: {
    color: "text.primary",
    fontSize: "1rem",
    fontFamily: Medium,
  },
  subText: {
    color: "text.tertiary",
    fontSize: "0.875rem !important",
    fontFamily: Regular,
    lineHeight: 2.2,
  },
  chip: {
    position: "absolute",
    backgroundColor: "hsla(170, 61%, 48%, 1)",
    color: "primary.contrastText",
    fontSize: "0.75rem",
    fontFamily: Medium,
    padding: "4px 8px",
    left: '16px',
    top: "12px",
    borderRadius: "50px",
    zIndex: 2,
    height: "24px"
  },
  chipArabic: {
    position: "absolute",
    backgroundColor: "hsla(170, 61%, 48%, 1)",
    color: "primary.contrastText",
    fontSize: "0.75rem",
    fontFamily: Medium,
    padding: "4px 8px",
    right: '16px',
    top: "12px",
    borderRadius: "50px",
    zIndex: 2,
    height: "24px"
  },
  chipRight: {
    position: "absolute",
    backgroundColor: "#091B29",
    color: "#FFFFFF",
    fontSize: "0.75rem",
    fontFamily: Medium,
    borderRadius: "50px",
    padding: "4px 8px",
    right: '16px',
    top: "12px",
    zIndex: 2

  },
  button1: {
    backgroundColor: "#F2F4F7",
    height: "32px",
    borderRadius: "16px",
    width: "119px",
    color: "#4E5A6B",
    fontFamily: Medium,
    fontSize: "0.75rem",
    border: "none",
    "&:hover": {
      border: "none",
      color: "#1F74EA",
      backgroundColor: "#F1F7FF",
    },
  },
  button2: {
    height: "32px",
    borderRadius: "16px ",
    width: "119px",
    color: "#1F74EA",
    fontFamily: Medium,
    fontSize: "0.75rem",
    border: "1px solid #1F74EA",
  },
  cardaction: {
    justifyContent: "space-around",
    padding: "4px",
  },
  cardcontent: {
    padding: {
      xs: "8px 12px 14px 14px !important",
      sm: "8px 12px 14px 14px !important",
      lg: "8px 12px 14px 14px !important",
    },
  },
  image: {
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    height: "166px"
  },
  g1: {
    display: 'flex', gap: 1
  },
  alCenter: {
    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
  },
  logo: {
    border: '1px solid grey', borderRadius: borderRadius, width: '54px', height: '32px'
  },
  mapAreaBox: {
    height: 40
  },


  startFrom: {
    color: "#091B29",
    fontFamily: Regular,
    fontSize: "0.875rem",
    marginBottom: "5px",
  },
  startAmnt: {
    color: "#091B29",
    fontFamily: Medium,
    fontSize: "0.875rem",
  },
  startMonth: {
    color: "#4E5A6B",
    fontSize: "0.875rem",
    fontFamily: Regular
  },
  startTaxes: {
    color: "#4E5A6B",
    fontFamily: Regular,
    fontSize: "0.875rem",
    marginTop: "4px"
  },
  tickTextStyle: {
    border: "1px solid #E4E8EE",
    padding: "0px 12px",
    borderRadius: "50px",
    height: "40px"
  },
  availabletText: {
    color: "#091B29",
    fontFamily: SemiBold,
    fontSize: "0.875rem",
    marginInlineStart: "4px"
  },
  availabletBlueIcon: {
    marginTop: "4px"
  },
  textheading: {
    color: "#091B29",
    fontSize: "1rem",
    fontFamily: SemiBold,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    margiBottom: "4px"
  },
  subTextss: {
    color: "#4E5A6B",
    fontSize: "0.75rem",
    fontFamily: Medium,
    marginInlineStart: "8px"
  },
  subTextDiv: {
    margiBottom: "16px !important"
  }
};
