import * as React from "react"
const MemberCountIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="Group 110527" transform="translate(-846 -17065)">
      <circle
        cx={20}
        cy={20}
        r={20}
        fill="#f1f7ff"
        data-name="Ellipse 129925"
        transform="translate(846 17065)"
      />
      <g data-name="icons8-people (3)">
        <path
          fill="#ffa726"
          d="M859.182 17080.169a2.841 2.841 0 1 0 2.841 2.841 2.841 2.841 0 0 0-2.841-2.841Z"
          data-name="Path 100190"
        />
        <path
          fill="#455a64"
          d="M853.5 17090.794a6.405 6.405 0 0 1 5.682-3.58 6.405 6.405 0 0 1 5.682 3.58v1.875H853.5Zm25 0a6.3 6.3 0 0 0-11.364 0v1.875H878.5Z"
          data-name="Path 100191"
        />
        <path
          fill="#ffb74d"
          d="M866 17077.328a3.409 3.409 0 1 0 3.409 3.409 3.409 3.409 0 0 0-3.409-3.409Z"
          data-name="Path 100192"
        />
        <path
          fill="#607d8b"
          d="M872.818 17090.453a7.585 7.585 0 0 0-13.636 0v2.216h13.636Z"
          data-name="Path 100193"
        />
        <path
          fill="#ffa726"
          d="M872.818 17080.169a2.841 2.841 0 1 0 2.841 2.841 2.841 2.841 0 0 0-2.841-2.841Z"
          data-name="Path 100194"
        />
        <g data-name="Group 110719">
          <path
            fill="#ffa726"
            d="M859.182 17080.169a2.841 2.841 0 1 0 2.841 2.841 2.841 2.841 0 0 0-2.841-2.841Zm13.636 0a2.841 2.841 0 1 0 2.841 2.841 2.841 2.841 0 0 0-2.841-2.841Z"
            data-name="Path 100195"
          />
        </g>
      </g>
    </g>
  </svg>
)
export default MemberCountIcon
