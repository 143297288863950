import {
  Grid,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { lstingSearchStyle } from "./style";
import {
  AreaIcon,
  Building,
  Furnishing,
  HomeIcon,
  HomePlusIcon,
  RoomAndBed,
  Vacant,
  WhiteTick,
} from "../../assets";
import { CustomButton } from "../Button";
import { withTranslation } from "react-i18next";
import { CarouselSliders } from "../imageCaurosel";
import { InsideCard } from "../InsideCard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const VacantPurposeView = ({
  startIcon,
  endIcon,
  onShortlist = () => false,
  hotelname = "",
  unit = "",
  src = "",
  sqft = "",
  bed = "",
  room = "",
  bathtab = "",
  price = "",
  month = "",
  available = "",
  type = "",
  id = '',
  isSelected = false,
  floorText = "",
  onClickViewDetails = () => false,
  onClick = () => false,
  t=()=> false,
  variant = "",
  Washroom = "",
  logo = "",
  OpenSingleImageModal = () => false,
  item = {}

}) => {

  const data = [
    {
      icon: <AreaIcon />,
      name: t("Total area"),
      value: sqft,
      borderTopLeftRadius: true

    },
    {
      icon: <Building />,
      name: t("Carpet area"),
      value: `${item?.carpet_area ?? ""} ${item?.area_metric ?? ""}`,
    },
    {
      icon: <Vacant />,
      name: t("Floor"),
      value: item?.is_floor ?? "-",
      borderTopRightRadius: true
    },
    {
      icon: <RoomAndBed />,
      name: t("Room & Baths"),
      value: `${bed ?? "-"} & ${bathtab ?? "-"}`,
      borderBottomLeftRadius: true
    },
    {
      icon: <Furnishing />,
      name: t("Furnishing"),
      value: item?.furnishing ?? "-",
    },
    {
      icon: <HomeIcon />,
      name: t("Built Date"),
      value: item?.year_built ?? '-',
      borderBottomRightRadius: true
    }

  ]

  const language = localStorage.getItem("i18nextLng")

  return (
    <Box sx={lstingSearchStyle.main}>
      {/* Main Card */}
      <Grid container sx={lstingSearchStyle.inherit}>
        {/* Left Segment */}
        <Grid item xs={12} sm={12} lg={3.8} sx={lstingSearchStyle.relative}>
          <CarouselSliders data={item?.unit_assets} logo={(logo?.length > 0 || item?.unit_assets?.length) ? logo : "../images/noImage.svg"} onClick={OpenSingleImageModal} />

          < Chip label={type} sx={language === "ar" ? lstingSearchStyle.chipArabic : lstingSearchStyle.chip} />
        </Grid>
        {/* Right Segment */}
        <Grid
          item
          xs={12}
          sm={12}
          lg={8.2}
          sx={lstingSearchStyle.pl}
        >
          <Grid container height={"100%"} >
            {/* Right 1st Segment */}
            <Grid item xs={8.7} sx={lstingSearchStyle.firstHalf}>
              {/* 1 */}
              <Box sx={lstingSearchStyle.displayFlex}>
                <Box>
                  <Typography sx={lstingSearchStyle.firstHeading}>
                    {hotelname}, {room}
                  </Typography>
                </Box>
                {item?.is_community && <Box sx={lstingSearchStyle.floatRight}>
                  < Chip label={t("Community")} sx={lstingSearchStyle.chip2} />
                  {/* <LocationIcon color={"#1F74EA"} /> */}

                </Box>}
              </Box>


              {/* 2 */}

              <Stack direction={"row"} alignItems={"center"}>


                <Stack direction={"row"} alignItems={"center"}>
                  <Building />
                  <Typography sx={lstingSearchStyle.subheadingCustom}>
                    <Box as="span" sx={lstingSearchStyle.normalBlack}>
                      {item?.district}, {item?.state}
                    </Box>
                  </Typography>
                </Stack>

                <Typography sx={lstingSearchStyle.underline} onClick={() => onClick()}>
                  {t("View on Map")}
                </Typography>

              </Stack>


              <Grid container spacing={0.9} rowSpacing={0.7} maxWidth={"464px"} mb={1}>
                {data?.map((val) => {
                  return (
                    <>
                      <Grid item xs={4}>

                        <InsideCard data={val} />
                      </Grid>
                    </>
                  )
                })}
              </Grid>

            </Grid>
            <Grid item xs={0.3} />
            {/* Right 2nd Segment */}
            <Grid item xs={3} sx={lstingSearchStyle.secondHalf}>
              {/* {logo ? <Box as="img" sx={lstingSearchStyle.companyIcon} src={logo} ></Box>
              :
              null
            } */}
              <Box sx={lstingSearchStyle.alignEnd}>
                {item?.rate !== null && <>
                  <Typography sx={lstingSearchStyle.normalBlackWithRegular}>
                    {t("Starts From")}
                  </Typography>
                  <Typography>
                    {`${item?.currency_symbol ?? ""} ${price ?? ""}`}
                    <Box as="span" sx={lstingSearchStyle.subheading}>
                      {" "}
                      / {month}
                    </Box>
                  </Typography>
                  <Typography sx={lstingSearchStyle.subheading}>
                    + {t("taxes & charges")}
                  </Typography>
                </>}

                {item?.offer && (
                  <Typography sx={lstingSearchStyle.red}>
                    {t("Upto")} {item?.offer} {t("offer")}
                  </Typography>
                )}
              </Box>
              <Box sx={lstingSearchStyle.buttonbox}>
                {isSelected ? <CustomButton
                  variant="contained"
                  size="medium"
                  label={t("Shortlisted")}
                  rootStyle={lstingSearchStyle.selectedbutton}
                  onClick={() => onShortlist({
                    hotelname,
                    unit,
                    src,
                    sqft,
                    bed,
                    room,
                    bathtab,
                    price,
                    month,
                    available,
                    type,
                    id,
                    isSelected,
                    logo
                  })}
                  startIcon={<WhiteTick rootStyle={lstingSearchStyle.whiteTick} />}
                  color='primary'
                  endIcon={endIcon}
                ></CustomButton> : <CustomButton
                  variant="outlined"
                  size="medium"
                  label={t("Shortlist")}
                  rootStyle={lstingSearchStyle.mainbutton}
                  onClick={() => onShortlist({
                    hotelname,
                    unit,
                    src,
                    sqft,
                    bed,
                    room,
                    bathtab,
                    price,
                    month,
                    available,
                    type,
                    id,
                    isSelected,
                    logo
                  })}
                  startIcon={<HomePlusIcon />}
                  endIcon={endIcon}
                ></CustomButton>}

                <CustomButton
                  variant="outlined"
                  size="medium"
                  label={t("View Details")}
                  rootStyle={lstingSearchStyle.subbutton}
                  onClick={onClickViewDetails}
                  startIcon={startIcon}
                  endIcon={language === "ar" ? <NavigateBeforeIcon/> : <NavigateNextIcon />}
                ></CustomButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withTranslation("vacantCards")(VacantPurposeView)