import * as React from "react"

export const Floor = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props}>
    <g data-name="Group 112086">
      <path data-name="Rectangle 57191" fill="none" d="M0 0h25v25H0z" />
      <path
        data-name="icons8-building (1)"
        d="M7.75 2.5A2.761 2.761 0 0 0 5 5.25v16a1.261 1.261 0 0 0 1.25 1.25h4a1.261 1.261 0 0 0 1.25-1.25v-2a.239.239 0 0 1 .25-.25h1.5a.239.239 0 0 1 .25.25v2a1.261 1.261 0 0 0 1.25 1.25h4A1.261 1.261 0 0 0 20 21.25v-16a2.761 2.761 0 0 0-2.75-2.75Zm0 1.5h9.5a1.239 1.239 0 0 1 1.25 1.25V21H15v-1.75a1.762 1.762 0 0 0-1.75-1.75h-1.5A1.762 1.762 0 0 0 10 19.25V21H6.5V5.25A1.239 1.239 0 0 1 7.75 4Zm1 1.5a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm-7 3a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm-7 3a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm-7 3a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Zm3.5 0a.75.75 0 1 0 0 1.5h.5a.75.75 0 1 0 0-1.5Z"
        fill="#4e5a6b"
      />
    </g>
  </svg>
)

 