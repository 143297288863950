import * as React from "react"

export const AreaIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="SQ FT"
    width={20.985}
    height={18.306}
    {...props}
  >
    <path
      data-name="Path 97264"
      d="M1.634 9.153h1.883v2.5h2.988l.656 2.621 3.193.572.434 2.155H1.634Z"
      fill="#ced3dd"
      opacity={0.3}
    />
    <path
      data-name="Path 97265"
      d="M19.645 1.308h-1.883v2.5h-3.643v2.62l-3.193.57-.434 2.155h9.153Z"
      fill="#ced3dd"
      opacity={0.3}
    />
    <path
      d="M.794 0A.807.807 0 0 0 0 .822v16.139a1.357 1.357 0 0 0 1.345 1.345h8.609a1.357 1.357 0 0 0 1.346-1.345v-2.152a1.357 1.357 0 0 0-1.345-1.345H8.071v-1.342a1.357 1.357 0 0 0-1.345-1.345H4.843V9.428A1.357 1.357 0 0 0 3.5 8.083H1.614V.822a.807.807 0 0 0-.82-.819Zm3.241.012a.807.807 0 1 0 .807.807.807.807 0 0 0-.806-.807Zm3.228 0a.807.807 0 1 0 .807.807.807.807 0 0 0-.806-.807Zm3.228 0a.807.807 0 1 0 .809.81.807.807 0 0 0-.808-.81Zm3.228 0a.807.807 0 1 0 .807.807.807.807 0 0 0-.805-.807Zm3.767 0a1.357 1.357 0 0 0-1.345 1.345v1.345h-1.882a1.357 1.357 0 0 0-1.345 1.345v1.345h-1.883a1.357 1.357 0 0 0-1.345 1.345v2.152a1.357 1.357 0 0 0 1.345 1.345h8.34v7.264a.807.807 0 1 0 1.614 0v-8.07a.807.807 0 0 0-.035-.246 1.3 1.3 0 0 0 .035-.292V1.357A1.357 1.357 0 0 0 19.64.012Zm.27 1.61h1.614v7H11.3V7.007h2.421a.807.807 0 0 0 .807-.807V4.317h2.421a.807.807 0 0 0 .807-.807ZM1.614 9.697h1.614v1.883a.807.807 0 0 0 .807.807h2.421v1.883a.807.807 0 0 0 .807.807h2.421v1.615h-8.07Zm12.107 6.995a.807.807 0 1 0 .807.807.807.807 0 0 0-.807-.807Zm3.228 0a.807.807 0 1 0 .807.807.807.807 0 0 0-.807-.807Z"
      fill="#4e5a6b"
    />
  </svg>
)

