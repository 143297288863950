import { SvgIcon } from "@mui/material"
import * as React from "react"

export const FlagIcon = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 20,
      height: 25,
      color:"#98a0ac",
      ...rootStyle,
    };
    return(
      <SvgIcon
    data-name="Component 77 \u2013 5"
    xmlns="http://www.w3.org/2000/svg"
    sx={rootSx}
    {...rest}
  >
    <rect
      data-name="Rectangle 57046"
      width={32}
      height={32}
      rx={10}
      fill="none"
    />
    <path
      data-name="icons8-map (3)"
      d="m22.257 7.621-3.288 1.315-5.305-1.768a2 2 0 0 0-1.375.04L8.257 8.821A2 2 0 0 0 7 10.678v11.845a2 2 0 0 0 2.743 1.857l3.289-1.315 5.305 1.771a2 2 0 0 0 1.375-.04l4.031-1.613A2 2 0 0 0 25 21.323V9.478a2 2 0 0 0-2.743-1.857ZM19 21.536a1 1 0 0 1-1.313.95l-4-1.318a1 1 0 0 1-.687-.945V10.46a1 1 0 0 1 1.313-.95l4 1.318a1 1 0 0 1 .687.95Z"
      fill="currentColor"
    />
  </SvgIcon>
    )
 
}

