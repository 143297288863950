import * as React from "react";
export const GreenTick  = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      id="Group_110511"
      data-name="Group 110511"
      transform="translate(-660 -305)"
    >
      <circle
        id="Ellipse_129668"
        data-name="Ellipse 129668"
        cx={8}
        cy={8}
        r={8}
        transform="translate(660 305)"
        fill="#5ac782"
      />
      <path
        id="Path_100111"
        data-name="Path 100111"
        d="M4511.912,5300.332l3.157,3.406,6.2-5.862"
        transform="matrix(0.996, -0.087, 0.087, 0.996, -4292.983, -4574.163)"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
      />
    </g>
  </svg>
);
