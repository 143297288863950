import { borderRadius, borderRadiusAlignments, remCalc } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const popoverRangeStyle = {
  main: {
    width: '100%'
  },
  toggle: {
    padding: 0,
    width: "46px",
    height: '40px',
    borderRadius: borderRadius
  },
  toggleSelected: {
    padding: 0,
    width: "46px",
    height: '40px',
    borderRadius: borderRadius,
    border: '1px solid #1F74EA',
    color: '#1F74EA'
  },
  duratonToggle: {
    padding: 0.75,
    height: "38px",
    width: 'fit-content',
    borderRadius: borderRadius
  },
  duratonToggleSelected: {
    padding: 0.75,
    height: "38px",
    width: 'fit-content',
    borderRadius: borderRadius,
    border: '1px solid #1F74EA',
    color: '#1F74EA'
  },
  toggleContainer: {
    padding: "6px 16px 16px 16px",
    gap: 2,
    borderRadius: "16px !important"
  },
  toggleHeader: {
    // ml: '10px',
    fontFamily: Regular,
    fontSize: remCalc(14),
    color: "#4E5A6B"
  },
  texField: {
    height: "38px !important",
    [`& fieldset`]: {
      borderRadius: borderRadius,
      height: (props) => (props.multiline ? "unset" : props?.height ?? 44),
      border: (props) => props?.border ?? "1px solid hsl(0, 0%, 80%)",
    },
    "& .MuiOutlinedInput-input": {
      borderRadius: borderRadius,
      backgroundColor: "#fff",
      paddingLeft: 1
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'hsl(0, 0%, 80%)'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'primary.main',
      },
    }
  },
  placeholder: {
    fontSize: remCalc(14),
    whiteSpace: "nowrap",
    color: "#98A0AC",
  },
  div: {
    width: "305px",
    padding: "6px",
  },
  title: {
    fontSize: remCalc(12),
    color: "#4E5A6B",
    marginBottom: "8px",
  },
  value: {
    fontSize: remCalc(14),
    color: "#091B29",
  },
  type: {
    fontSize: remCalc(12),
    color: "#4E5A6B",
    textAlign: "center",
    cursor: "pointer",
    margin: "0px 8px",
  },
  input: {
    width: "80px",
    height: "30px",
    border: "1px solid #c1c1c1",
  },
  select: {
    width: "80px",
    height: "30px",
    border: "1px solid #F5F7FA",
    marginLeft: "12px",
    backgroundColor: "#F5F7FA",
    color: "#091B29",
    fontSize: remCalc(12),
    outline: "none",
    padding: "2px",
  },
  dialog: {
    borderRadius: borderRadiusAlignments.borderEight,
    "& .MuiPopover-paper": {
      marginTop: "12px",
      // borderRadius: borderRadius,
      // borderRadius: borderRadiusAlignments?.borderTwelve,
      boxShadow: '0px 2px 12px #00000014',
      // height: "226px",
      borderRadius: borderRadiusAlignments.borderEight,

    },
  },
  duration: {
    fontSize: remCalc(14),
    fontFamily: Regular,
    color: "text.secondary",
  },
  subtile: {
    fontSize: remCalc(12),
    fontFamily: Regular,
    color: "text.secondary",
  },
  maxWidth: {
    width: '100%',
    height: "40px"
  },
  mt2: {
    mt: 2
  },
  gap: {
    gap: 0.75,
    p: 1,
    display: "flex",
    alignItems: "center",

  },
  gap1: {
    gap: 0.75,
    p: 1,
    display: "flex",
    alignItems: "center",
    height: "300px",
    width: "400px"
  },
  pointer: {
    cursor: "pointer"
  },
  textFieldBox: {
    display: "flex",
    alignItems: "center",
    height: "45px",
    cursor: 'pointer'
  },
  textFieldBox1: {
    flexGrow: 1
  },
  sqft: {
    lineHeight: 1.643,
    color: 'text.secondary'
  },
  member: {
    display: 'flex', flexDirection: 'row', alignItems: 'center'
  },
  memberBox: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    mb: 1,
    maxHeight: '150px',
    height: "auto",
    Overflow: "auto",
    borderRadius: borderRadiusAlignments.borderSixteen
  },
  memberBoxDialog: {
    padding: "12px"
  },
  NumberInput: {
    "& input": {
      maxWidth: 85,
      py: "4.5px",
    },
    " & .MuiOutlinedInput-notchedOutline": {
      borderColor: "#E4E8EE",
      borderRadius: "6px",
      border: '1px solid #E4E8EE !important'
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 4px"
    }
  },
  formlabel: {
    "& .MuiPaper-elevation MuiPaper-rounded": {
      borderRadius: borderRadiusAlignments?.borderTwelve
    },
    "& .MuiFormControlLabel-root .MuiFormControlLabel-label": {
      fontSize: remCalc(14),
      fontFamily: Medium,
      color: '#4E5A6B'
    },
  },
  labelText: {
    fontSize: (props) => (props?.fontSize ? props?.fontSize : remCalc(14)),
    fontFamily: (props) => (props?.fontFamily ? props?.fontFamily : Medium),
    color: (props) => (props?.color ? props?.color : "primary.light1"),
  },
  menulist: {

    height: "20px",
    Overflow: "auto"
  },
  noDataFoundText: {
    color: "text.secondary",
    fontSize: remCalc(14),
    mt: "8px",
    mb: "-8px",
    fontFamily: SemiBold,
    textAlign: "center"
  },
  sizeBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px"
  }
}