import React from "react";
import { Box, Container, Hidden } from "@mui/material";
import { NavbarStyle } from "./style";
import { TopNavBar } from '../components';
import { useGetAllAssetsMutation } from "../redux/services";
import { LocalStorageKeys } from "../utils";
import { config } from "../config";


const withNavBars = (Component, params) => (props) => {
  const [assetApiCall] = useGetAllAssetsMutation()
  const client = localStorage.getItem(LocalStorageKeys.client)
  const [data, setData] = React.useState()
  const hostName = window.location.origin;

  const getAssetsAndLinks = (pathName) => {
    const payload = {
      client_url: pathName
    }
    assetApiCall(payload)
      .then((response) => {
        setData(response?.data?.response)
      }).catch((error) => {
        console.log(error);
      })

  }
  let host_arr = [config?.listings_url , config?.realitygoto_url ,config?.amenitiesgoto_url]
  React.useEffect(() => {
    if (client?.length > 0 || !(host_arr?.includes(hostName)) ) {
      getAssetsAndLinks(client)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Box sx={NavbarStyle.root} >
      {/* Your nav bars here */}
      <Container sx={{ maxWidth: "1920px !important", padding: "0px !important" }}>
        <Box sx={NavbarStyle.topNavbar}>
          <Hidden smDown>
            <TopNavBar {...params} tab={params?.key} assetsAndLinks={data} />
          </Hidden>
          {
            window.location.pathname === '/residentview' || window.location.pathname === '/commercialview' ?
              < Hidden smDown>
                <TopNavBar {...params} tab={params?.key} assetsAndLinks={data} />
              </Hidden>
              :
              <TopNavBar {...params} tab={params?.key} assetsAndLinks={data} />

          }

        </Box>

        {/* Content */}
        <Box sx={window.location.pathname === '/residentview' || window.location.pathname === '/commercialview' ? NavbarStyle.resContent : NavbarStyle.content} >
          <Component {...params} assetsAndLinks={data}>{props.children}</Component>
        </Box>


      </Container >
    </Box >
  );
};

export default withNavBars;
