import { SvgIcon } from "@mui/material"
import * as React from "react"

export const Building = (props) => {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 22,
      height: 22,
      color:"#98a0ac",
      ...rootStyle,
    };
    return(
<SvgIcon
    data-name="Group 96783"
    xmlns="http://www.w3.org/2000/svg"
    sx={rootSx}
    {...rest}
  >
    <path data-name="Rectangle 51173" fill="none" d="M0 0h24v24H0z" />
    <path
      data-name="icons8-city (3)"
      d="M9.264 4.001a2 2 0 0 0-2.064 2v7.2H5.8a2.206 2.206 0 0 0-2.2 2.2v4a.6.6 0 0 0 .6.6h15.6a.6.6 0 0 0 .6-.6v-6.8a2.206 2.206 0 0 0-2.2-2.2h-1.8v-3a2.005 2.005 0 0 0-1.48-1.931l-5.2-1.4a2 2 0 0 0-.456-.069Zm-.037 1.2a.775.775 0 0 1 .18.027l5.2 1.4a.8.8 0 0 1 .593.773v11.4h-4v-3.4a2.206 2.206 0 0 0-2.2-2.2h-.6v-7.2a.8.8 0 0 1 .827-.8Zm.773 2.4a.4.4 0 0 0-.4.4v.8a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .4-.4v-.8a.4.4 0 0 0-.4-.4Zm2.8 0a.4.4 0 0 0-.4.4v.8a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .4-.4v-.8a.4.4 0 0 0-.4-.4Zm-2.8 2.8a.4.4 0 0 0-.4.4v.8a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .4-.4v-.8a.4.4 0 0 0-.4-.4Zm2.8 0a.4.4 0 0 0-.4.4v.8a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .4-.4v-.8a.4.4 0 0 0-.4-.4Zm0 2.8a.4.4 0 0 0-.4.4v.8a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .4-.4v-.8a.4.4 0 0 0-.4-.4Zm4.8 0h.8a.4.4 0 0 1 .4.4v.8a.4.4 0 0 1-.4.4h-.8a.4.4 0 0 1-.4-.4v-.8a.4.4 0 0 1 .4-.4Zm-12 2.8h.8a.4.4 0 0 1 .4.4v.8a.4.4 0 0 1-.4.4h-.8a.4.4 0 0 1-.4-.4v-.8a.4.4 0 0 1 .4-.4Zm2.8 0h.8a.4.4 0 0 1 .4.4v.8a.4.4 0 0 1-.4.4h-.8a.4.4 0 0 1-.4-.4v-.8a.4.4 0 0 1 .4-.4Zm4.4 0a.4.4 0 0 0-.4.4v.8a.4.4 0 0 0 .4.4h.8a.4.4 0 0 0 .4-.4v-.8a.4.4 0 0 0-.4-.4Zm4.8 0h.8a.4.4 0 0 1 .4.4v.8a.4.4 0 0 1-.4.4h-.8a.4.4 0 0 1-.4-.4v-.8a.4.4 0 0 1 .4-.4Z"
      fill="currentColor"
    />
  </SvgIcon>
    )
  
}
