import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
// import { config } from "../../../config";
import { LocalStorageKeys } from "../../../utils";

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: "https://api.mapbox.com/geocoding/v5/mapbox.places/",
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = localStorage.getItem(LocalStorageKeys.authToken);
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });

export const locationsAPI = createApi({
  reducerPath: "locationsAPI",
  baseQuery: baseQueryWithRetry,
  tagTypes: ["Location"],
  endpoints: (builder) => ({

    locationSearch: builder.mutation({
      query: (payload) => ({
        url: payload?.countryCode !== "All" ? `${payload?.search}.json?access_token=pk.eyJ1IjoicHJvcGdvdG9tYXBzIiwiYSI6ImNsY214ZmtybzA5eXgzeHFwZmN2MDhjNmMifQ.YV5PBXEeIk9CJmtWRKA4vw&country=${payload?.countryCode}&limit=10` :
          `${payload?.search}.json?access_token=pk.eyJ1IjoicHJvcGdvdG9tYXBzIiwiYSI6ImNsY214ZmtybzA5eXgzeHFwZmN2MDhjNmMifQ.YV5PBXEeIk9CJmtWRKA4vw&limit=10`
        ,
        method: "GET",
      })
    }),

  }),
});

// Export hooks for usage in functional components
export const {
  useLocationSearchMutation,
} = locationsAPI;
