import { Card, CardContent, Divider, Typography, Stack, Box, Hidden, Avatar, Grid } from "@mui/material";
import React from "react";
import { CustomButton } from "../Button";
import { facilityBillStyle } from "./style";
import { withTranslation } from 'react-i18next';
import { PeakHourIcon } from "../../assets";
import moment from "moment";
import { getTimeWithAmPm } from "../../utils/common";
import { LocalStorageKeys, borderRadius, currencyCalculation } from "../../utils";
import { AuthContext } from "../../contexts";
import { TextBox } from "../textbox";
import MemberCountIcon from "../../assets/components/memberCountIcon";
// import { AuthContext } from "../../contexts";


const FacilitiesBillCard = ({
    hourRate = '',
    courtId = '',
    bookingDate = '',
    bookingSlot = [],
    isDialog = false,
    handleDialog = () => false,
    t = () => false,
    symbol = "",
    amountDetail = {},
    data = {},
    currency_symbol = "",
    validateCount = () => false,
    pcCount = "",
    setpcCount,
    dialogParticipantCount = false,
    overBookingCalc = () => false,
    error = ""

}, props) => {


    const auth = React.useContext(AuthContext);
    const memberCount = pcCount?.length > 0 ? pcCount : 1




    return (
        <>
            <Hidden smDown>
                <Card sx={facilityBillStyle.main}>
                    <CardContent sx={facilityBillStyle.fc1}>
                        {/* 1 */}
                        {/* <Box sx={facilityBillStyle.sb}>
                            <Box sx={facilityBillStyle.ac}>
                                <Avatar src={data?.viewFacilityBookingAmenity?.amenity_assets?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"} sx={facilityBillStyle.image} />
                                <Box >
                                    <Typography>{data?.viewFacilityBookingAmenity?.description ?? data?.viewFacilityBookingAmenity?.amenities_name}</Typography>
                                    <Typography sx={facilityBillStyle.smallFontText} noWrap>{data?.viewFacilityBookingAmenity?.property_name}</Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={facilityBillStyle?.hourRate}>
                                    <Typography sx={facilityBillStyle.hourFont}>{`${currency_symbol ?? symbol}${currencyCalculation(hourRate ?? 0, auth?.facilityToCurrency ?? 0)}`}</Typography>
                                    < Typography sx={facilityBillStyle.smallFontPerHr}>{t("Per Hr")}</Typography>
                                </Box>
                                
                            </Box>


                        </Box> */}

                        <Grid container spacing={1}>
                            <Grid container item xs={9} spacing={1}>
                                <Grid item xs={2} sm={2.5} md={2.5} lg={2.5} xl={2}>
                                    <Avatar src={data?.viewFacilityBookingAmenity?.amenity_assets?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"} sx={facilityBillStyle.image} />
                                </Grid>
                                <Grid item xs={10} sm={9.5} md={9.5} lg={9.5} xl={10}>
                                    <Box >
                                        <Typography noWrap>{data?.viewFacilityBookingAmenity?.description ?? data?.viewFacilityBookingAmenity?.amenities_name}</Typography>
                                        <Typography sx={facilityBillStyle.smallFontText} noWrap>{data?.viewFacilityBookingAmenity?.property_name}</Typography>
                                    </Box>

                                </Grid>

                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={facilityBillStyle?.hourRate}>
                                    <Typography sx={facilityBillStyle.hourFont}>{`${currency_symbol ?? symbol}${currencyCalculation(hourRate ?? 0, auth?.facilityToCurrency ?? 0)}`}</Typography>
                                    < Typography sx={facilityBillStyle.smallFontPerHr}>{t("Per Hr")}</Typography>
                                </Box>
                                {/* {data?.viewFacilityBookingAmenity?.slots_info?.[1]?.slots?.length > 0 &&
                                    <Typography sx={facilityBillStyle.smallFont1}>
                                        <BoltIcon /> {t("Peak Hour")} {data?.viewFacilityBookingAmenity?.symbol} {data?.viewFacilityBookingAmenity?.slots_info?.[1]?.rate} / Hr
                                    </Typography>
                                } */}

                            </Grid>

                        </Grid>
                        <Divider sx={facilityBillStyle.divider} />
                        {data?.viewFacilityBookingAmenity?.is_overbooking && !dialogParticipantCount &&
                            <>
                                <Box sx={facilityBillStyle.overbookcountBox}>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} alignItems={"center"} spacing={1} width={"100%"}>
                                            <Stack>
                                                <MemberCountIcon />
                                            </Stack>
                                            <Typography sx={facilityBillStyle.participantCountText}>{t("Enter participant member count")}</Typography>
                                        </Stack>
                                        <Stack sx={facilityBillStyle.textBoxOverbooking}>
                                            <TextBox
                                                value={pcCount}
                                                type={"number"}
                                                borderColor={"#78B1FE"}
                                                onChange={(e) => overBookingCalc(e?.target?.value)}
                                                placeholder="00"
                                                fieldStyle={{
                                                    "& .MuiOutlinedInput-input": {
                                                        backgroundColor: "#5078E10D",
                                                        height: "33px !important",
                                                        borderRadius: borderRadius,
                                                        width: "100%",
                                                        border: "1px solid",
                                                        borderColor: "primary.main"

                                                    },
                                                    [`& fieldset`]: {
                                                        border: "none",
                                                    },
                                                }}
                                                isSearch
                                            />
                                        </Stack>
                                    </Stack>
                                    {error && <Typography sx={facilityBillStyle.errorText}>{error}</Typography>}

                                </Box>
                                <Divider sx={facilityBillStyle.divider} />
                            </>
                        }
                        {/* {data?.viewFacilityBookingAmenity?.is_overbooking && dialogParticipantCount &&
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography>{t("No Of Members")}</Typography>
                                <Typography>{pcCount}</Typography>

                            </Stack>
                        } */}


                        {/* 2 */}
                        <Box sx={facilityBillStyle.sb1}>

                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={facilityBillStyle.bookings}>
                                <Typography sx={facilityBillStyle.smallFont}>{t("Court ID")}</Typography>
                                <Typography sx={facilityBillStyle.rightText}>{courtId}</Typography>
                            </Stack>
                            {data?.viewFacilityBookingAmenity?.is_overbooking && dialogParticipantCount &&
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={facilityBillStyle.bookings}>
                                    <Typography sx={facilityBillStyle.smallFont}>{t("No Of Members")}</Typography>
                                    <Typography sx={facilityBillStyle.rightText}>{pcCount}</Typography>
                                </Stack>
                            }
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={facilityBillStyle.bookings}>
                                <Typography sx={facilityBillStyle.smallFont}>{t("Booking Date")}</Typography>
                                <Typography sx={facilityBillStyle.rightText}>&#x202a;{moment(bookingDate).format("DD MMM YY")}&#x202c;, &#x202a;{moment(bookingDate).format("dddd")}&#x202c;</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography sx={facilityBillStyle.smallFont}>{t("Booking Slot")}</Typography>
                                <Stack direction={"column"}>
                                    {bookingSlot?.map((item, index) => {
                                        return (
                                            <Typography sx={facilityBillStyle.rightText}>
                                                &#x202a;{`${data?.viewFacilityBookingAmenity?.period === "Hourly" ? `${getTimeWithAmPm(item?.value?.check_in_time)} - ${getTimeWithAmPm(item?.value?.check_out_time)}` : `${moment(item?.value?.check_in_date?.date).format("DD MMM YY")}`}`}&#x202c;
                                            </Typography>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                        </Box>
                        <Divider sx={facilityBillStyle.fullDivider} />
                        {/* 3 */}
                        <Box >
                            <Typography sx={facilityBillStyle.bold}>{t("Bill Summary")}</Typography>
                            <Box sx={facilityBillStyle.s}>
                                <Box sx={facilityBillStyle.f}>
                                    {bookingSlot?.map((item, index) => {
                                        return (
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={facilityBillStyle.smallFontTime}>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                    <Typography sx={facilityBillStyle.smallFont}>{t("Slot")} &#x202a;{`${data?.viewFacilityBookingAmenity?.period === "Hourly" ?
                                                        `${getTimeWithAmPm(item?.value?.check_in_time)} - ${getTimeWithAmPm(item?.value?.check_out_time)}` :
                                                        `${moment(item?.value?.check_in_date?.date).format("DD MMM YY")} - ${moment(item?.value?.check_out_date?.date).format("DD MMM YY")}`}`}&#x202c;
                                                    </Typography>
                                                    {item?.value?.slot_detail?.is_peak === true && <PeakHourIcon />}
                                                </Stack>

                                                <Typography sx={facilityBillStyle.rightText}>{`${data?.viewFacilityBookingAmenity?.period === "Hourly" ? `${currency_symbol ?? symbol}${currencyCalculation((item?.value?.slot_detail?.rate * memberCount), auth?.facilityToCurrency)}` : `${currency_symbol ?? symbol}${currencyCalculation(data?.viewFacilityBookingAmenity?.rate ?? 0, auth?.facilityToCurrency ?? 0)}`}`}</Typography>

                                            </Stack>
                                        )
                                    })}
                                </Box>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography sx={facilityBillStyle.smallFont}>{t("Taxes")}</Typography>
                                    <Typography sx={facilityBillStyle.rightText}>{`${currency_symbol ?? symbol} ${currencyCalculation((amountDetail?.taxAmount ?? 0 * memberCount), auth?.facilityToCurrency ?? 0)}`}</Typography>


                                </Stack>
                            </Box>
                        </Box>
                        <Divider sx={facilityBillStyle.divider} />
                        {/* 4 */}

                        <Box sx={facilityBillStyle.sb}>
                            <Typography sx={facilityBillStyle.bold}>{t("Total Amount To Pay")}</Typography>
                            <Typography sx={facilityBillStyle.rightText}>{`${currency_symbol ?? symbol}${currencyCalculation((amountDetail?.totalAmount ?? 0), auth?.facilityToCurrency ?? 0)}`}</Typography>
                        </Box>
                        {/* <Divider sx={facilityBillStyle.divider} /> */}

                        {isDialog ? null : <Divider sx={facilityBillStyle.divider} />}

                        {isDialog ? null : <CustomButton
                            label={t("Book Facility Now")}
                            color="primary"
                            variant="contained"
                            onClick={handleDialog}
                        // disabled={data?.viewFacilityBookingAmenity?.is_overbooking && pcCount?.length === 0}

                        />
                        }
                    </CardContent>
                </Card>
            </Hidden >
            <Hidden smUp>
                {localStorage.getItem(LocalStorageKeys?.authToken) &&
                    <Stack sx={facilityBillStyle.db}>
                        <Avatar
                            alt={localStorage.getItem(LocalStorageKeys?.username)}
                            src="/broken-image.jpg"
                        ></Avatar>
                        <Stack sx={facilityBillStyle.textBox}>
                            <Typography sx={facilityBillStyle.headtext}>{data?.user_profile?.name ?? data?.user_profile?.name}</Typography>
                            <Typography sx={facilityBillStyle.smallFont}>{data?.user_profile?.mobile_no_country_code ?? data?.user_profile?.mobile_no_country_code}{data?.user_profile?.mobile_no ?? data?.user_profile?.mobile_no} {data?.user_profile?.mobile_no_country_code && data?.user_profile?.mobile_no ? "," : ""}{data?.user_profile?.email ?? data?.user_profile?.email}</Typography>
                        </Stack>
                    </Stack>
                }
                <Card sx={facilityBillStyle.main1}>
                    <CardContent sx={facilityBillStyle.fc}>
                        {/* 1 */}
                        {/* <Box sx={facilityBillStyle.sb}>
                            <Box sx={facilityBillStyle.ac}>
                                <Avatar src={data?.viewFacilityBookingAmenity?.amenity_assets?.[0]?.url <FacilityB
                                    <Typography sx={facilityBillStyle.hourFont}>{`${currency_symbol ?? symbol}${currencyCalculation(hourRate ?? 0, auth?.facilityToCurrency ?? 0)}`}</Typography>
                                    <Typography sx={facilityBillStyle.smallFont}>{t("Per Hr")}</Typography>
                                </Box>
                                
                            </Box>

                        </Box> */}
                        <Grid container spacing={1}>
                            <Grid container item xs={9} spacing={1}>
                                <Grid item xs={2.5} sm={2.5} md={1} lg={2.5} xl={2}>
                                    <Avatar src={data?.viewFacilityBookingAmenity?.amenity_assets?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"} sx={facilityBillStyle.image} />
                                </Grid>
                                <Grid item xs={9.5} sm={9.5} md={11} lg={9.5} xl={10}>
                                    <Box >

                                        <Typography noWrap sx={facilityBillStyle.headtext}>{data?.viewFacilityBookingAmenity?.description ?? data?.viewFacilityBookingAmenity?.amenities_name}</Typography>
                                        <Typography sx={facilityBillStyle.smallFont} noWrap>{data?.viewFacilityBookingAmenity?.property_name}</Typography>
                                    </Box>

                                </Grid>

                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={facilityBillStyle?.hourRate}>
                                    <Typography sx={facilityBillStyle.hourFont}>{`${currency_symbol ?? symbol}${currencyCalculation(hourRate ?? 0, auth?.facilityToCurrency ?? 0)}`}</Typography>
                                    <Typography sx={facilityBillStyle.smallFont}>{t("Per Hr")}</Typography>
                                </Box>
                                {/* {data?.viewFacilityBookingAmenity?.slots_info?.[1]?.slots?.length > 0 &&
                                    <Typography sx={facilityBillStyle.smallFont1}>
                                        <BoltIcon /> {t("Peak Hour")} {data?.viewFacilityBookingAmenity?.symbol} {data?.viewFacilityBookingAmenity?.slots_info?.[1]?.rate} / {t("Hr")}
                                    </Typography>
                                } */}

                            </Grid>

                        </Grid>

                        <Divider sx={facilityBillStyle.divider} />
                        {/* {data?.viewFacilityBookingAmenity?.is_overbooking &&
                            <>
                                <Box>
                                    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Stack direction={"row"} alignItems={"center"} spacing={1} width={"100%"}>
                                            <Stack>
                                                <MemberCountIcon />
                                            </Stack>
                                            <Typography>
                                                {t("Enter participant member count")}
                                            </Typography>
                                        </Stack>
                                        <Stack sx={facilityBillStyle.textBoxOverbooking}>
                                            <TextBox
                                                value={pcCount}
                                                type={"number"}
                                                borderColor={"#78B1FE"}
                                                onChange={(e) => (e?.target?.value === "" || e?.target?.value > 0) && setpcCount(e?.target?.value)}

                                            />
                                        </Stack>
                                    </Stack>

                                    <Box sx={facilityBillStyle.continueBtnBox}>
                                        <CustomButton
                                            label={t("Continue")}
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            rootStyle={{ width: "100%!important" }}
                                            onClick={() => validateCount()}
                                            disabled={pcCount?.length === 0}
                                            sx={facilityBillStyle.continueBtn}
                                        />
                                    </Box>

                                </Box>
                                <Divider sx={facilityBillStyle.divider} />
                            </>
                        } */}
                        {/* 2 */}
                        <Box sx={facilityBillStyle.sb}>
                            <Box sx={facilityBillStyle.fc}>
                                <Typography sx={facilityBillStyle.smallFont}>{t("Court ID")}</Typography>
                                {data?.viewFacilityBookingAmenity?.is_overbooking && dialogParticipantCount && <Typography sx={facilityBillStyle.smallFont}>{t("No Of Members")}</Typography>}
                                <Typography sx={facilityBillStyle.smallFont}>{t("Booking Date")}</Typography>
                                <Typography sx={facilityBillStyle.smallFont}>{t("Booking Slot")}</Typography>
                            </Box>
                            <Box sx={facilityBillStyle.fc}>
                                <Typography sx={facilityBillStyle.rightText}>{courtId}</Typography>
                                {data?.viewFacilityBookingAmenity?.is_overbooking && dialogParticipantCount && <Typography sx={facilityBillStyle.rightText}>{pcCount}</Typography>}
                                <Typography sx={facilityBillStyle.rightText}>&#x202a;{moment(bookingDate).format("DD-MMM-YY")}&#x202c;, &#x202a;{moment(bookingDate).format("dddd")}&#x202c;</Typography>

                                {bookingSlot?.map((item, index) => {
                                    return (
                                        <Typography sx={facilityBillStyle.rightText}>
                                            {/* {`${getTimeWithAmPm(item?.value?.check_in_time)} - ${getTimeWithAmPm(item?.value?.check_out_time)}`} */}
                                            &#x202a;{`${data?.viewFacilityBookingAmenity?.period === "Hourly" ? `${getTimeWithAmPm(item?.value?.check_in_time)} - ${getTimeWithAmPm(item?.value?.check_out_time)}` : `${moment(item?.value?.check_in_date?.date).format("DD MMM YY")} - ${moment(item?.value?.check_out_date?.date).format("DD MMM YY")}`}`}&#x202c;
                                        </Typography>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Divider sx={facilityBillStyle.fullDivider} />
                        {/* 3 */}
                        <Box >
                            <Typography sx={facilityBillStyle.bold}>{t("Bill Summary")}</Typography>
                            <Box sx={facilityBillStyle.s}>
                                <Box sx={facilityBillStyle.f}>
                                    {bookingSlot?.map((item, index) => {
                                        return (
                                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={facilityBillStyle?.smallFontTime}>
                                                <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                                    <Typography sx={facilityBillStyle.smallFont}>{t("Slot")} &#x202a;{`${data?.viewFacilityBookingAmenity?.period === "Hourly" ? `${getTimeWithAmPm(item?.value?.check_in_time)} - ${getTimeWithAmPm(item?.value?.check_out_time)}` : `${moment(item?.value?.check_in_date?.date).format("DD MMM YY")} - ${moment(item?.value?.check_out_date?.date).format("DD MMM YY")}`}`}&#x202c;
                                                    </Typography>
                                                    {item?.value?.slot_detail?.is_peak === true && <PeakHourIcon />}
                                                </Stack>
                                                <Typography sx={facilityBillStyle.rightText2}>{`${data?.viewFacilityBookingAmenity?.period === "Hourly" ? `${currency_symbol ?? symbol}${currencyCalculation((item?.value?.slot_detail?.rate * memberCount), auth?.facilityToCurrency ?? 0)}` : `${currency_symbol ?? symbol}${currencyCalculation(data?.viewFacilityBookingAmenity?.rate ?? 0, auth?.facilityToCurrency ?? 0)}`}`}</Typography>
                                            </Stack>

                                        )
                                    })}
                                </Box>
                                <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Typography sx={facilityBillStyle.smallFont}>{t("Taxes")}</Typography>
                                    <Typography sx={facilityBillStyle.rightText2}>{`${currency_symbol ?? symbol}${currencyCalculation(amountDetail?.taxAmount ?? 0, auth?.facilityToCurrency ?? 0)}`}</Typography>
                                </Stack>
                            </Box>
                        </Box>
                        <Divider sx={facilityBillStyle.divider} />
                        {/* 4 */}
                        <Box sx={facilityBillStyle.sb}>
                            <Typography sx={facilityBillStyle.bold1}>{t("Total Amount To Pay")}</Typography>
                            <Typography sx={facilityBillStyle.rightText2}>{`${currency_symbol ?? symbol}${currencyCalculation(amountDetail?.totalAmount ?? 0, auth?.facilityToCurrency ?? 0)}`}</Typography>
                        </Box>

                        {isDialog ? null : <Divider sx={facilityBillStyle.divider} />}

                        {isDialog ? null : <CustomButton rootStyle={{ height: "48px !important" }} label={t("Book Facility Now")} color="primary" variant="contained" onClick={handleDialog} />}
                        <Divider sx={facilityBillStyle.divider} />

                    </CardContent>
                </Card>
            </Hidden>
        </>
    )
}
export default withTranslation()(FacilitiesBillCard);