import * as React from "react"
export const PersonIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props}>
    <g data-name="Group 111716">
      <path
        fill="#5ac782"
        d="M9.6 3a3.5 3.5 0 1 0 3.5 3.5A3.508 3.508 0 0 0 9.6 3Zm-4.025 8.4A1.583 1.583 0 0 0 4 12.975v.42a3.174 3.174 0 0 0 1.648 2.587A7.366 7.366 0 0 0 9.6 17a7.366 7.366 0 0 0 3.952-1.018 3.174 3.174 0 0 0 1.648-2.587v-.42a1.583 1.583 0 0 0-1.575-1.575Z"
        data-name="icons8-account (3)"
      />
    </g>
  </svg>
)
 
