import * as React from "react"

export const NoteIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15.718}
    height={16.508}
    {...props}
  >
    <g data-name="Group 112062" fill="#4e5a6b">
      <path
        d="M4.792.512a.568.568 0 0 0-.219.043l-1.721.687-1.53-.678a.583.583 0 0 0-.822.531v12.1a2.15 2.15 0 0 0 2.146 2.146h8.194c.059 0 .117 0 .175-.008a2.545 2.545 0 0 1-.253-.554 1.3 1.3 0 0 1-.047-.609H2.646a.976.976 0 0 1-.976-.976V1.997l.93.415a.58.58 0 0 0 .457.008l1.732-.691 1.732.691a.567.567 0 0 0 .437 0l1.732-.691 1.732.691a.58.58 0 0 0 .457-.008l.933-.413v7.153a2.123 2.123 0 0 1 .223-.137 1.362 1.362 0 0 1 .948-1.03v-6.89a.583.583 0 0 0-.823-.534l-1.53.678L8.909.552a.567.567 0 0 0-.437 0l-1.732.691L5.008.552a.568.568 0 0 0-.216-.04ZM3.427 5.193a.585.585 0 1 0 0 1.171h6.633a.585.585 0 1 0 0-1.171Zm0 3.512a.585.585 0 1 0 0 1.171h3.512a.585.585 0 1 0 0-1.171Zm5.853 0a.585.585 0 1 0 0 1.171h.78a.585.585 0 1 0 0-1.171Zm-5.853 2.341a.585.585 0 1 0 0 1.171h3.512a.585.585 0 1 0 0-1.171Zm5.853 0a.585.585 0 1 0 0 1.171h.78a.585.585 0 1 0 0-1.171Z"
        stroke="rgba(0,0,0,0)"
      />
      <path
        data-name="icons8-receipt-dollar"
        d="M13.377 8.705a.586.586 0 0 0-.585.585v.257a1.856 1.856 0 0 0 .494 3.646h.573a.689.689 0 0 1 .006 1.377l-.718.007a.549.549 0 0 1-.525-.384.585.585 0 0 0-1.117.351 1.709 1.709 0 0 0 1.287 1.162v.218a.585.585 0 1 0 1.171 0v-.193a1.856 1.856 0 0 0-.1-3.709h-.573a.69.69 0 0 1-.01-1.379l.321-.005a.546.546 0 0 1 .526.388.586.586 0 1 0 1.12-.342 1.7 1.7 0 0 0-1.285-1.172v-.221a.586.586 0 0 0-.585-.586Z"
      />
    </g>
  </svg>
)

