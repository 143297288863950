import React from "react";
import { Box, Tabs, Tab,Typography } from "@mui/material";
import { policiesStyle } from "./style";

export const Policies = () => {
  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <Box sx={policiesStyle.box}>
      <Box sx={policiesStyle.tab}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="wrapped label tabs example"
          sx={policiesStyle.tabhead}

        >
          <Tab
            label="Cancellation Policy"
            sx={policiesStyle.transform}
          />
          <Tab  label="House Rules"  sx={policiesStyle.transform} />
          <Tab
            label="Damages and Incidents"
            sx={policiesStyle.transform}
          />
        </Tabs>
       
      </Box>
      <TabPanel value={value} index={0} >
          <Typography sx={policiesStyle.headText}>These are the conditions applicable for the mentioned policy</Typography>
          <ul style={policiesStyle.ul}>
            <li style={policiesStyle.list}>Cancel above than 24 hours then apply a fixed amount $ 17.</li>
            <li style={policiesStyle.list}>Cancel between 01 days to 02 days then apply 50% of bill amount.</li>
            <li style={policiesStyle.list}>Cancel less than 08 hours then apply a fixed amount $ 0.54.</li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={1} >
          <Typography>Follow these rules to be a considerate guest and avoid any issues during your stay.</Typography>
          <ul style={policiesStyle.ul}>
            <li style={policiesStyle.list}>5 guests maximum</li>
            <li style={policiesStyle.list}>No pets</li>
            <li style={policiesStyle.list}>Check-in: 12:00 pm – 5:00 pm</li>
            <li style={policiesStyle.list}>Checkout before 11:00 am</li>
            <li style={policiesStyle.list}>Smoking is allowed</li>
          </ul>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography>You will be responsible for any damage to the rental property caused by you or your party during your stay</Typography>
        </TabPanel>
    </Box>
  );
};
