export const ImageGrid = (images) => {
    //check for pdf files
    let pdfRegex = /\.(pdf)$/i;
    const finalImages = images?.length > 0 && images?.filter((val) => !pdfRegex.test(val?.url))
    let mapperArray = [];
  
  
    if (finalImages?.length > 8) {
      finalImages?.map((val, index) => {
        let obj = {};
        obj.img = val.url;
        obj.title = "";
        if (index === 0) {
          obj.rows = 2;
          obj.cols = 2;
        }
        if (index === 1) {
          obj.cols = 2;
  
        }
        if (index === 2) {
          obj.cols = 2;
  
        }
        if (index === 3) {
          obj.cols = 2;
          obj.rows = 2;
        }
        if (index === 4) {
          obj.cols = 2;
  
        }
        if (index === 5) {
          obj.cols = 2;
  
        }
        if (index === 6) {
          obj.cols = 2;
  
        }
        if (index === 7) {
          obj.cols = 2;
        }
        mapperArray.push(obj);
        return 0;
      });
      return mapperArray;
    }
    else {
      switch (finalImages?.length) {
  
        case 8:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 2;
            }
            if (index === 1) {
              obj.cols = 2;
  
            }
            if (index === 2) {
              obj.cols = 2;
  
            }
            if (index === 3) {
              obj.cols = 2;
              obj.rows = 2;
            }
            if (index === 4) {
              obj.cols = 2;
  
            }
            if (index === 5) {
              obj.cols = 2;
  
            }
            if (index === 6) {
              obj.cols = 2;
  
            }
            if (index === 7) {
              obj.cols = 2;
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 7:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 2;
            }
            if (index === 1) {
              obj.cols = 2;
  
            }
            if (index === 2) {
              obj.cols = 2;
  
            }
            if (index === 3) {
              obj.cols = 2;
              obj.rows = 2;
            }
            if (index === 4) {
              obj.cols = 2;
  
            }
            if (index === 5) {
              obj.cols = 2;
  
            }
            if (index === 6) {
              obj.cols = 2;
  
  
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 6:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 2;
            }
            if (index === 1) {
              obj.cols = 2;
  
            }
            if (index === 2) {
              obj.cols = 2;
  
            }
            if (index === 3) {
              obj.cols = 2;
              obj.rows = 2;
            }
            if (index === 4) {
              obj.cols = 2;
  
            }
            if (index === 5) {
              obj.cols = 2;
  
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 5:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 2;
            }
            if (index === 1) {
              obj.cols = 2;
            }
            if (index === 6) {
              obj.cols = 2;
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 4:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 2;
            }
            if (index === 1) {
              obj.cols = 2;
            }
            if (index === 6) {
              obj.cols = 2;
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 3:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 3;
            }
            if (index === 1) {
              obj.cols = 3;
              obj.rows = 2;
            }
            if (index === 6) {
              obj.cols = 2;
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 2:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 3;
            }
            if (index === 1) {
              obj.cols = 3;
              obj.rows = 2;
            }
            if (index === 6) {
              obj.cols = 2;
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        case 1:
          finalImages?.map((val, index) => {
            let obj = {};
            obj.img = val.url;
            obj.title = "";
            if (index === 0) {
              obj.rows = 2;
              obj.cols = 3;
            }
            if (index === 1) {
              obj.cols = 3;
              obj.rows = 2;
            }
            if (index === 6) {
              obj.cols = 2;
            }
            mapperArray.push(obj);
            return 0;
          });
          return mapperArray;
        default:
          break;
      }
    }
  };
  