import { borderRadius } from "../../utils";
import { Medium, SemiBold } from "../../utils/font";

export const ReviewCardStyle = {
    reviewCardBox: {
        borderRadius: borderRadius,
        backgroundColor: "text.default",
        border: "1px solid #CED3DD",
        padding: "12px"
    },
    headerCardBox: {
        display: "flex",
        alignItems: "center"
    },
    reviewCardDivider: {
        height: "16px",
        border: "1px solid #CED3DD",
        margin: "4px 4px 4px 4px"
    },
    dateText: {
        color: "#4E5A6B",
        fontSize: "0.75rem",
        fontFamily: SemiBold
    },
    reviewerText: {
        color: "#4E5A6B",
        fontSize: "0.75rem",
        fontFamily: SemiBold,
        bottom: "0px",
        mt: "6px"
    },
    rating: {
        "& .MuiSvgIcon-root": {
            height: "14px",
            width: "14px"
        }
    },
    mainImageBox: {
        position: "relative",
        height: "72px",
        width: "72px",
        marginRight: "17px"
    },
    paraText: {
        textAlign: "justify",
        mt: "10px",
        fontSize: "0.875rem"
    },
    reviewImage: {
        borderRadius: borderRadius,
        marginRight: "17px",
        height: "72px",
        width: "72px"

    },
    imageBox: {
        mt: "14px",
        display: "flex",
        alignItems: "center"
    },
    viewMoreBox: {
        position: "absolute",
        top: "50%",
        left: "25%",
        transform: "translate(-50%, -50%)",
        borderRadius: borderRadius,
        height: "72px",
        width: "72px",
        backgroundColor: "#00000082",
        ml: "18px"
    },
    viewMoreText: {
        color: "#fff",
        fontSize: "0.75rem",
        fontFamily: Medium,

    },
    backgroundBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90%"
    }

}