import { SvgIcon } from "@mui/material"
import * as React from "react"

export const BedIcon = (props) => {
  const { rootStyle, ...rest } = props;
  const rootSx = {
    width: 22,
    height: 22,
    color: "#98A0AC",
    ...rootStyle,
  };

  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" sx={rootSx} {...rest}>
      <g data-name="Group 116994">
        <g data-name="Group 96235">
          <path
            fill="#98a0ac"
            d="M3.804 2a2.207 2.207 0 0 0-2.2 2.2v2.72a2.18 2.18 0 0 0-1.6 2.075v3.5a.6.6 0 0 0 0 .194v2.3a.6.6 0 1 0 1.2 0v-1.8h13.589v1.8a.6.6 0 1 0 1.2 0v-2.3a.6.6 0 0 0 0-.194v-3.5a2.18 2.18 0 0 0-1.6-2.075V4.2a2.207 2.207 0 0 0-2.2-2.2Zm0 1.2h8.392a.99.99 0 0 1 1 1v2.6h-1.2a.8.8 0 0 0-.8-.8h-1.6a.8.8 0 0 0-.8.8h-1.6a.8.8 0 0 0-.8-.8h-1.6a.8.8 0 0 0-.8.8h-1.2V4.2a.99.99 0 0 1 1.008-1ZM2.304 8h11.392a.6.6 0 0 0 .118.008.986.986 0 0 1 .98.991v3H1.207v-3A.986.986 0 0 1 2.188 8a.6.6 0 0 0 .114-.006Z"
            data-name="icons8-bed (2)"
          />
        </g>
      </g>
    </SvgIcon>
  )

}
