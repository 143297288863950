import { CreateNewAccountStyle } from "./style"
import { CustomButton, TextBox } from "../../components";
import { Box } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as React from 'react';
import { Auth } from "aws-amplify";
import { AlertContext } from "../../contexts";
import { useSignUpMutation } from "../../redux/services/auth";
import { AlertProps, LocalStorageKeys } from "../../utils";

const initialState = {
    name: "",
    emailId: "",
    password: "",
    conformpassword: "",
    showPassword: false,
    error: {
        name: "",
        emailId: "",
        password: "",
        conformpassword: "",

    }
}
export const CreateNewAccount = (props) => {
    const { isPadding } = props
    const { t=()=> false } = props
    const selectCode = JSON.parse(localStorage.getItem(LocalStorageKeys.selected_country_detail))

    const [data, setData] = React.useState({ ...initialState })
    const updateData = (key, value) => {
        let error = data.error
        error[key] = ""
        setData({ ...data, [key]: value, error })
    };
    const [signup] = useSignUpMutation()
    const alert = React.useContext(AlertContext);
    // form validations
    const validate = () => {
        var isValid = true;
        const error = data?.error;
        // const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
        // const is_password_strong = is_password_strong_regex.test(data.password)
        if (data?.name.length === 0) {
            isValid = false;
            error.name = "Name is Required";
        }
        if (data?.emailId.length === 0) {
            isValid = false;
            error.emailId = "Email Id is Required";
        }
        if (data?.password?.length === 0) {
            isValid = false;
            error.password = "Password is Required";
        }

        if (data?.password?.length < 8) {
            isValid = false;
            error.password = "Password Must be 8 Characters";
        }
        // else
        //  if (!Boolean(is_password_strong)) {
        //     isValid = false;
        //     error.password = "Password is not stong";
        // }
        if (data?.conformpassword?.length === 0) {
            isValid = false;
            error.conformpassword = "Conform Password is Required";
        }
        if (data?.conformpassword?.length < 8) {
            isValid = false;
            error.conformpassword = "conform Password Must be 8 Characters";
        }
        if (data?.conformpassword !== data?.password) {
            isValid = false;
            error.conformpassword = "Password and Conform Password must be same";
        }
        setData({ ...data, error });
        return isValid;
    };

    const SignUp = async () => {
        if (validate()) {
            try {
                const { userSub } = await Auth.signUp({
                    username: data.emailId,
                    password: data.conformpassword,
                    attributes: {
                        email: data.emailId,          // optional
                        // other custom attributes 
                    },
                    autoSignIn: { // optional - enables auto sign in after user is confirmed
                        enabled: true,
                    }
                });


                if (!userSub) return;

                signup({
                    "name": data?.name,
                    "email": data?.emailId,
                    "sub_id": userSub,
                    country: selectCode?.id ?? undefined
                })
                    .then((res) => {

                        if (res?.data?.type === 'success') {
                            alert.setSnack({
                                ...alert,
                                open: true,
                                severity: AlertProps.severity.success,
                                msg: 'User signed up successfully',
                            });
                            props?.onClose()
                        }
                    })
                    .catch((err) => {

                        // console.log(err)
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: err.message
                        });
                    })
            }
            catch (error) {
                console.log("Sign up error:", error);
                // console.log(err)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: error?.message
                });
                // Handle any other caught errors if needed
            }
        }
        else {
            return false;
        }
    }

    const handleClickShowPassword = () => {
        updateData("showPassword", !data?.showPassword)
    };
    return (
        <>
            {/* input form name email & password  */}
            <Box sx={isPadding ? CreateNewAccountStyle.mainBoxpadding : CreateNewAccountStyle.mainBox}>
                <Box sx={CreateNewAccountStyle.inputBarSx}>
                    <TextBox
                        isSearch
                        fieldStyle={CreateNewAccountStyle.email}
                        label={t("Name")}
                        placeholder={t("Enter Name")}
                        type={"name"}
                        helperText={data?.error?.name}
                        value={data?.name}
                        onChange={(e) => updateData("name", e.target.value)}
                        error={data?.error?.name}
                    />
                </Box>
                <Box sx={CreateNewAccountStyle.inputBarSx}>
                    <TextBox
                        isSearch
                        fieldStyle={CreateNewAccountStyle.email}
                        label={t(" Email ID")}
                        placeholder={t("Enter email ID")}
                        type={"emailId"}
                        helperText={data?.error?.emailId}
                        value={data?.emailId}
                        onChange={(e) => updateData("emailId", e.target.value)}
                        error={data?.error?.emailId}
                    />
                </Box>
                <Box sx={CreateNewAccountStyle.inputBarSx}>
                    <TextBox
                        isSearch
                        fieldStyle={CreateNewAccountStyle.email}
                        label={t("Password")}
                        placeholder={t("Enter your password")}
                        type={data?.showPassword ? 'text' : 'password'}
                        value={data?.password}
                        helperText={data?.error?.password}
                        error={data?.error?.password}
                        onChange={(e) => updateData("password", e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {data?.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>
                <Box sx={CreateNewAccountStyle.inputBarSx}>
                    <TextBox
                        isSearch
                        fieldStyle={CreateNewAccountStyle.email}
                        label={t("Confirm Password")}
                        placeholder={t("Enter your Confirm password")}
                        type={data?.showPassword ? 'text' : 'password'}
                        value={data?.conformpassword}
                        helperText={data?.error?.conformpassword}
                        error={data?.error?.conformpassword}
                        onChange={(e) => updateData("conformpassword", e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {data?.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>
                {/*signIn button  */}

                <CustomButton
                    variant="outlined"
                    size="medium"
                    label={t("Create New Account")}
                    rootStyle={CreateNewAccountStyle.button}
                    onClick={() => SignUp()}
                >
                </CustomButton>

            </Box>
        </>
    )
}