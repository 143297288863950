import * as React from "react"

export const GymIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M12.285 3.001a.692.692 0 0 0-.406.149L5.32 8.317A3.464 3.464 0 0 0 4 11.036v7.426a2.549 2.549 0 0 0 2.538 2.539h11.539a2.549 2.549 0 0 0 2.538-2.538v-7.427A3.464 3.464 0 0 0 19.3 8.317l-6.56-5.168a.692.692 0 0 0-.455-.148Zm.023 1.574 6.131 4.826a2.074 2.074 0 0 1 .791 1.631v7.426a1.144 1.144 0 0 1-1.154 1.154H6.538a1.144 1.144 0 0 1-1.154-1.154v-7.422a2.074 2.074 0 0 1 .792-1.635Zm-3.011 5.8a.692.692 0 0 0-.681.7v.926a.693.693 0 1 0-1.385 0v3.692a.693.693 0 1 0 1.385 0v.923a.693.693 0 1 0 1.385 0v-2.077h4.615v2.077a.693.693 0 1 0 1.385 0v-.923a.693.693 0 1 0 1.385 0v-3.692a.693.693 0 1 0-1.386 0v-.923a.693.693 0 1 0-1.385 0v2.077H10v-2.078a.692.692 0 0 0-.7-.7Z"
      fill="#78b1fe"
      data-name="Group 111058"
    />
  </svg>
)


