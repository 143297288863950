import React from "react";
import { withNavBars } from "../../../HOCs";

class MyShortlistPageParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    return <></>;
  }
}
const props = {
  key: 0
}
export default withNavBars((MyShortlistPageParent), props);

