import * as React from "react"

export const SwimmingIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g data-name="Group 111058">
      <path
        data-name="icons8-outdoor-swimming-pool (3)"
        d="M5.149 5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5Zm7.5 0a1.938 1.938 0 0 0-1.933 1.928v7.711a.643.643 0 1 0 1.285 0V6.928a.633.633 0 0 1 .643-.643h.857a.643.643 0 1 0 0-1.285Zm6.854 0a1.938 1.938 0 0 0-1.928 1.928v1.5h-4.07a.643.643 0 1 0 0 1.285h4.07v1.714h-4.07a.643.643 0 1 0 0 1.285h4.07v1.928a.643.643 0 1 0 1.285 0V6.928a.633.633 0 0 1 .643-.643h.857a.643.643 0 1 0 0-1.285ZM3.633 15.275a.643.643 0 0 0-.431 1.11 3.559 3.559 0 0 0 .855.55 5.6 5.6 0 0 0 2.377.489 5.6 5.6 0 0 0 2.382-.489 1.663 1.663 0 0 0 .407-.262 1.663 1.663 0 0 0 .407.262 5.6 5.6 0 0 0 2.377.489 5.6 5.6 0 0 0 2.377-.489 1.663 1.663 0 0 0 .407-.262 1.663 1.663 0 0 0 .407.262 5.6 5.6 0 0 0 2.377.489 5.6 5.6 0 0 0 2.377-.489 3.559 3.559 0 0 0 .855-.55.644.644 0 1 0-.9-.922 1.8 1.8 0 0 1-.5.307 4.279 4.279 0 0 1-1.833.368 4.279 4.279 0 0 1-1.833-.368 1.807 1.807 0 0 1-.5-.307.643.643 0 0 0-.9 0 1.807 1.807 0 0 1-.5.307 4.279 4.279 0 0 1-1.833.368 4.279 4.279 0 0 1-1.833-.368 1.605 1.605 0 0 1-.475-.29.643.643 0 0 0-.484-.206h-.059a.643.643 0 0 0-.411.2 1.594 1.594 0 0 1-.474.289 4.279 4.279 0 0 1-1.833.368 4.278 4.278 0 0 1-1.833-.368 1.808 1.808 0 0 1-.5-.307.643.643 0 0 0-.464-.188Zm0 2.57a.643.643 0 0 0-.431 1.11 3.559 3.559 0 0 0 .855.55 5.6 5.6 0 0 0 2.377.489 5.6 5.6 0 0 0 2.382-.489 1.663 1.663 0 0 0 .407-.262 1.663 1.663 0 0 0 .407.262 5.6 5.6 0 0 0 2.377.489 5.6 5.6 0 0 0 2.377-.489 1.663 1.663 0 0 0 .407-.262 1.663 1.663 0 0 0 .407.262 5.6 5.6 0 0 0 2.377.489 5.6 5.6 0 0 0 2.377-.489 3.559 3.559 0 0 0 .855-.55.644.644 0 1 0-.9-.922 1.8 1.8 0 0 1-.5.307 4.279 4.279 0 0 1-1.833.368 4.279 4.279 0 0 1-1.833-.368 1.807 1.807 0 0 1-.5-.307.643.643 0 0 0-.9 0 1.807 1.807 0 0 1-.5.307 4.279 4.279 0 0 1-1.833.368 4.279 4.279 0 0 1-1.833-.368 1.605 1.605 0 0 1-.475-.29.643.643 0 0 0-.484-.206h-.059a.643.643 0 0 0-.411.2 1.594 1.594 0 0 1-.474.289 4.279 4.279 0 0 1-1.833.368 4.278 4.278 0 0 1-1.833-.368 1.808 1.808 0 0 1-.5-.307.643.643 0 0 0-.464-.188Z"
        fill="#78b1fe"
      />
    </g>
  </svg>
)

 