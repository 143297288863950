export const config = {
  api_url: process.env.REACT_APP_BACKEND_API,
  graphql_url: process.env.REACT_APP_GRAPHQL_BASE_URL,
  graphql_web_socket_url: process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL,
  cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  cognito_region: process.env.REACT_APP_COGNITO_REGION,
  cognito_user_pool_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  cognito_user_pool_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  cognito_domain: process.env.REACT_APP_COGNITO_DOMAIN,
  mapbox_key: "pk.eyJ1IjoicHJvcGdvdG9tYXBzIiwiYSI6ImNsY214ZmtybzA5eXgzeHFwZmN2MDhjNmMifQ.YV5PBXEeIk9CJmtWRKA4vw",
  mapbox_style: process.env.REACT_APP_MAP_BOX_STYLE,
  googlemap_key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  listings_url: process.env.REACT_APP_LISTING_URL,
  realitygoto_url: process.env.REACT_APP_REALITYGOTO_URL,
  amenitiesgoto_url: process.env.REACT_APP_AMENITIESGOTO_URL,
}; 
