import * as React from "react"
const RedDelete = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13.998} height={14} {...props}>
    <path
      fill="#ff4b4b"
      d="M13.366-.001a.617.617 0 0 0-.43.19L6.998 6.127 1.06.189a.617.617 0 1 0-.872.872l5.938 5.938-5.938 5.937a.617.617 0 1 0 .872.872L6.998 7.87l5.938 5.938a.617.617 0 1 0 .872-.872L7.87 6.998l5.937-5.937a.617.617 0 0 0-.442-1.062Z"
      data-name="icons8-delete (3)"
    />
  </svg>
)
export default RedDelete
