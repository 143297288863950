import { Typography } from "@mui/material"
import { labelStyles } from './style'

export const Label = (props) => {
    return (
        <Typography sx={localStorage?.i18nextLng === "ar" ? labelStyles.labelArabic(props) : labelStyles.label(props)} noWrap>
            {props.label}
            {props.isRequired ?
                <Typography variant="caption" sx={labelStyles.required}>*</Typography> :
                <Typography variant="caption" sx={labelStyles.required}></Typography>}
        </Typography>
    )
}