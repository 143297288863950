import { Typography, Box, Avatar } from "@mui/material";
import React from "react";
import { searchCardStyle } from "./style";
import { LocationIcon, } from "../../assets/components";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const SearchCard = ({
  header = '',
  location = '',
  img = '',
  islocate = true,
  type = '',
  isIcon = "",
  units = '',
  onClick = () => false,
  data = {}
}) => {
  return (
    <Box sx={islocate ? searchCardStyle.main : searchCardStyle.locateMain} onClick={() => onClick(data)}>
      <Typography sx={searchCardStyle.chip}>{type}</Typography>
      <Box >
        <Avatar src={img ? img : "../images/noImage.svg"} style={searchCardStyle.image}></Avatar>
      </Box>
      <Box sx={searchCardStyle.textBox}>
        <Typography sx={islocate ? searchCardStyle.headerTextWrapped : searchCardStyle.headerText}>
          {header}
        </Typography>
        {isIcon ?
          <Box sx={searchCardStyle.locationBox}>
            {islocate && <LocationIcon />}
            <Typography sx={searchCardStyle.locationText}>{location}</Typography>
          </Box>
          :
          <Box sx={searchCardStyle.iconBox}>
            <Typography sx={searchCardStyle.locationText}>{location}</Typography>
            {units && <FiberManualRecordIcon sx={searchCardStyle.roundIcon} />}
            <Typography sx={searchCardStyle.locationText}>{units ?? ""}{units ? " Units available" : ""}</Typography>
          </Box>
        }
      </Box>
    </Box>
  )
}