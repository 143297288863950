import * as React from "react"
export const SortDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    data-name="Group 112256"
    {...props}
  >
    <path
      fill="#4e5a6b"
      d="m15.542 20.708-3.5-3.5a1 1 0 1 1 1.415-1.414l1.793 1.792V4a1 1 0 1 1 2 0v13.586l1.793-1.794a1 1 0 1 1 1.414 1.415l-3.5 3.5a1 1 0 0 1-.693.292h-.014a1 1 0 0 1-.708-.291Zm-11.826-.265a.75.75 0 0 1-.41-.978l2.25-5.5a.75.75 0 0 1 1.389 0l2.25 5.5a.75.75 0 0 1-.409.978.736.736 0 0 1-.284.056.753.753 0 0 1-.7-.466l-.215-.533H4.913l-.219.534a.747.747 0 0 1-.978.41ZM5.527 18h1.447l-.724-1.77Zm-1.025-7.5a.75.75 0 0 1-.634-1.152L6.634 5H4.5a.75.75 0 0 1 0-1.5H8a.75.75 0 0 1 .633 1.153L5.866 9h2.135a.75.75 0 1 1 0 1.5Z"
      data-name="Path 100548"
    />
  </svg>
)
