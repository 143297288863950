import { Avatar, Box, Grid, Typography } from "@mui/material";
import { AvailableCardStyle } from "./style";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { withTranslation } from "react-i18next";
const AvailableCard = ({ t = () => false, url = "", slots = "", title = "", handleClick = () => false, amenietyMaster = "", item, padding = '' }) => {
  return (
    // Main card and images
    <>

      {/* <Box sx={AvailableCardStyle.mainBox} onClick={handleClick}>
        <Box sx={AvailableCardStyle?.firstBox}>
          <Box sx={AvailableCardStyle.mainimage}>
            <Avatar src={url?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"} sx={AvailableCardStyle.image} />
          </Box>
          <Box sx={AvailableCardStyle.content} >
            
            <Typography sx={AvailableCardStyle.amenietyMaster}>{amenietyMaster}</Typography>
            <Typography sx={AvailableCardStyle.text1}>{title}</Typography>
            <Typography sx={AvailableCardStyle.text2}>{slots} {t("Slots Available")}</Typography>
            
          </Box>
        </Box>
        <Box sx={AvailableCardStyle.arrowicon}>
          <NavigateNextIcon className="arrowhover" style={{ transform: localStorage?.i18nextLng === "ar" ? `rotate(180deg)` : '' }} />
        </Box>
      </Box> */}

      <Grid container sx={AvailableCardStyle.mainBox} onClick={handleClick}>
        <Grid item xs={11} sm={10} md={10} lg={10} spacing={1} display={'flex'}>
        <Grid item xs={3} sm={2.5} md={2.5} lg={2.5}>
          <Avatar src={url?.[0]?.url ?? "/images/amenityMiniPlaceholder.png"} sx={AvailableCardStyle.image} />
        </Grid>
        <Grid item xs={9} sm={9.5} md={9.5} lg={9.5} sx={{ ...AvailableCardStyle.label, ...padding }}>
        <Typography sx={AvailableCardStyle.amenietyMaster} noWrap>{title} | {item?.category}</Typography>
      <Typography sx={AvailableCardStyle.text1} noWrap>{amenietyMaster}</Typography>
          <Typography sx={AvailableCardStyle.text2} noWrap>{slots} {t("Slots Available")}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={1} sm={2} md={2} lg={2}>
        <Box sx={AvailableCardStyle.arrowicon}>
          <NavigateNextIcon className="arrowhover" style={{ transform: localStorage?.i18nextLng === "ar" ? `rotate(180deg)` : '' }} />
        </Box>

      </Grid>

    </Grid>
  </>
  );
};
export default withTranslation('searchResults')(AvailableCard);
