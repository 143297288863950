import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AreaIcon, BathtabIcon, Building, HomeIcon, HomePlusIcon, HotelbedIcon, ShortListDeleteIcon, ShortlistTick } from "../../../assets/components";
import { CustomButton } from "../../Button";
import { shortlistedStyle } from "./style";
import { withTranslation } from "react-i18next";

const ShortlistedUnit = ({

    startIcon,
    endIcon,
    isbutton = false,
    onShortlist = () => false,
    hotelname = "",
    unit = "",
    src = "",
    sqft = "",
    bed = "",
    room = "",
    bathtab = "",
    price = "",
    month = "",
    available = "",
    type = "",
    id = '',
    isSelected = false,
    floorText = "",
    onClickViewDetails = () => false,
    onClick = () => false,
    t=()=> false,
    variant = "",
    Washroom = "",
    logo = "",
    onShortlistUnit = () => false,
    OpenSingleImageModal = () => false,
    item = {},
    symbol = "",
    cmpny_name = ""

}) => {
    return (
        <>
            {/* image section */}
            {isbutton ?
                <Box sx={shortlistedStyle?.main} onClick={() => onClickViewDetails()}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={4.5}>
                            <Avatar src={logo ?? "images/house.png"} alt={"house"} style={shortlistedStyle.Leftimage} />
                        </Grid>
                        <Grid item xs={7.5}>
                            <Typography noWrap sx={shortlistedStyle.texthead1} className="texthead1">
                                {hotelname}, {room}
                            </Typography>
                            <Stack direction={"row"} alignItems={"center"}  sx={shortlistedStyle?.subTextDiv}>

                                <Building />
                                <Typography sx={shortlistedStyle?.subText}>{cmpny_name ?? ""}</Typography>
                                <AreaIcon />
                                <Typography sx={shortlistedStyle?.subText}>{sqft ?? ""}</Typography>
                            </Stack>
                            <Stack>
                                <Typography sx={shortlistedStyle?.imgChips}>{type}</Typography>
                            </Stack>
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                <Stack>
                                    <Typography sx={shortlistedStyle?.startFrom}>{t("Starts From")}</Typography>
                                    <Typography sx={shortlistedStyle?.startAmnt}>{symbol}{price} <span style={shortlistedStyle.startMonth}> / {month}</span></Typography>
                                    <Typography sx={shortlistedStyle?.startTaxes}>+ {t("taxes & charges")}</Typography>
                                </Stack>
                                <Stack>
                                    {isSelected ?
                                        <CustomButton
                                            variant="outlined"
                                            size="medium"
                                            rootStyle={shortlistedStyle.tickbutton}
                                            startIcon={<ShortlistTick />}
                                            onClick={(e) => onShortlist({
                                                hotelname,
                                                unit,
                                                src,
                                                sqft,
                                                bed,
                                                room,
                                                bathtab,
                                                price,
                                                month,
                                                available,
                                                type,
                                                id,
                                                isSelected,
                                                logo
                                            }, e)}
                                        />
                                        :
                                        <CustomButton
                                            variant="outlined"
                                            size="medium"
                                            rootStyle={shortlistedStyle.mainbutton}
                                            startIcon={<HomePlusIcon />}
                                            onClick={(e) => onShortlist({
                                                hotelname,
                                                unit,
                                                src,
                                                sqft,
                                                bed,
                                                room,
                                                bathtab,
                                                price,
                                                month,
                                                available,
                                                type,
                                                id,
                                                isSelected,
                                                logo
                                            }, e)}
                                        ></CustomButton>
                                    }
                                </Stack>
                            </Stack>


                        </Grid>

                    </Grid>
                </Box>
                :
                <Box sx={shortlistedStyle.maincard}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={4.5}>
                            <Stack sx={shortlistedStyle?.imageBoxe}>
                                <Typography sx={shortlistedStyle?.imgChip}>{type}</Typography>
                                <Box
                                    as="img"
                                    src={logo ?? "images/house.png"}
                                    alt="house"
                                    style={shortlistedStyle.image}>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={7.5} sx={shortlistedStyle?.rightContentGrid}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography sx={shortlistedStyle.amt} >{symbol}{price}<span style={shortlistedStyle.month1}>/ {item?.payment_period}</span></Typography>
                                <Box>
                                    <CustomButton
                                        size="medium"
                                        rootStyle={shortlistedStyle.button}
                                        startIcon={<ShortListDeleteIcon onClick={() => onShortlistUnit(item)} />}
                                    ></CustomButton>
                                </Box>
                            </Stack>

                            <Grid item xs={12} sm={12} sx={shortlistedStyle?.heading} >
                                <Typography noWrap sx={shortlistedStyle.texthead1} className="texthead1">
                                    {hotelname}, {unit}
                                </Typography>
                            </Grid>
                            <Stack direction={"row"} spacing={1} alignItems={"center"} sx={shortlistedStyle?.unitsSec} >
                                <Typography noWrap sx={shortlistedStyle.unittext1} className="unittext1">
                                    {unit}
                                </Typography>
                                <FiberManualRecordIcon sx={shortlistedStyle.doticon} />
                                <Typography noWrap sx={shortlistedStyle.unittext1} className="unittext1">
                                    {sqft}
                                </Typography>
                            </Stack>
                            <Stack direction={"row"} spacing={1} alignItems={"center"} sx={shortlistedStyle?.iconsLists} >
                                <HotelbedIcon />
                                <Typography noWrap sx={shortlistedStyle.icontext}>{bed}</Typography>
                                <FiberManualRecordIcon sx={shortlistedStyle.doticongrey} />
                                <BathtabIcon />
                                <Typography noWrap sx={shortlistedStyle.icontext}>{bathtab}</Typography>
                                <FiberManualRecordIcon sx={shortlistedStyle.doticongrey} />
                                <HomeIcon />
                                <Typography noWrap sx={shortlistedStyle.icontext}>{room}</Typography>

                            </Stack>


                        </Grid>

                    </Grid>
                </Box>

            }




        </>
    );

}
export default (withTranslation('shortlistedUnit')(ShortlistedUnit));

// {/* Old UI For Short List Starts Please Don't Delete */}
// {isbutton ?
//     <Box>
//         {isSelected ?
//             <CustomButton
//                 variant="outlined"
//                 size="medium"
//                 rootStyle={shortlistedStyle.tickbutton}
//                 startIcon={<ShortlistTick />}
//                 onClick={() => onShortlist({
//                     hotelname,
//                     unit,
//                     src,
//                     sqft,
//                     bed,
//                     room,
//                     bathtab,
//                     price,
//                     month,
//                     available,
//                     type,
//                     id,
//                     isSelected,
//                     logo
//                 })}
//             />
//             :
//             <CustomButton
//                 variant="outlined"
//                 size="medium"
//                 rootStyle={shortlistedStyle.mainbutton}
//                 startIcon={<HomePlusIcon />}
//                 onClick={() => onShortlist({
//                     hotelname,
//                     unit,
//                     src,
//                     sqft,
//                     bed,
//                     room,
//                     bathtab,
//                     price,
//                     month,
//                     available,
//                     type,
//                     id,
//                     isSelected,
//                     logo
//                 })}
//             ></CustomButton>
//         }
//     </Box>
//     :
//     <Box>
//         <CustomButton
//             size="medium"
//             rootStyle={shortlistedStyle.button}
//             startIcon={<ShortListDeleteIcon onClick={() => onShortlistUnit(item)} />}
//         ></CustomButton>
//     </Box>
// }
// {/* Old UI For Short List Ends Please Don't Delete */}