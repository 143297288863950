import * as React from "react"

const HospitalIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
        <path
            d="M10.419 2.048a2.491 2.491 0 0 0-2.488 2.488v1.131H4.99a2.488 2.488 0 0 0-2.488 2.488v11.31a2.488 2.488 0 0 0 2.488 2.483h5.429a.679.679 0 0 0 .679-.679v-2.94a.453.453 0 0 1 .452-.452h.9a.453.453 0 0 1 .452.452v2.94a.679.679 0 0 0 .679.679h5.429a2.488 2.488 0 0 0 2.492-2.483V8.155a2.488 2.488 0 0 0-2.488-2.488h-2.94V4.536a2.491 2.491 0 0 0-2.489-2.488Zm-.227 1.357h3.62a.9.9 0 0 1 .9.9v3.624a.9.9 0 0 1-.9.9h-3.62a.9.9 0 0 1-.9-.9V4.31a.9.9 0 0 1 .9-.905Zm1.81.9a.679.679 0 0 0-.679.679v.452h-.452a.679.679 0 1 0 0 1.357h.452v.455a.679.679 0 1 0 1.357 0v-.45h.452a.679.679 0 1 0 0-1.357h-.452v-.453a.679.679 0 0 0-.678-.678Zm-5.881 6.791h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Zm3.619 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Zm3.619 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.457-.452v-.9a.453.453 0 0 1 .457-.452Zm3.619 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452ZM6.121 13.81h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Zm3.619 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Zm3.619 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Zm3.619 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452ZM6.121 16.524h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Zm10.857 0h.9a.453.453 0 0 1 .452.452v.9a.453.453 0 0 1-.452.452h-.9a.453.453 0 0 1-.452-.452v-.9a.453.453 0 0 1 .452-.452Z"
            fill={props?.color ?? "#4e5a6b"}
            data-name="Group 111271"
        />
    </svg>
)

export default HospitalIcon
