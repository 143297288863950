import React from 'react'
import { InsideCardStyles } from "./style"
import { Box, Typography } from '@mui/material'

export const InsideCard = (props) => {
    return (
        <>
            <Box sx={InsideCardStyles.parent(props)}>
                <Box sx={InsideCardStyles.subParent}>
                    <Box sx={InsideCardStyles.icon}>{props?.data?.icon}</Box>
                    <Box>
                        <Typography sx={InsideCardStyles.text}>{props?.data?.name}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={InsideCardStyles.subText}>{props?.data?.value}</Typography>

                </Box>
            </Box>
        </>
    )
}