import { borderRadius } from "../../utils";
import { Medium, Regular, SemiBold } from "../../utils/font";

export const staysSearchStyle = {
    main: {
        borderRadius: borderRadius,
        height: {
            sm: 'auto',
            lg: "236px"
        },
        border: "1px solid #CED3DD",
        mt: 2,
        boxShadow: "0px 0px 32px #0000000f",
        cursor: "pointer"
    },
    rightArrow: {
        mt: '10px',
        mr: '-10px'
    },
    checkBtn: {
        height: "40px",
        fontFamily: SemiBold,
        fontSize: "0.875rem"
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: borderRadius,
        cursor: 'pointer'
    },
    relative: {
        position: 'relative',
        height: {
            sm: '200px',
            lg: "100%"
        }
    },
    inherit: {
        height: 'inherit'
    },
    alignEnd: {
        textAlign: 'end'
    },
    subheading: {
        fontFamily: Regular,
        color: 'text.secondary',
        fontSize: "0.75rem",
        pb: {
            sm: "10px",
            lg: "0px"
        }
    },
    rate: {
        fontFamily: Medium,
        color: 'text.secondary',
        fontSize: "1rem",
    },
    viewonmap: {
        fontFamily: Regular,
        color: 'text.tertiary',
        fontSize: "0.875rem",
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        flexDirection: 'row'
    },
    subHeading2: {
        fontFamily: Regular,
        color: 'text.secondary',
        fontSize: "0.75rem",
        marginTop: '-5px'
    },
    pl: {
        pl: '20px', pb: "12px"
    },
    subHeading3: {
        fontFamily: SemiBold,
        color: 'text.secondary',
        fontSize: "0.875rem",
        marginTop: '-7px'
    },
    gtick: {
        mb: "8px"
    },
    underline: {
        fontFamily: Medium,
        fontSize: "0.75rem",
        color: 'primary.main'
    },
    viewonMap: {
        display: 'flex', gap: 2, alignItems: 'center'
    },
    mapIcons: {
        display: 'flex', alignItems: 'center', gap: 0.75
    },
    pdetails2: {
        display: 'flex', alignItems: 'center', gap: 0.5, padding: '10px', mt: "5px"
    },
    pdetails3: {
        display: 'flex', alignItems: 'center', gap: 0.5
    },
    pdetails: {
        height: '40px',
        backgroundColor: '#F2F4F7',
        borderRadius: borderRadius,
        display: 'flex',
    },
    firstHalf: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px'
    },
    firstHeading: {
        mb: '-5px',
        mt: '10px',
        fontFamily: Medium,
        fontSize: "1rem",
        color: "text.secondary"
    },
    chip: {
        position: "absolute",
        top: "18px",
        left: "17px",
        backgroundColor: "hsla(170, 61%, 48%, 1)",
        color: "#fff",
        fontSize: "0.75rem"
    },
    companyIcon: {
        width: '77px', height: '46px', border: '1px solid #E4E8EE',
        borderRadius: borderRadius,
    },
    normalBlack: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: '#091B29'
    },
    normalSmall: {
        fontSize: "0.75rem",
        fontFamily: Regular,
        color: 'text.tertiary'
    },
    red: {
        fontSize: "0.75rem",
        color: 'error.light'
    },
    bedroomIcon: {
        color: '#78B1FE', width: 22, height: 22, mt: 1
    },
    secondHalf: {
        display: 'flex', p: "8px 16px", mt: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end'
    },
    imageViewer: {
        padding: 2
    },
    viewButton: {
        pb: 0
    },
    bedroom: {
        display: 'flex',
        width: '173px',
        justifyContent: 'center',
        mt: 4,
        gap: 0.75,
        alignItems: 'center', backgroundColor: '#F1F7FF', padding: '8px', borderRadius: borderRadius, color: '#78B1FE'
    },
    imageViewBox: {
        height: '332px', borderRadius: borderRadius
    },
    imageViewImg: {
        width: '100%', height: '100%', borderRadius: borderRadius
    },
    imageViewSmall: {
        padding: 1, display: 'flex', gap: '12px', flexWrap: 'nowrap', width: 'fit-content'
    },
    imageViewSmallBox: {
        borderRadius: borderRadius, height: '64px', width: '64px', overflow: 'hidden'
    },
    imageViewSmallBoxImg: {
        width: '100%', height: '100%', borderRadius: borderRadius, objectFit: 'cover'
    },
    avatarBox: {
        display: "flex",
        justifyContent: "center",
    },
    avatar: {
        display: "flex",
        justifyContent: "center",
    },
    avBox: {
        mt: "80px"
    },
    bedroomIconText: {
        fontSize: "0.75rem"
    }
}