import * as React from "react"

const CalendarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g data-name="Group 111593">
      <g data-name="Group 111592">
        <path
          data-name="icons8-calendar (14)"
          d="M2.889 0A2.9 2.9 0 0 0 0 2.889v10.222A2.9 2.9 0 0 0 2.889 16h10.222A2.9 2.9 0 0 0 16 13.111V2.889A2.9 2.9 0 0 0 13.111 0Zm0 1.333h10.222a1.546 1.546 0 0 1 1.556 1.556v.667H1.333v-.667a1.546 1.546 0 0 1 1.556-1.556ZM1.333 4.889h13.334v8.222a1.546 1.546 0 0 1-1.556 1.556H2.889a1.546 1.546 0 0 1-1.556-1.556Zm2.889 1.778a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm3.778 0a1.111 1.111 0 1 0 1.111 1.111A1.111 1.111 0 0 0 8 6.667Zm3.778 0a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm-7.556 4a1.111 1.111 0 1 0 1.111 1.111 1.111 1.111 0 0 0-1.111-1.111Zm3.778 0a1.111 1.111 0 1 0 1.111 1.111A1.111 1.111 0 0 0 8 10.667Z"
          fill="#98a0ac"
        />
      </g>
    </g>
  </svg>
)

export default CalendarIcon
