
import { SvgIcon } from "@mui/material"
import * as React from "react"

export const FacilitiesIcon=(props)=> {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 24,
      height: 24,
      color:"#98a0ac",
      ...rootStyle,
    };
    return (
      <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
   sx={rootSx}
    {...rest}
  >
    <defs>
      <style>{".b{fill:currentColor}"}</style>
    </defs>
    <g transform="translate(-3.877 -3.145)">
      <path
        d="M19.475 21.245H5.827a1.949 1.949 0 0 1-1.95-1.95V9.317a2.924 2.924 0 0 1 1.5-2.556l5.849-3.249a2.927 2.927 0 0 1 2.841 0l5.849 3.249a2.925 2.925 0 0 1 1.5 2.556v9.977a1.949 1.949 0 0 1-1.941 1.951Z"
        style={{
          fill: "#ced3dd",
          opacity: 0.35,
        }}
      />
      <circle
        className="b"
        cx={1.462}
        cy={1.462}
        r={1.462}
        transform="translate(5.827 11.496)"
      />
      <circle
        className="b"
        cx={1.462}
        cy={1.462}
        r={1.462}
        transform="translate(16.551 11.496)"
      />
      <path
        className="b"
        d="M9.727 19.295a.975.975 0 0 1-.975-.975v-.975h1.95v.975a.975.975 0 0 1-.975.975ZM15.576 19.295a.975.975 0 0 1-.975-.975v-.975h1.95v.975a.975.975 0 0 1-.975.975Z"
      />
      <path
        className="b"
        d="M16.547 13.06v1.364h-7.8V12.96l-1.95-.487v3.9a1.949 1.949 0 0 0 1.95 1.95h7.8a1.949 1.949 0 0 0 1.95-1.95v-3.9Z"
      />
      <path
        className="b"
        d="M9.729 12.959a2.512 2.512 0 0 1-.049.487h5.949a2.435 2.435 0 0 1 1.9-2.876V9.549a1.949 1.949 0 0 0-1.95-1.95h-5.85a1.949 1.949 0 0 0-1.952 1.95v1.024a2.439 2.439 0 0 1 1.952 2.386Z"
      />
    </g>
     </SvgIcon>
    )
  }

 