import { SvgIcon } from "@mui/material"
import * as React from "react"

export const ResidentialIcon=(props)=> {
    const { rootStyle, ...rest } = props;
    const rootSx = {
      width: 24,
      height: 24,
      color:"#98a0ac",
      ...rootStyle,
    };
    return (
      <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={rootSx}
      viewBox="0 0 24 24"
      {...rest}
    >
      <defs>
        <clipPath id="a">
          <path
            transform="translate(287 21)"
            fill="#4e5a6b"
            stroke="#707070"
            d="M0 0H24V24H0z"
          />
        </clipPath>
      </defs>
      <g transform="translate(-287 -21)" clipPath="url(#a)">
        <g transform="translate(287 21)">
          <path
            d="M18 21H6a3 3 0 01-3-3v-8h2l7-5 7 5h2v8a3 3 0 01-3 3z"
            fill="#ced3dd"
            opacity={0.35}
          />
          <path
            d="M20 3H4a2 2 0 00-2 2v5a2 2 0 002 2h2.172a2 2 0 001.414-.586l2.782-2.782a2.319 2.319 0 013.273-.008l2.825 2.8a2 2 0 001.407.576H20a2 2 0 002-2V5a2 2 0 00-2-2z"
            fill="currentColor"
          />
          <path transform="translate(10 16)" fill="#98a0ac" d="M0 0H4V5H0z" />
          <circle
            cx={2}
            cy={2}
            r={2}
            transform="translate(10 14)"
            fill="currentColor"
          />
          <path
            d="M18.5 17h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5zM7.5 17h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-.5.5z"
            fill="currentColor"
          />
        </g>
      </g>
     </SvgIcon>
    )
}
 
  