import { Medium, SemiBold } from "../../utils/font";

export const LocationCardStyle = {
  maincard: {
    width: '105%',
    borderRadius: "8px",
    border: "1px solid #E4E8EE",
    cursor: "pointer",
    padding: "12px"
  },
  iconbox: {
    height: "48px",
    width: "48px",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    // padding: "10px 8px 4px 4px ",
    marginLeft: "4px",
  },
  text1: {
    fontFamily: Medium,
    fontSize: "1rem",
    color: "text.primary",
  },
  text2: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: "text.primary",
  },
  arrowicon: {
    color: "text.tertiary",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  textcontent: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px"
  }
};
