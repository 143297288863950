import { borderRadiusAlignments, remCalc } from "../../utils";
import { Regular } from "../../utils/font";


export const alertStyleCard = {
    favouriteBox: {
        borderRadius: borderRadiusAlignments.borderEight
    },
    favouriteText: {
        border: "1px solid #FF4B4B",
        backgroundColor: "#FFECEC",
        color: "#091B29",
        fontSize: remCalc(14),
    },
    subText: {
        color: "#4E5A6B",
        fontSize: remCalc(12),
        fontFamily: Regular
    }
};
